import React from "react";
import helper from "./helper.js"

class CommentDialog extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            selectedAdtile: (window.shapes.length < 0) ? -1 : 0,
            iqadtile: "",
            commentBoxActivated:false,
            commentText: "",
        }
        this.selectAdtile = this.selectAdtile.bind(this);
        this.toggleAddComment = this.toggleAddComment.bind(this);
        this.commentTextOnChangeHandler = this.commentTextOnChangeHandler.bind(this);
        this.addTileCommentWrapper = this.addTileCommentWrapper.bind(this);
        this.addSiteCommentWrapper = this.addSiteCommentWrapper.bind(this);
    }
    toggleAddComment(){
        this.setState({
            commentBoxActivated: !this.state.commentBoxActivated,
            commentText: "",
        })
    }
    selectAdtile(index, adtile){
        this.setState({
            selectedAdtile: index,
            iqadtile: adtile,
        })
    }
    commentTextOnChangeHandler(evt){
        this.setState({
            commentText: evt.target.value,
        })
    }
    addTileCommentWrapper(){
        this.props.addComment(this.state.commentText, this.state.iqadtile);
        this.setState({
            commentText: "",
            commentBoxActivated: false,
        });
    }
    addSiteCommentWrapper(){
        this.props.addSiteComment(this.state.commentText);
        this.setState({
            commentText: "",
            commentBoxActivated: false,
        });
    }
    render(){
        let commentBoxPixel = (this.state.commentBoxActivated) ? 150 : 80;
        let gridTemplateString = "30px 1fr " + commentBoxPixel + "px";
        return(
            <div id="commentDialog" style={{gridTemplateRows: gridTemplateString}}>
                <div style={{width: "90%", gridRow: "1/2", gridColumn: "2/3"}}><h4 style={{textAlign: "left"}}>Kommentare - {(this.props.activeFolder === 0) ? "Seite" : "Adtiles"}</h4></div>
                {this.props.activeFolder === 0 &&
                    <SitebarTopLevelComments 
                        mandant = {this.props.mandant} 
                        endgerät = {this.props.endgerät} 
                        status = {this.props.status} 
                        layout = {this.props.layout} 
                    />
                }
                {this.props.activeFolder === 0 && 
                    <TopLevelComments 
                        toggleDialogModal = {this.props.toggleDialogModal} 
                    /> 
                }
                {this.props.activeFolder === 1 &&
                    <SitebarAdtileComments 
                        selectedAdtile = {this.state.selectedAdtile}
                        selectAdtile = {this.selectAdtile} 
                    /> 
                }
                { (((this.props.activeFolder === 0) || (this.props.activeFolder === 1 && window.shapes.length > 0)) && (this.props.permission < 3)) && 
                    <div id="addCommentWrapper">
                        {!this.state.commentBoxActivated &&
                            <button onClick={this.toggleAddComment} title="Kommentar hinzufügen" id="addCommentButton">
                                <div style={{margin: 0, padding: 0, display: "flex", justifyContent: "center", justifyItems: "center", textAlign: "center"}}><i className="material-icons">add</i></div>
                            </button>
                        }
                        {this.state.commentBoxActivated && 
                            <div id="commentBoxActivated" >
                                <div style={{gridRow: "1/2", gridColumn: "1/2",width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
                                    <button id="minusCommentButton" title="Einklappen" onClick={this.toggleAddComment}><i className="material-icons">expand_more</i></button>
                                </div>
                                <textarea onChange={this.commentTextOnChangeHandler} value={this.state.commentText} id="addCommentText"></textarea> 
                                <div style={{gridRow: "2/3", gridColumn: "2/3",width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
                                    {this.props.activeFolder === 0 &&
                                        <button onClick={this.addSiteCommentWrapper} title="Absenden" id="sendCommentButton"><i className="material-icons">send</i></button>
                                    }
                                    {this.props.activeFolder === 1 &&
                                        <button onClick={this.addTileCommentWrapper} title="Absenden" id="sendCommentButton"><i className="material-icons">send</i></button>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                }
                {this.props.activeFolder === 1 &&
                    <AdtileComments 
                        selectedAdtile = {this.state.selectedAdtile} 
                    />
                }
            </div>
        )
    }
}
class SitebarTopLevelComments extends React.Component{
    render(){
        return(
            <div id="commentSideBar">
                <div className="sidebarItem">
                    <h5>Mandant</h5>
                    <span>{this.props.mandant}</span>
                </div>
                <div className="sidebarItem">
                    <h5>Endgerät</h5>
                    <span>{this.props.endgerät}</span>
                </div>
                <div className="sidebarItem">
                    <h5>Status</h5>
                    <span>{this.props.status}</span>
                </div>
                <div className="sidebarItem">
                    <h5>Layout</h5>
                    <span>{this.props.layout}</span>
                </div>
            </div>
        )
    }
}

class SitebarAdtileComments extends React.Component{
    render(){
        return(
            <div id="commentSideBar">
                {[...window.shapes].sort((a,b) => {
                    if(Number(a.positionLabel.substring(1)) < Number(b.positionLabel.substring(1))){
                        return -1
                    }
                    else if(Number(a.positionLabel.substring(1)) > Number(b.positionLabel.substring(1))){
                        return 1
                    }
                    else{
                        return 0;
                    }
                }).map((el, index) =>{
                    return(
                        <AdtileClickItem
                            adtileId ={el.iatId}
                            key = {index}
                            index = {index}
                            selectAdtile = {this.props.selectAdtile}
                            selectedAdtile = {this.props.selectedAdtile}
                        />
                    )
                })}
            </div>
        )
    }
}
class AdtileClickItem extends React.Component{
    constructor(props){
        super(props);
        this.selectAdtileClickWrapper = this.selectAdtileClickWrapper.bind(this);
    }
    selectAdtileClickWrapper(){
        this.props.selectAdtile(this.props.index, this.props.adtileId);
    }
    render(){
        return(
            <div onClick={this.selectAdtileClickWrapper} className={(this.props.index === this.props.selectedAdtile) ? "sidebarItem selected sidebarAdtile" : "sidebarItem sidebarAdtile"}>
                <span>{this.props.adtileId}</span>
            </div>
        )
    }
}

class TopLevelComments extends React.Component{
    render(){
        return(
            <div id="topLevelComments">
                { window.topLevelComments && window.topLevelComments.length > 0 && window.topLevelComments.sort(sortDescending).map( (el, index) => {
                    return(
                        <Comment 
                            key = {index}
                            name = {el.item.owner} 
                            date = {el.item.creationdate}
                            text = {el.item.text}
                        />
                        );
                    })
                }
                { (!window.topLevelComments || window.topLevelComments.length === 0) &&
                    <p style={{marginTop: 40}}>Noch Keine Kommentare vorhanden...</p> 
                }
            </div>
        )
    }
}

class AdtileComments extends React.Component{
    render(){
        let sortedShapes = [...window.shapes].sort((a,b) => {
                    if(Number(a.positionLabel.substring(1)) < Number(b.positionLabel.substring(1))){
                        return -1
                    }
                    else if(Number(a.positionLabel.substring(1)) > Number(b.positionLabel.substring(1))){
                        return 1
                    }
                    else{
                        return 0;
                    }
                })
                console.log(sortedShapes);
        return(
            <div id="topLevelComments">
                {(sortedShapes.length >0 && sortedShapes[this.props.selectedAdtile].comment.length > -1) && 
                    [...sortedShapes[this.props.selectedAdtile].comment].sort(sortDescending).map((el, index) =>{
                        return(
                            <Comment
                                key = {index}
                                name = {el.item.owner} 
                                date = {el.item.creationdate}
                                text = {el.item.text}
                            />
                        )
                    })
                }
                {(sortedShapes.length <= 0 || sortedShapes[this.props.selectAdtile] || sortedShapes[this.props.selectedAdtile].comment.length < 0) &&
                    <p style={{marginTop: 40}}>Noch Keine Kommentare vorhanden...</p> 
                }
            </div>
        )
    }
}

class Comment extends React.Component{
    render(){
        return(
            <div className="commentComponent">
                <div className="comment_name"><div style={{display:"inline",width:30,height:30,borderRadius:50,marginRight:15,background: helper.generateRgbFromName(this.props.name)}}></div><span>{helper.capitalize(this.props.name)}</span></div>
                <div className="comment_text"><p>{atob(this.props.text)}</p></div>
                <div className="comment_date"><span>{new Date(this.props.date).toLocaleDateString()}</span></div>
            </div>
        )
    }
}

function sortDescending(a,b){
    if(a.item.creationdate < b.item.creationdate){
        return 1;
    }
    else if(a.item.creationdate > b.item.creationdate){
        return -1;
    }
    else{
        return 0;
    }
}

export default CommentDialog;