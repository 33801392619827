import React from "react";

import Loader from "./Loader.js";

class Login extends React.Component{
    constructor(props){
        super(props);
        this.state={
            showRegister: false,
            innerLoginInput: {
                email: "",
                password: "",
            },
            innerRegisterInput: {
                email: "",
                name: "",
                department: "",
                password: "",
                confirmpassword: "",
            },
            isLoading: false,
            registerError: false,
            errorType: null,

        }
        this.loginHandle = this.loginHandle.bind(this);
        this.toggleRegister = this.toggleRegister.bind(this);
        this.onChangeHandlerRegister = this.onChangeHandlerRegister.bind(this);
        this.registerHandle = this.registerHandle.bind(this);
    }

	loginHandle(event){
        event.preventDefault();
        this.props.toggleLoadingState(true);
        let email = document.querySelector("#email").value;
        let password = document.querySelector("#password").value;
        let x = JSON.stringify({email: email, password: password});
        this.props._post(window.api + "/login", x, true)
            .then((response) => {
                let parsed = JSON.parse(response.response);
                this.props.setAppState({
                    isAuthed: true,
                    isLoading: false,
                    wrongCredentials: false,
                    name: parsed.name,
                    permission: parsed.permission,
                })
            }).catch( (err) => {
                console.log(err);
                this.props.setAppState({
                    isAuthed: false,
                    isLoading: false,
                    wrongCredentials: true,
                });
            });
    }
    registerHandle(evt){
        evt.preventDefault();
        this.setState({
            isLoading: true,
        })
        if(this.state.innerRegisterInput.password !== this.state.innerRegisterInput.confirmpassword){
            this.setState({
                registerError: true,
                errorType: "Die eingegebenen Passwörter sind nicht identisch.",
                isLoading: false,
            })
            window.alert("ERROR: Passwörter sind nicht identisch.");
        }
        else{
            let x = this.state.innerRegisterInput;
            let c = JSON.stringify({email: x.email, name: x.name, lastname: x.lastname, department: x.department, password: x.password, confirmpassword: x.confirmpassword, timestamp: Date.now()});
            this.props._post(window.api + "/register", c, true)
                .then((response) =>{
                    let res = JSON.parse(response.response);
                    if(res.success){
                        window.alert("Wir werden deine Registrierung so schnell wie möglich bearbeiten. Bitte melde dich bei nils.kummert@iqdigital.de falls deine Anfrage nicht innerhalb zwei Tagen bearbeitet wurde.");
                        this.setState({
                            innerRegisterInput: {
                                email: "",
                                name: "",
                                department: "",
                                password: "",
                                confirmpassword: "",
                            },showRegister: false,
                            isLoading: false,
                        })
                    }
                    else{
                        let err = {
                            1: "Deine angegebenen Passwörter stimmen nicht überein.",
                            2: "Die Emailadresse ist uns bereit bekannt. Entweder hast du bereits einen Account oder dieser befindet sich gerade in Prüfung.",
                            default: "Es ist ein Fehler aufgetreten. Bitte wende dich an den Administratior."
                        }
                        window.alert("ERROR: " + err[res.errorType] || err.default);
                        this.setState({
                            registerError: true,
                            errorType: err[res.errorType] || err.default,
                            isLoading: false,
                        });
                    }
                }).catch((err) =>{
                    window.alert("ERROR: unbekannt");
                    this.setState({
                        registerError: true,
                        errorType: "Es ist ein Fehler aufgetreten. Bitte wende dich an den Administratior.",
                        isLoading: false,
                    })
                });
        }
    }
    toggleRegister(){
        this.setState({
            showRegister: !this.state.showRegister
        })
    }
    onChangeHandlerRegister(key, value){
        let c = Object.assign(this.state.innerRegisterInput);
        c[key] = value;
        this.setState({
            innerRegisterInput: c,
        });
    }
    
    render(){
        return(
            <div className="whiteWrapper" id="loginWrapper">
                <div className="box" id="loginbox">
                    {!this.state.showRegister && 
                        <InnerLogin
                            wrongCredentials = {this.props.wrongCredentials}
                            toggleRegister = {this.toggleRegister}
                            toggleGui = {this.props.toggleGui}
                            loginHandle = {this.loginHandle}
                        />
                    }
                    {this.state.showRegister &&
                        <InnerRegister
                            wrongCredentials = {this.props.wrongCredentials}
                            toggleRegister = {this.toggleRegister}
                            toggleGui = {this.props.toggleGui}
                            onChangeHandlerRegister = {this.onChangeHandlerRegister}
                            innerRegisterInput = {this.state.innerRegisterInput}
                            registerHandle = {this.registerHandle}
                            isLoading = {this.state.isLoading}
                        /> 
                    }
                </div>
            </div>
        )
    }
}

class InnerLogin extends React.Component{
    render(){
        return(
            <div>
                <h2>Login</h2>
                <form className ="form" method="POST" >
                    <div className="div" style={{margin: 0}}>
                        <input id="email" style={(this.props.wrongCredentials) ? {borderBottom: "2px solid red"} : {}} className={this.props.toggleGui("input", "inputDeactivated")} placeholder="Email"></input>
                        <input id="password" style={(this.props.wrongCredentials) ? {borderBottom: "2px solid red"} : {}} className={this.props.toggleGui("input", "inputDeactivated")} type="password" placeholder="Password"></input>
                    </div>
                        <div className="div" style={{width: "100%", height: "30px", margin: 0}}>
                    { 
                        (this.props.wrongCredentials) &&
                            <span style={{color: "red"}}>Falsche E-Mail und/oder Passwort!</span>
                    }
                        </div>
                    <div className="div" style={{width: "100%"}}>
                        {/* <span>Passwort vergessen?</span> */}
                    </div>
                    <div className="div">
                        <button onClick={this.props.loginHandle} className={this.props.toggleGui("button", "buttonHidden")}>LOGIN</button>
                        <button onClick={this.props.toggleRegister} className={this.props.toggleGui("button", "buttonHidden")}>ACCOUNT BEANTRAGEN</button>
                        <div className={this.props.toggleGui("cssLoader", "cssLoaderActivated")}></div>
                    </div>
                </form>
            </div>
        )
    }
}

class InnerRegister extends React.Component{
    constructor(props){
        super(props); 
        this.onChangeWrapper = this.onChangeWrapper.bind(this);
    }
    onChangeWrapper(evt){
        this.props.onChangeHandlerRegister(evt.target.id, evt.target.value);
    }
    render(){
        let loaderClassName = (this.props.isLoading) ? "cssLoader cssLoaderActivated" : "cssLoader";
        let buttonClassName = (this.props.isLoading) ? "button buttonHidden" : "button";
        return(
            <div>
                <h2>Account Beantragen</h2>
                <form className ="form" method="POST" >
                    <div className="div" style={{margin: 0}}>
                        <input onChange={this.onChangeWrapper} value={this.props.innerRegisterInput.email} id="email"  className={this.props.toggleGui("input", "inputDeactivated")} placeholder="Email"></input>
                        <input onChange={this.onChangeWrapper} value={this.props.innerRegisterInput.name} id="name" className={this.props.toggleGui("input", "inputDeactivated")} placeholder="Name"></input>
                        <input onChange={this.onChangeWrapper} value={this.props.innerRegisterInput.department} id="department" className={this.props.toggleGui("input", "inputDeactivated")} placeholder="Abteilung"></input>
                        <input onChange={this.onChangeWrapper} value={this.props.innerRegisterInput.password} id="password" className={this.props.toggleGui("input", "inputDeactivated")} type="password" placeholder="Passwort"></input>
                        <input onChange={this.onChangeWrapper} value={this.props.innerRegisterInput.confirmpassword} id="confirmpassword" className={this.props.toggleGui("input", "inputDeactivated")} type="password" placeholder="Passwort bestätigen"></input>
                    </div>
                        <div className="div" style={{width: "100%", height: "30px", margin: 0}}>
                    { 
                        (this.props.wrongCredentials) &&
                            <span style={{color: "red"}}>Falsche E-Mail und/oder Passwort!</span>
                    }
                        </div>
                    <div className="div">
                        <button onClick={this.props.registerHandle}className={buttonClassName}>ABSCHICKEN</button>
                        <button onClick={this.props.toggleRegister} className={buttonClassName}>ABBBRECHEN</button>
                        <div className={loaderClassName}></div>
                    </div>
                </form>
            </div>
        )
    }
}

export default Login;