import React from "react";
import "./NavSidebar.css";

class NavSidebar extends React.Component{
    render(){
        return(
            <div id="navSidebar" >
                <div id="sidebarElementWrapper">
                    {this.props.menuItems.map( (el,ind) => {
                        return(
                            <SideBarElement 
                                icon = {el.icon} 
                                title = {el.title}
                                key = {ind}
                                index = {ind}
                                activatedFolder={this.props.activatedFolder}
                                changeFolder={this.props.changeFolder}
                            />
                        )
                    })}
                </div>
            </div>
        )
    }
}

class SideBarElement extends React.Component{
    constructor(props){
        super(props);
        this.clickHandler = this.clickHandler.bind(this);
    }
    clickHandler(){
        this.props.changeFolder(this.props.index);
    }
    render(){
        let id = (this.props.activatedFolder === this.props.index) ? "sidebarElementSelected" : "";
        return(
            <div id={id} className="sidebarElement" onClick = {this.clickHandler}>
                <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <i title={this.props.title || ""} className="material-icons" style={{fontSize: 30}}>{this.props.icon || ""}</i>
                </div>
            </div>
        )
    }
}
export default NavSidebar;