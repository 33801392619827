import React from "react";

/**
 * @class Loader
 * @param {Number} [scale] - Scalefactor of the Loader
 * @param {String} [color] - color of the Loader
 * @param {Number} [marginTop] - topMargin
 */
class Loader extends React.Component{

    render(){
        let width = 200*(this.props.scale || 1);
        let height = 200*(this.props.scale || 1);
        let color = this.props.color || undefined;
        let cssLoaderStyle = {
            width: width,
            height: height,
            borderWidth: 25
        };
        if(color){
            cssLoaderStyle.borderColor = color;
        }
        return(
            <div style={{marginTop: this.props.marginTop ,overflow: "hidden", display: "flex", justifyContent:"center", alignItems:"center",width: "100%", height: "100%"}}>
                <div className="cssLoader cssLoaderActivated" style={cssLoaderStyle}></div>
            </div>
        )
    }
}

export default Loader;