function deepFind(object, path){
    let isPath = /\w+\./.test(path);
    let current = object; 
    if(!isPath){
        return current[path];
    }
    else{
        let paths = path.split('.');

        for(let i = 0; i < paths.length; i++){
            if(current[paths[i]] === undefined){
                return undefined;
            }
            else{
                current = current[paths[i]];
            }
        }
        // return current.toString();
        return current;
    }
}

function capitalize(string){
    let c = string.split(" ");
    c = c.map((el) => (el[0]) ? (el[0].toUpperCase() + el.substring(1)) : "")
    return c.join(" ");
}
function generateRatio(name, index){
    return (("z".charCodeAt(0)+1) - name.charCodeAt(index))/(("z".charCodeAt(0)+1) - "a".charCodeAt(0));
}
function generateRgbFromName(name){
    let r = Math.round(generateRatio(name, 0)*254);
    let g = Math.round(generateRatio(name, 1)*254);
    let b = (name.length < 3) ? Math.round(generateRatio("m",0)*254) : Math.round(generateRatio(name, 2)*254);
    return "rgba(" + r + "," + g + "," + b + ",0.8)";
}
function sortSlotSizes(arr){
    let splittedArr = arr.map((el) => {
        if(el.match(/\d+x\d+/)){
            return el.split("x").map((l) =>{
                return Number(l);
            })
        }
        else{
            return el;
        }
    });
    splittedArr.sort((a,b) =>{
        return a[1] - b[1];
    }).sort((a,b) => {
       return a[0] - b[0]
    });
    splittedArr = splittedArr.map((el) => {
        if(typeof el === "string"){
            return el;
        }
        else{
            return el[0] + "x" + el[1]
        }
    });
    return splittedArr;
}
function sortAdtiles(arr){
    let splittedArr = arr.map((el) => {
        let c;
        if(isNaN(Number(el.substring(8)))){
            c = el.substring(8);
        }
        else{
            c = Number(el.substring(8));
        }
        return c;
    })
    splittedArr.sort();
    splittedArr = splittedArr.map((el) => "iqadtile"+el);
    return splittedArr;
}
function sortNested(arr,key) {
    let c = [...arr];
    if(key === "iqadtile"){
        c.sort((a,b)=>{
            if(Number(a[key].substring(8)) < Number(b[key].substring(8))){
                return -1;
            }
            else if(Number(a[key].substring(8)) > Number(b[key].substring(8))){
                return 1;
            }
            else{
                return 0;
            }
        });
    }
    else if(key !== "slotgrößen"){
        c.sort((a,b)=>{
            if(a[key] < b[key]){
                return -1;
            }
            else if(a[key] > b[key]){
                return 1;
            }
            else{
                return 0;
            }
        });
    }
    return c;
}
 
export default {deepFind, generateRgbFromName, capitalize, sortSlotSizes, sortAdtiles, sortNested}