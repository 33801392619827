import React from "react";
import { Switch, Route, Link } from "react-router-dom";

import Headline from "./Headline.js";
import Loader from "./Loader.js";
import InputDiv from "./InputDiv.js";
import "./Planung.css";

class Planung extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            ulists: ["mandant", "endgerät", "status", "layout"],
            selectedItems: {
                mandant: {
                    name: undefined,
                    element: undefined,
                },
                endgerät: {
                    name: undefined,
                    element: undefined,
                },
                status: {
                    name: undefined,
                    element: undefined,
                },
                layout: {
                    name: undefined,
                    element: undefined,
                },
            },
            mandant: {
                items: [],
                loaded: false,
            },
            endgerät: {
                items: [],
                loaded: false,
            },
            status: {
                items: [],
                loaded: false,
            },
            layout: {
                items: [],
                loaded: false,
            },
            editLayoutActive: false,
            addNewItemActive: false,
            addNewItemIndex: null,
            newItemInputValue: "",
            lastRequest: "",
            schemaBluePrints: [],
            loadingBluePrints: false,
            selectedSchema: -1,
        }
        this.getNextList = this.getNextList.bind(this);
        this.setPlanungState = this.setPlanungState.bind(this);
        this.newItemInput = this.newItemInput.bind(this);
        this.addNewItem = this.addNewItem.bind(this);
        this.newItemInputHandleChange = this.newItemInputHandleChange.bind(this);
        this.getMandantList = this.getMandantList.bind(this);
        this.fetchSchema = this.fetchSchema.bind(this);
        this.schemaHandleChange = this.schemaHandleChange.bind(this);
    }
    componentDidMount(){
        this.getMandantList();
    }
    componentDidUpdate(prevProps, prevState){
        if(this.state.mandant.length < 1){
            this.getMandantList();
        }
    }
    getMandantList(){
        let c = {...this.state.selectedItems};
        c.mandant = new SItem();
        c.endgerät = new SItem();
        c.status = new SItem();
        c.layout = new SItem();
        let cHash = btoa(window.api + "/planung?key=mandant" + Date.now());
        this.setState({lastRequest: cHash})
        this.props._get(window.api + "/planung?key=mandant")
            .then((result) =>{
                if(cHash === this.state.lastRequest){
                    this.setState({
                        mandant:{
                            items: JSON.parse(result),
                            loaded: true,
                        },
                        selectedItems: c,
                    })
                }
            })
            .catch((err) =>{
                console.error(err);
            })

    }
    getNextList(prev_index){
        let answ = null;
        let c;
        let filter;
        let cHash;
        switch(prev_index){
            case "0":
                c = {...this.state.selectedItems};
                c.endgerät = new SItem();
                c.status = new SItem();
                c.layout = new SItem();
                filter = this.state.selectedItems.mandant.name;
                this.setState({
                    endgerät: {
                        items: [],
                        loaded: false,
                    },
                    status: {
                        items: [],
                        loaded: false,
                    },
                    layout: {
                        items: [],
                        loaded: false,
                    },
                    editLayoutActive: false,
                    selectedItems: c,
                })

                cHash = btoa(window.api + "/planung?key=endgerät&filter=" + filter);
                this.setState({lastRequest: cHash})
                this.props._get(window.api + "/planung?key=endgerät&filter=" + filter)
                    .then((result) =>{
                        if(cHash = this.state.lastRequest){
                            this.setState({
                                endgerät: {
                                    items: JSON.parse(result),
                                    loaded: true,
                                },
                            })
                        }
                    })
                break;
            case "1":
                c = {...this.state.selectedItems};
                c.status = new SItem();
                c.layout = new SItem();
                filter = this.state.selectedItems.mandant.name + "," + this.state.selectedItems.endgerät.name;
                this.setState({
                    status: {
                        items: [],
                        loaded: false,
                    },
                    layout: {
                        items: [],
                        loaded: false,
                    },
                    editLayoutActive: false,
                    selectedItems: c,
                })
                cHash = btoa(window.api + "/planung?key=endgerät&filter=" + filter);
                this.setState({lastRequest: cHash})
                this.props._get(window.api + "/planung?key=status&filter=" + filter)
                    .then((result) =>{
                        if(cHash === this.state.lastRequest){
                            this.setState({
                                status: {
                                    items: JSON.parse(result),
                                    loaded: true,
                                },
                            })
                        }
                    })
                break;
            case "2":
                c = {...this.state.selectedItems};
                c.layout = new SItem();
                filter = this.state.selectedItems.mandant.name + "," + this.state.selectedItems.endgerät.name + "," +  this.state.selectedItems.status.name;
                this.setState({
                    layout: {
                        items: [],
                        loaded: false,
                    },
                    editLayoutActive: false,
                    selectedItems: c,
                })
                cHash = btoa(window.api + "/planung?key=endgerät&filter=" + filter);
                this.setState({lastRequest: cHash})
                this.props._get(window.api + "/planung?key=layout&filter=" + filter)
                    .then((result) =>{
                        if(cHash === this.state.lastRequest){
                            this.setState({
                                layout: {
                                    items: JSON.parse(result),
                                    loaded: true,
                                },
                            })
                        }
                    })
                break;
            case "3":
                this.setState({
                    editLayoutActive: true,
                })
                break;
            default:
                break;
        }

    }
    setPlanungState(obj){
        this.setState(obj);
    }
    newItemInput(index){
        this.setState({
            addNewItemActive: !this.state.addNewItemActive,
            addNewItemIndex: Number(index),
            newItemInputValue: "",
            loadingBluePrints: true,
        }, this.fetchSchema(index));
    }
    fetchSchema(index){
        console.log(this.state.addNewItemIndex);
        this.props._get(window.api + "/schema/select?key=" + this.state.ulists[index])
        .then((data) =>{
            let parsed = JSON.parse(data);
            this.setState({
                schemaBluePrints: parsed,
                loadingBluePrints: false,
            })
        }).catch((err) =>{
            console.error(err);
            this.setState({
                loadingBluePrints: false,
            })
        })
    }
    addNewItem(){
        let c = {};
        document.querySelector("body").style.cursor = "wait";
        switch(this.state.addNewItemIndex){
            case 0:
                c.mandant = this.state.newItemInputValue;
                break;
            case 1:
                c.mandant = this.state.selectedItems.mandant.name;
                c.endgerät = this.state.newItemInputValue;
                break;
            case 2:
                c.mandant = this.state.selectedItems.mandant.name;
                c.endgerät = this.state.selectedItems.endgerät.name;
                c.status = this.state.newItemInputValue;
                break;
            case 3:
                c.mandant = this.state.selectedItems.mandant.name;
                c.endgerät = this.state.selectedItems.endgerät.name;
                c.status = this.state.selectedItems.status.name;
                c.layout = this.state.newItemInputValue;
                break;
            default:
                console.error("Error: No Index found");

        }
        if(Number(this.state.selectedSchema) > -1){
            c.schema = {};
            Object.keys(this.state.schemaBluePrints[Number(this.state.selectedSchema)]).forEach((el) =>{
                if(el !== "idString"){
                    c.schema[el] = this.state.schemaBluePrints[Number(this.state.selectedSchema)][el];
                }
            });
            c.key = this.state.ulists[this.state.addNewItemIndex];
        }
        c.srcSchema = false;
        this.props._post(window.api + "/planung", JSON.stringify(c))
        .then( (data) =>{
            this.setState({
                addNewItemActive: false,
                newItemInputValue: "",
            }) 
            document.querySelector("body").style.cursor = "auto";
            if(this.state.addNewItemIndex === 0){
                this.setState({
                    mandant: {
                        items: [],
                        loaded: false,
                    },
                    endgerät:{
                        items: [],
                        loaded: false,
                    },
                    status: {
                        items: [],
                        loaded: false,
                    },
                    layout: {
                        items: [],
                        loaded: false,
                    },
                })
                this.getMandantList();
            }
            else{
                this.getNextList((this.state.addNewItemIndex-1).toString());
            }
        })
        .catch( (err) =>{
            console.error(err);
        })
    }
    newItemInputHandleChange(evt){
        this.setState({
            newItemInputValue: evt.target.value,
        })
    }
    schemaHandleChange(evt){
        this.setState({
            selectedSchema: evt.target.value,
        })
    }
    render(){
        let buttonClass = (!this.state.editLayoutActive) ? "button button_deactivated": "button";
        let disabledCheck = !(this.state.editLayoutActive) ? "disabled" : "";
        return(
            <div className="whiteWrapper" id="planungWrapper">
                <Headline breadcrumb="planung" headline="Auswahl"/>
                    <div className="divWrapper" style={{alignItems: "flex-start", height: 595}}>
                        <InputDiv 
                            listName={this.state.ulists[0]} 
                            index="0"
                            values={this.state.mandant.items}
                            activeHandler={this.getNextList}
                            active={true}
                            setPlanungState = {this.setPlanungState}
                            selectedItems = {this.state.selectedItems}
                            addNewItemActive = {this.state.addNewItemActive}
                            addNewItemIndex= {this.state.addNewItemIndex}
                            newItemInput = {this.newItemInput}
                            disabledCheck = {disabledCheck}
                            buttonClass = {buttonClass}
                            addNewItem = {this.addNewItem}
                            newItemInputHandleChange= {this.newItemInputHandleChange}
                            newItemInputValue= {this.state.newItemInputValue}
                            schemaHandleChange = {this.schemaHandleChange}
                            permission = {this.props.permission}
                        >
                            {!this.state.mandant.items[0] && !this.state.mandant.loaded &&
                                <Loader scale="0.5"/>
                            }
                        </InputDiv>
                        <InputDiv 
                            listName={this.state.ulists[1]} 
                            values={this.state.endgerät.items}
                            index="1"
                            activeHandler={this.getNextList}
                            active={(!!this.state.selectedItems.mandant.name)}
                            setPlanungState = {this.setPlanungState}
                            selectedItems = {this.state.selectedItems}
                            addNewItemActive = {this.state.addNewItemActive}
                            addNewItemIndex= {this.state.addNewItemIndex}
                            newItemInput = {this.newItemInput}
                            disabledCheck = {disabledCheck}
                            buttonClass = {buttonClass}
                            addNewItem = {this.addNewItem}
                            newItemInputHandleChange= {this.newItemInputHandleChange}
                            newItemInputValue= {this.state.newItemInputValue}
                            schemaHandleChange = {this.schemaHandleChange}
                            permission = {this.props.permission}
                        >
                            {!this.state.endgerät.items[0] && !this.state.endgerät.loaded && !!this.state.selectedItems.mandant.name && 
                                <Loader scale="0.5"/>
                            }
                        </InputDiv>
                        <InputDiv 
                            listName={this.state.ulists[2]} 
                            values={this.state.status.items}
                            index="2"
                            activeHandler={this.getNextList}
                            active={(!!this.state.selectedItems.endgerät.name)}
                            setPlanungState = {this.setPlanungState}
                            selectedItems = {this.state.selectedItems}
                            addNewItemActive = {this.state.addNewItemActive}
                            addNewItemIndex= {this.state.addNewItemIndex}
                            newItemInput = {this.newItemInput}
                            disabledCheck = {disabledCheck}
                            buttonClass = {buttonClass}
                            addNewItem = {this.addNewItem}
                            newItemInputHandleChange= {this.newItemInputHandleChange}
                            newItemInputValue= {this.state.newItemInputValue}
                            schemaBluePrints = {this.state.schemaBluePrints}
                            loadingBluePrints = {this.state.loadingBluePrints}
                            schemaHandleChange = {this.schemaHandleChange}
                            permission = {this.props.permission}
                        >
                            {!this.state.status.items[0] && !this.state.status.loaded && !!this.state.selectedItems.endgerät.name && 
                                <Loader scale="0.5"/>
                            }
                        </InputDiv>
                        <InputDiv 
                            listName={this.state.ulists[3]} 
                            values={this.state.layout.items}
                            index="3"
                            activeHandler={this.getNextList}
                            active={(!!this.state.selectedItems.status.name)}
                            setPlanungState = {this.setPlanungState}
                            selectedItems = {this.state.selectedItems}
                            addNewItemActive = {this.state.addNewItemActive}
                            addNewItemIndex= {this.state.addNewItemIndex}
                            newItemInput = {this.newItemInput}
                            disabledCheck = {disabledCheck}
                            buttonClass = {buttonClass}
                            addNewItem = {this.addNewItem}
                            newItemInputHandleChange= {this.newItemInputHandleChange}
                            newItemInputValue= {this.state.newItemInputValue}
                            schemaBluePrints = {this.state.schemaBluePrints}
                            loadingBluePrints = {this.state.loadingBluePrints}
                            schemaHandleChange = {this.schemaHandleChange}
                            permission = {this.props.permission}
                        >
                            {!this.state.layout.items[0] && !this.state.layout.loaded && !!this.state.selectedItems.status.name && 
                                <Loader scale="0.5"/>
                            }
                        </InputDiv>
                        <div style={{alignSelf: "center"}}>
                                <Link to={{
                                    pathname: "/planung/planner",
                                    state: {
                                        mandant: this.state.selectedItems.mandant.name,
                                        endgerät: this.state.selectedItems.endgerät.name,
                                        status: this.state.selectedItems.status.name,
                                        layout: this.state.selectedItems.layout.name,
                                    }
                                }} style={{textDecoration: "none", color: "inherit"}}>
                                    <button disabled={disabledCheck} className={buttonClass}>{(this.props.permission < 3) ? "Layout bearbeiten" : "Layout anzeigen"}</button>
                                </Link>
                        </div>
                    </div>
            </div>
        )
    }
}

class SItem{
    constructor(name=undefined, element=undefined){
        this.name = name;
        this.element = element;
    }
}

export default Planung;