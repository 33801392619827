import React from "react";
import { Redirect } from "react-router-dom";
import helper from "./helper.js";
import Headline from "./Headline.js"
import Loader from "./Loader";
import NiceSelect from "./NiceSelect";

import "./style.css";
import "./AdminImport.css";

class AdminImport extends React.Component{
    constructor(props){
        super(props);
        this.state={
            activatedFolder: 0,
            folder: [{title: "Knoten mit TMS verknüpfen", icon: "link"}, {title: "Verknüpfungen aufheben", icon: "link_off"}, {title: "Mandanten aus TMS kopieren", icon: "cloud_download"}],
            isLoading: true,
            xValues: [],
            tmsValues: [],
            selectedMandant: undefined,
            doesTmsConnectionExists: false,
            newConnectionOpen: false,
            nodePreview: undefined,
            progressPage: 0,
            mandantInput: "",
            endgerätInput: "",
            statusInput: "",
            importSuccess: false,
        }
        this.selectMandant = this.selectMandant.bind(this);
        this.fetchData = this.fetchData.bind(this);
        this.urlTms= this.urlTms.bind(this);
        this.urlTmsAvailable = this.urlTmsAvailable.bind(this);
        this.toggleLoading = this.toggleLoading.bind(this);
        this.onChangeHandler = this.onChangeHandler.bind(this);
        this.onClickHandler = this.onClickHandler.bind(this);
        this.checkBoxHandler = this.checkBoxHandler.bind(this);
        this.createXTNode = this.createXTNode.bind(this);
        this.walkTree = this.walkTree.bind(this);
        this.inputChangeWrapper = this.inputChangeWrapper.bind(this);
    }
    componentDidMount(){
        this.fetchData(this.urlTmsAvailable, null, "")
        .then((data) => {
            if(data){
                console.log(data)
                this.setState({
                    isLoading: false,
                    mandantenList: data.map((el) => el).sort(),
                });
            }
        }).catch((err) => console.log(err));
    }
    async toggleNewConnectionOpen(){
        if(this.state.tmsValues.length > 0){
            this.setState({
                newConnectionOpen: !this.state.newConnectionOpen,
            });
        }
        else{
            await new Promise((resolve, reject) =>{
                this.setState({
                    newConnectionOpen: !this.state.newConnectionOpen,
                    isLoading: true,
                }, resolve());
            });
            this.fetchData(this.urlTms, "name")
            .then((data) => {
                console.log(data);
                this.setState({
                    isLoading: false,
                    tmsValues: data,
                });
            }).catch((err) =>{
                console.error(err);
                this.setState({
                    isLoading: false,
                });
            });
        }
    }
    fetchData(urlFunction, sortingKey=null, arg=null){
        return new Promise((resolve, reject) =>{
            const url = urlFunction(arg);
            this.props._get(url)
                .then((data) =>{
                    let c;
                    if(sortingKey && sortingKey !== "name"){
                        c = JSON.parse(data).map((el) =>{
                            console.log(el);
                            let ca = el;
                            // Ich weiß nicht warum, aber man kann die tms values nicht mit der helper Funktion capizalizen
                                ca[sortingKey] = helper.capitalize(ca[sortingKey]);
                            return ca;
                        });
                    }
                    else{
                       c = JSON.parse(data) ;
                    }
                    if(sortingKey){
                        c.sort((a, b) => {
                            let aName = a[sortingKey].toUpperCase();
                            let bName = b[sortingKey].toUpperCase();
                            if(aName < bName){
                                return -1;
                            }
                            else if(aName > bName){
                                return 1;
                            }
                            else{
                                return 0;
                            }
                        });
                    }
                    resolve(c);
                }).catch((err) =>{
                    reject(err);
                })
            })
    }
    urlMandant(arg){
        return window.api + "/tms/planung?nodes=1";
    }
    urlEndgerät(arg){
        return window.api + "/tms/planung?nodes=" + arg + "&device=1";
    }
    urlTms(arg){
        return window.api + "/tms/export" + arg;
    }
    urlTmsAvailable(arg){
        return window.api + "/tms/exportAvailable" + arg;
    }
    inputChangeWrapper(e, type){
        let x = {};
        let t = e.target.value;
        switch(type){
            case "mandant":
                x.mandantInput = t;
                break;
            case "endgerät":
                x.endgerätInput = t;
                break;
            case "status":
                x.statusInput = t;
                break;
        }
        this.setState(x);
    }
    fetchTmsData(){
        return new Promise((resolve, reject) =>{
            const url = window.api + "/tms/export";
            this.props._get(url)
                .then((data) =>{
                    let c = JSON.parse(data).map((el) =>{return {nodeId: el.nodeId, name: el.name}});
                    c.sort((a, b) => {
                        let aName = a.name.toUpperCase();
                        let bName = b.name.toUpperCase();
                        if(aName < bName){
                            return -1;
                        }
                        else if(aName > bName){
                            return 1;
                        }
                        else{
                            return 0;
                        }
                    });
                    resolve(c);
                }).catch((err) =>{
                    reject(err);
                })
            })
    }
    selectMandant(e){
        this.setState({
            selectedMandant: e
        });
    }
    toggleLoading(){
        this.setState({
            isLoading: !this.state.isLoading,
        });
    }
    onChangeHandler(e){
        this.setState({
            selectedMandant: e,
        })
    }
    onClickHandler(){
        this.toggleLoading();
        this.fetchData(this.urlTms, null, "?nodeId=" + this.state.selectedMandant + "&excludeScripts=1" + "&excludePosTable=1")
        .then((data) => {
            this.setState({
                isLoading: false,
                nodePreview: (function xx(input){
                    input.checked = true;
                    if(input.subnodes){
                        input.subnodes = input.subnodes.map((el) => {
                            return xx(el);
                        });
                    }
                    return input;
                })(data),
            })
        }).catch((err) =>{
            this.toggleLoading();
            console.log(err);
        })
    }
    checkBoxHandler(depthArray){
        let temp = Object.assign(this.state.nodePreview);
        let pointer = temp;
        for(let i = 1; i < depthArray.length; i++){
            pointer = pointer.subnodes[depthArray[i]];
        }
        this.recursiveCheckbox(pointer);
        // pointer.checked = !pointer.checked;
        this.setState({
            nodePreview: temp
        });
    }
    recursiveCheckbox(node){
        node.checked = !node.checked;  
        for(let i = 0; node.subnodes && i < node.subnodes.length; i++){
            this.recursiveCheckbox(node.subnodes[i]);
        }
    }
    createXTNode(){
        let x = this.walkTree(this.state.nodePreview);
        console.log(x);
        this.setState({
            isLoading: true
        })
        this.props._post(window.api + "/tms/import", JSON.stringify([x,{mandant: this.state.mandantInput, endgerät: this.state.endgerätInput, status: this.state.statusInput, topLevelId: this.state.nodePreview.nodeId}])) 
        .then(() =>{
            this.setState({
                importSuccess: true,
                isLoading: false
            });
            alert("Mandanten erfolgreich importiert");
        }).catch((err) => {
            this.setState({
                isLoading: false
            });
            if(err.toString().indexOf("Error: 406") > -1){
                alert("Error: Mandant ist bereits importiert");
            }
            else{
                alert("Error: Konnte Import nicht durchführen.");
            }
        })
    }
    walkTree(node){
        let a = [node.nodeId];
        if(node.subnodes){
            for(let i = 0; i < node.subnodes.length; i++){
                if(node.subnodes[i].checked){
                    let t = this.walkTree(node.subnodes[i]);
                    a = [...a,...t];
                }
            }
        }
        return a;
    }
    render(){
        return(
            <>
                <div style={{alignContent: "flex-start"}} className="whiteWrapper" id="adminImportComponent">
                    <Headline breadcrumb="data" headline="TMS Anbindung" other={["Mandanten Importieren"]}/>
                    <div id="positioner" style={{flexWrap: "wrap", height: "initial", alignItems: "flex-start", marginTop: 50}}>
                        <div className="importWrapper">
                            <NiceSelect 
                                isLoading = {this.state.isLoading}
                                onChangeHandler = {this.onChangeHandler}
                                label = "TMS Knoten"
                                values = {this.state.mandantenList}
                                valueKey = {"nodeId"}
                                displayKey = {"name"}
                                selected = {this.state.selectedMandant}
                                button = {true}
                                buttonText = "Auswählen"
                                buttonClickHandler = {this.onClickHandler}
                            />
                            {(this.state.nodePreview && this.state.isLoading) &&
                                <Loader /> 
                            }
                            {(this.state.nodePreview && !this.state.isLoading && this.state.selectedMandant && this.state.selectedMandant !== "Auswählen") && 
                                <>
                                    <div className="row">
                                        <NodeItem node={this.state.nodePreview} depth={[0]} checkBoxHandler={this.checkBoxHandler} id="root"/>
                                    </div>
                                    <div className="import_inputWrapper">
                                        <input onChange={(e) => this.inputChangeWrapper(e, "mandant")} value={this.state.mandantInput} className="niceSelect import_input" type="text" placeholder="Mandant"></input>
                                        <input onChange={(e) => this.inputChangeWrapper(e, "endgerät")} value={this.state.endgerätInput} className="niceSelect import_input" type="text" placeholder="Engerät"></input>
                                        <input onChange={(e) => this.inputChangeWrapper(e, "status")} value={this.state.statusInput} className="niceSelect import_input" type="text" placeholder="Status"></input>
                                    </div>
                                    <div className="row"><button onClick={this.createXTNode}>Importieren</button></div>
                                </>
                            }
                        </div>
                    </div>
                </div>
                {this.state.importSuccess &&
                    <Redirect to="/data" />
                }
            </>
        )
    }
}

const NodeItem = ({node, depth ,checkBoxHandler, id}) =>{
    const checkBoxWrapper = (e) => {
        checkBoxHandler(depth);
    }
    return(
        <div className="nodeItem" id={id}>
            <div className="nodeItem_name">
                {depth.length <=1 &&
                    <input onChange={checkBoxWrapper} className="nodeItem_check" type="checkbox" disabled="disabled" checked={node.checked}></input>
                }
                {depth.length > 1 &&
                    <input onChange={checkBoxWrapper} className="nodeItem_check" type="checkbox" checked={node.checked}></input>
                }
                <span>{node.name}</span></div>
                {(node.subnodes && node.checked) && 
                    <div className="nodeItem_children"> 
                        {
                            node.subnodes.map((el, i) =>{
                                return(
                                    <NodeItem node={el} key={i} depth={[...depth, i]} checkBoxHandler={checkBoxHandler}/>
                                )
                            })
                        }
                    </div>
                }
        </div>
    )
}

export default AdminImport;