import React from "react";

class SaveThrowDialog extends React.Component{
    render(){
        let text = {}
        text.save = "Möchtest du deine Änderungen speichern?"
        text.throw = "Möchtest du deine Änderungen verwerfen?"
        let buttonClass = "dialogButton "
        buttonClass += (this.props.dialogType === "save") ? "dialogSaveButton" : "dialogThrowButton"
        return(
            <div id="saveThrowDialog">
                <div style={{width: "100%", textAlign: "center"}}>
                    <span>{text[this.props.dialogType]}</span>
                </div>
                <div style={{width: "50%", minWidth: 200, display: "flex", justifyContent: "space-around", alignItems: "center"}}>
                    <button onClick={(this.props.dialogType === "save") ? this.props.saveConfig : this.props.throwConfig}className={buttonClass}>{(this.props.dialogType === "save") ? "Speichern" : "Verwerfen"}</button>
                    <button onClick={this.props.toggleDialogModal} className="dialogCancelButton">Abbrechen</button>
                </div>
            </div>
        )
    }
}

export default SaveThrowDialog;