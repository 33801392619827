import React from "react";

class ModalFolder extends React.Component{
    constructor(props){
        super(props);
        this.onClickWrapper = this.onClickWrapper.bind(this);
    }
    onClickWrapper(){
        this.props.folderClickHandler(this.props.index);
    }
    render(){
        return(
            <div onClick={this.onClickWrapper} className={(this.props.index === this.props.activeFolder) ? "modalFolder inFocus" : "modalFolder"}>
                <span>{this.props.headline}</span>
            </div>

        )
    }
}

export default ModalFolder;