import React from "react";

class RTable extends React.Component{
    constructor(props){
        super(props);
        this.rowOffset = 2;
    }
    render(){
        let rtableStyle = {display: "grid", gridTemplateRows: "repeat("+(this.props.values.length+1).toString()+",40px)", gridTemplateColumns: "1.5fr 1fr 1fr 0.8fr"}
        let style = [];
        for(let i = 0; i < 5; i++){
            let c = {gridRow: "1/2",gridColumn:"1/2",display:"flex",justifyContent:"flex-start",alignItems: "center",boxSizing:"border-box", padding: 3, fontWeight: "700", cursor: "auto"};
            c.gridColumn = (i+1).toString() + "/" + (i+2).toString();
            style.push(c);
        }
        return(
            <div id="rTable" style={rtableStyle}>
                <div className="tableCell"style={style[0]}>E-Mail</div>
                <div className="tableCell"style={style[1]}>Name</div>
                <div className="tableCell"style={style[2]}>Abteilung</div>
                <div className="tableCell"style={style[3]}>{(this.props.activatedFolder === 0) ? "Anmeldedatum" : "Benutzerrechte"}</div>
                {this.props.values.length > 0 && this.props.values.map( (el, index) =>{
                    return(
                        <TableRow
                            values = {el}
                            key = {index}
                            rowIndex = {index + this.rowOffset}
                            keys = {Object.keys(el)}
                            rowOffset = {this.rowOffset}
                            clickHandler = {this.props.clickHandler}
                            selectedRow={this.props.selectedRow}
                        />
                    )
                })}
                {this.props.values.length < 1 &&
                    <div style={{gridColumn: "1/5", textAlign: "center", padding: 10, color: "grey",}}>Derzeit sind keine Einträge vorhanden.</div> 
                }
            </div>
        )
    }
}

class TableRow extends React.Component{
    constructor(props){
        super(props);
        this.clickHandlerWrapper = this.clickHandlerWrapper.bind(this);
        this.convertPermission = this.convertPermission.bind(this);
    }
    clickHandlerWrapper(){
        this.props.clickHandler(this.props.rowIndex-this.props.rowOffset);
    }
    convertPermission(el){
        switch(el){
            case 1:
                return "Admin";
            case 2:
                return "Planer";
            case 3:
                return "Nutzer";
            default:
                return "Error";
        }
    }
    render(){
        let index = 0;
        return this.props.keys.map((el) => {
            let c = {
                gridRow: (this.props.rowIndex).toString()+"/"+(this.props.rowIndex+1).toString(), 
                gridColumn: (index+1).toString() + "/" + (index+2).toString(),
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                boxSizing: "border-box",
                padding:3,
                transition: "all 0.2s",
            }
            if(this.props.selectedRow === (this.props.rowIndex-this.props.rowOffset)){
                c.background = "rgb(210, 230, 255)";
                // c.fontWeight = "700";
            }
            let formatInput; 
            if(el === "timestamp"){
                formatInput =  (new Date(this.props.values[el])).toLocaleString("de-DE").split(",")[0];
            }
            else if(el === "name" || el === "department"){
                formatInput = this.props.values[el].split(" ").map( (e) =>{return e[0].toUpperCase()+e.substring(1)+ " "})
            }
            else if(el === "permission"){
                formatInput = this.convertPermission(this.props.values[el]);
            }
            else{
                formatInput = this.props.values[el];
            }
            if(el !== "_id"){
                index++;
                return(
                    <div onClick={this.clickHandlerWrapper} className="tableCell"style={c} key={index}>{formatInput}</div>
                )
            }
        })
    }
}

export default RTable;