import React from "react";
import { Switch, Route, Link } from "react-router-dom";

import Headline from "./Headline.js";
import AbfrageNeu from "./AbfrageNeu.js"
import AbfrageReport from "./AbfrageReport.js"

import "./Abfrage.css";

class Abfrage extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            currentUrl: null,
            elements: [],
        }
        this.deleteHandler = this.deleteHandler.bind(this);
        this.setUrl = this.setUrl.bind(this);
        this.fetchReports();
    }
    fetchReports(){
        this.props.setAppState({
            isLoading: true
        });
        let request = this.props._get(window.api + "/abfrage/")
        request.then( (data) => {
            let parsedData = JSON.parse(data);
            this.props.setAppState({
                reports: parsedData || [],
                elements: parsedData.elements,
                isLoading: false
            })
        }).catch((err)=>console.log(err));
    }
    setUrl(urlString){
        this.setState({
            currentUrl: urlString,
        });
    }
    editHandler(){
        //TODO
    }
    deleteHandler(index){
        this.props.setAppState({
            isLoading: true,
        });

        let deletedReport = { id: this.props.reports[index]._id};
        let cacheReports = this.props.reports.filter( (x, i) =>{
            if(i !== index){
                return true;
            }
            else{
                return false;
            }
        });

        deletedReport = JSON.stringify(deletedReport);
        let request = this.props._delete(window.api + "/abfrage/report/delete", deletedReport, true);
        request.then( (data) =>{
            console.log(data.responseText);
            this.props.setAppState({
                isLoading: false,
                reports: cacheReports,
            })
        });
    }
    render(){
        let headline = null;
        let breadcrumb = null;
        if(this.state.currentUrl === "AbfrageIndex"){
            headline= "Übersicht";
            breadcrumb= "abfrage";
        }
        else if(this.state.currentUrl === "AbfrageNeu"){
            headline= "Neue Ansicht";
            breadcrumb= "abfrage";
        } 
        else{
            headline= "Report";
            breadcrumb= "abfrage";
        } 
        return(
            <div className="whiteWrapper" id="abfrageWrapper">
                <Headline breadcrumb={breadcrumb} headline={headline} />
                <Switch>
                    <Route exact path="/abfrage/report" >
                        {(this.props.location.state) && 
                            <AbfrageReport 
                                setUrlHandler={this.setUrl} 
                                filter={this.state.filter}
                                _get = {this.props._get}
                                buildQueryString={this.props.buildQueryString}
                                isLoading={this.props.isLoading}
                                elements={this.props.location.state.elements}
                                reportName={this.props.location.state.reportName}
                                setAppState={this.props.setAppState}
                                report = {this.props.location.state.report}

                            />
                        }
                        {(!this.props.location.state) && 
                            <AbfrageReport 
                                setUrlHandler={this.setUrl} 
                                filter={this.state.filter}
                                _get = {this.props._get}
                                buildQueryString={this.props.buildQueryString}
                                isLoading={this.props.isLoading}
                                setAppState={this.props.setAppState}
                            />
                        }
                    </Route>
                    <Route exact path="/abfrage/neu" >
                        {(this.props.location.state) && 
                            <AbfrageNeu 
                                setUrlHandler={this.setUrl} 
                                _get = {this.props._get}
                                _post = {this.props._post}
                                toggleGui = {this.props.toggleGui}
                                toggleLoadingState = {this.props.toggleLoadingState}
                                report = {this.props.location.state.report}
                                reportIndex = {this.props.location.state.reportIndex}
                                reportName={this.props.location.state.reportName}
                            />
                        }
                        {(!this.props.location.state) &&
                            <AbfrageNeu 
                                setUrlHandler={this.setUrl} 
                                _get = {this.props._get}
                                _post = {this.props._post}
                                toggleGui = {this.props.toggleGui}
                                toggleLoadingState = {this.props.toggleLoadingState}
                            />
                        
                        }
                    </Route>
                    <Route exact path="/abfrage" >
                        <AbfrageIndex 
                            reports={this.props.reports} 
                            deleteHandler={this.deleteHandler}
                            setUrlHandler={this.setUrl}
                            isLoading={this.props.isLoading}
                        />
                    </Route>
                </Switch>
            </div>
        )
    }
}

class AbfrageIndex extends React.Component{

    componentDidMount(){
        this.props.setUrlHandler("AbfrageIndex")
    }
    render(){
        return(
            <div id="reportWrapper">
                <div id="buttonWrapper">
                    <Link to={{pathname:"/abfrage/neu", state: {elements: []}}} className="button" style={{marginTop: 50, textAlign: "center"}} id="newReport">Neuer Report</Link>
                </div>
                <div id="reportItemWrapper">
                    {(this.props.isLoading) &&
                            <div style={{backgroundColor: "lightgrey",padding: "10px 0px", height: "auto", display: "block"}} className="reportItem">
                                <div style={{margin: "0 auto"}} className="cssLoader cssLoaderActivated"></div>
                            </div>
                    }
                    {(!this.props.isLoading) && this.props.reports.map((value,index) =>{
                        return(
                            <ReportItem 
                                // name={this.props.reports[index].name} 
                                // besitzer={this.props.reports[index].owner} 
                                // elements={this.props.reports[index].elements}
                                // erstellungsdatum={this.props.reports[index].date} 
                                report={this.props.reports[index]}
                                elements={this.props.reports[index].elements}
                                index={index}
                                deleteHandler={this.props.deleteHandler}
                                key={index}
                                isLoading={this.props.isLoading}
                                />
                        )
                    })}
                </div> 
            </div>

        )
    }
}

class ReportItem extends React.Component{

    constructor(props){
        super(props);
        this.deleteWrapper = this.deleteWrapper.bind(this);
    }
    deleteWrapper(){
        this.props.deleteHandler(this.props.index)
    }

    render(){
        let date = new Date();
        date.setTime(this.props.report.date);
        return(
            <div className="reportItem">
                <div className="reportItem_column">
                    <span className="reportItem_label">Name</span>
                    <span className="reportItem_value">{this.props.report.name}</span>
                </div>
                <div className="reportItem_column">
                    <span className="reportItem_label">Besitzer</span>
                    <span className="reportItem_value">{this.props.report.owner}</span>
                </div>
                <div className="reportItem_column">
                    <span className="reportItem_label">Erstellungsdatum</span>
                    <span className="reportItem_value">{date.toLocaleDateString("de-DE", {weekday: "short", year: "numeric", month:"short", day:"numeric"})}</span>
                </div>
                <div className="reportItem_column actionButtons">
                    <span className="reportAction run noSelect">
                        <Link to={{
                            pathname: "/abfrage/report",
                            state: {
                                reportName: this.props.report.name,
                                report: this.props.report,
                                elements: this.props.elements
                            }
                        }} style={{textDecoration: "none"}}><i title="Generieren" style={{display: "block"}}className="material-icons">send</i></Link>
                    </span>
                    <span className="reportAction edit noSelect">
                        <Link to={{
                            pathname:"/abfrage/neu", 
                            state: {
                                report: this.props.report,
                                reportIndex: this.props.index,
                                elements: this.props.elements
                            }}}
                                style={{textDecoration: "none"}}><i title="Bearbeiten" style={{display: "block"}} className="material-icons">create</i></Link>
                    </span>
                    <span className="reportAction delete noSelect" onClick={this.deleteWrapper}><i title="Löschen"style={{display: "block"}}className="material-icons">delete_forever</i></span>
                </div>
            </div>
        )
    }
}

export default Abfrage;
