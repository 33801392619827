/*TODO
    todo:   find a way to show slotsize related type-of-ad information
    
    testing:   define iqadtile sequence rule per mandant/site - onImport
    
    todo:   onImport - also use and obey the site related limit header bids by handle rules
    
    todo:   show applied rules like comments - limitHeaderBidsByHandle and window.IMPORTRULES[mandantLevel1Id]
    
    nice-to-have todo:   onImport show progress information
    
    todo:   onSave - save planning-wizard data-object eq a site configuration in database

    todo:   sanitize comment text input
        
    todo FR:    show comment - site layout related: implement a site layout comment and sort comment texts - topmost is latest and bottom-most is first
    
    todo FR:    add site type information in site header section - show it as text below SITE HEADER
        
    testing:   convert shapes config to TMS impex config

 MANDATORY
    todo:   node minify and - webpack if needed - build from source
 
    todo:   connect to database & CRUD-Operations
    
    todo:   implement user authentication 
 */
// window.addEventListener("load", windowLoadHandler, false);

class PlanningWizz{
    constructor(){
        this.variableInit();
        this.init();
    }
    variableInit(){
        /**
         * global items
         */
        window.makeSingleShape = function(){};
        window.makeCustomShape = function(){};
        window.makeMiniMap = function(){};
        window.drawScreen = function(){};
        window.makePwMenu = function(){};
        window.shapes = [];
        window.timer = null;
        window.errorMultipleMessages = [];
        window.createFullsize = function() {};
        window.createTwoTird = function() {};
        window.createOneTird = function() {};
        window.createCustom = function() {};
        window.addIqadtileId = function() {};
        window.removeIqadtileId = function() {};
        window.addOneSlotsize = function() {};
        window.removeOneSlotsize = function() {};
        window.addMultipleSlotsize = function() {};
        window.removeMultipleSlotsize = function() {};
        window.removeAllSlotsize = function() {};
        window.toggleInformationWrapper = function() {};
        window.makeConfigurationText = function() {};
        window.makeTmsConfigurationText = function() {};
        window.importTmsConfiguration = function() {};
        window.isValidJson = function() {};
        window.createPosition = function() {};
        window.saveConfiguration = function() {};
        window.toggleAppliedRules = function() {};
        // data list item
        window.IQDTILEID= 'iqadtile1,iqadtile1a,iqadtile10,iqadtile11,iqadtile12,iqadtile13,iqadtile14,iqadtile15,iqadtile16,iqadtile161,iqadtile2,iqadtile3,iqadtile3a,iqadtile4,iqadtile41,iqadtile42,iqadtile43,iqadtile5,iqadtile51,iqadtile52,iqadtile53,iqadtile6,iqadtile7,iqadtile8,iqadtile81,iqadtile82,iqadtile83,iqadtile9,iqadtile99,iqadtile991,iqadtile992,iqadtile993,iqadtileNAT,iqadtileOOP'.split(',');
        ///window.iqdTileIdInUse = [];

        // 2019-09-23 Chrome datalist is broken when more than 28 items are in the list
        //window.iqdTileId = 'iqadtile1,iqadtile10,iqadtile11,iqadtile12,iqadtile13,iqadtile14,iqadtile15,iqadtile16,iqadtile161,iqadtile2,iqadtile3,iqadtile4,iqadtile41,iqadtile42,iqadtile43,iqadtile5,iqadtile51,iqadtile52,iqadtile53,iqadtile6,iqadtile7,iqadtile8,iqadtile81,iqadtile82,iqadtile83,iqadtile9,iqadtile99,iqadtile991'.split(',');

        //data list item
        // Premium Buttons
        // FAZ 210x64 iat14,iat15
        // HBO 200x95 iat15
        // WIWO 150x40 iat15
        //99x1,120x600,150x40,160x600,200x95,200x600,210x64,300x100,300x250,300x300,300x600,510x180,560x170,600x1200,660x250,728x90,800x250,940x250,970x90,970x250,1000x100,1000x125,1000x167,1000x250,1000x333,1000x500,1000x1000
        // iat8
        //120x600,160x600,200x600,300x250,300x300,300x600
        // iat4
        //800x250,940x250,970x90,970x250,1000x100,1000x125,1000x167,1000x250
        // iat16
        //800x250,940x250,970x90,970x250,1000x100,1000x125,1000x167,1000x250,1000x333,1000x500,1000x1000
        window.IQDSLOTSIZE = '99x1,120x600,150x40,160x90,160x600,200x95,200x600,210x64,300x100,300x250,300x300,300x600,510x180,560x170,600x1200,660x250,728x90,800x250,940x250,970x90,970x250,1000x100,1000x125,1000x167,1000x250,1000x333,1000x500,1000x1000'.split(',');
        window.IQDPROGRAMMATICSLOTSIZE = '120x600,160x90,160x600,200x600,300x250,300x600,600x1200,728x90,800x250,970x90,970x250,1000x100,1000x125,1000x167,1000x250,1000x333,1000x500,1000x1000'.split(',');
        // TODO: also use and obey the site related limit header bids by handle rules
        // iqdsde - limitHeaderBidsByHandle and default setting
        // includeTilesHandle = {
        // applikation_blt: ['iqadtile1','iqadtile16']
        // ,applikation: ['iqadtile1','iqadtile4','iqadtile8','iqadtile16']
        // ,bildgal: ['iqadtile1','iqadtile16','iqadtile161']
        // ,artikel: ['iqadtile1','iqadtile4','iqadtile41','iqadtile42','iqadtile5','iqadtile8','iqadtile81','iqadtile16']
        // ,article: ['iqadtile1','iqadtile4','iqadtile41','iqadtile42','iqadtile5','iqadtile8','iqadtile81','iqadtile16']
        // ,homepage: ['iqadtile8','iqadtile4','iqadtile5']
        // }
        // ,includeTiles = includeTilesHandle[AdController._handle] || ['iqadtile1','iqadtile8','iqadtile4','iqadtile5','iqadtile9','iqadtile16'];
        window.IMPORTRULES = {
                // RULE: the iqadtile sequence in each of this array equals iqadtile sequence on website
                // if there are sequence variations per page type there must be an array per each variation
                'iqdsde':['iqadtile14','iqadtile1','iqadtile1a','iqadtile8','iqadtile81','iqadtile82','iqadtile83','iqadtile4','iqadtile41','iqadtile42','iqadtile43','iqadtile99','iqadtile991','iqadtile992','iqadtile993','iqadtile5','iqadtile51','iqadtile52','iqadtile53','iqadtile9','iqadtile161','iqadtile16']
        };
        // main menu methods - do not change, except you really know what you're doing
        // btw the method-name-position in array matters
        window.ALLBUTTONMETHODNAME = 'createFullsize,createTwoThird,createOneThird,createCustom,addIqadtileId,removeIqadtileId,addOneSlotsize,removeOneSlotsize,addMultipleSlotsize,removeMultipleSlotsize,removeAllSlotsize,addComment,toggleComment,toggleConfiguration,copyConfiguration,cloneCurrentPosition,resizeCurrentPosition,importTmsConfiguration,copyTmsConfiguration,saveConfiguration,toggleAppliedRules'.split(',');
        // error messages
        window.ERRORMESSAGE = {
            'shapesEmptyArray':'No position defined'
            ,'shapeNull':'Current position is undefined'
            ,'shapeCommentEmpty':'Position item without comment'
            ,'idEmpty':'Iqadtile-ID is empty string'
            ,'idForbidden':'Iqadtile-ID is not allowed'
            ,'idDouble':'Desired iqadtile-ID is already in use'
            ,'sizeEmpty':'Slotsize is empty string'
            ,'sizeMandatory':'Can not remove mandatory slotsize'
            ,'sizeForbidden':'Slotsize is not allowed'
            ,'sizeDouble':'Slotsize is already set'
            ,'sizeNull':'Slotsize is not set'
            ,'commentEmpty':'The comment is empty string'
            ,'noSeparator':'Comma separated numbers are expected: width,height <- px'
            ,'tmsEmpty':'TMS JSON is empty string'
            ,'tmsNoJson':'TMS JSON is formally incorrect'
            ,'userPromptDelete':'Möchtest du ###item### wirklich löschen?'
            ,'missingFeature':'No import possible - Your browser does not support Array.prototype.findIndex'
        }

    }
    init(){
        windowLoadHandler();

        function windowLoadHandler() {
            canvasApp();
        };

        //let us assume, that all the browsers in 2019 supports the use of canvas element
        function canvasSupport() {
            return true;
        };

        /**
         * the main app section
         */
        function canvasApp() {
            if (!canvasSupport()) {
                return;
            }
            /*
            function canvasButtons() {
                document.querySelectorAll('[id*="button"]')[0].addEventListener("mouseDown", buttonDownHandler, false);
                document.querySelectorAll('#button')[0].addEventListener("mouseUp", buttonUpHandler, false);
                function buttonDownHandler(){
                    alert('buttonDown');
                }
                function buttonUpHandler(){
                    alert('buttonUp');
                }
            }
            canvasButtons();
            // */
            var theCanvas = document.getElementById("canvasOne")
                ,context = theCanvas.getContext("2d")
                ,theMap = document.getElementById("canvasMiniMap")
                ,contextMap = theMap.getContext("2d")
                ,theMapActiveArea = document.getElementById("canvasMiniMapActiveArea")
                ,contextMapActiveArea = theMapActiveArea.getContext("2d");

            init();

            var numShapes
                ,dragIndex
                ,dragging
                ,mouseX
                ,mouseY
                ,dragHoldX
                ,dragHoldY
                ,timer
                ,targetX
                ,targetY
                ,easeAmount;

            function init() {
                easeAmount = 0.45;

                //makeSingleShape();

                drawScreen();
                makeMiniMap();
                
                window.addEventListener("scroll", drawMiniMapActiveArea, false);

                theCanvas.addEventListener("mousedown", mouseDownListener, false);
                theCanvas.addEventListener("mouseenter", mouseEnterListener, false);
                theCanvas.addEventListener("mouseleave", mouseLeaveListener, false);
                
                document.getElementById('showCommentWrapper').addEventListener("click", function(){window.toggleInformationWrapper('comment');}, false);
                document.getElementById('showErrorWrapper').addEventListener("click", function(){window.toggleInformationWrapper('error');}, false);
                document.getElementById('showSiteConfigWrapper').addEventListener("click", function(){window.toggleInformationWrapper('config');}, false);
                
            };

            function makeSingleShape(shapeWidth, shapeHeight) {
                var _shapes = window.shapes
                    ,_tmpW = (!!shapeWidth) ? shapeWidth*1 : 1000
                    ,_tmpH = (!!shapeHeight) ? shapeHeight*1 : 250
                    ,_tmpX = _tmpW/2
                    // iqd orange
                    ,_tmpR = 285
                    ,_tmpG = 85
                    ,_tmpB = 0
                    ,_tmpA = 0.8
                    ,_tmpColor = "rgba(" + _tmpR + "," + _tmpG + "," + _tmpB + "," + _tmpA + ")"
                    // set current top
                    ,_tmpTop = (document.getElementById('canvasOne').getBoundingClientRect().top < 0) ? Math.ceil(Math.abs(document.getElementById('canvasOne').getBoundingClientRect().top)) : 0
                    ,_tmpY = (_tmpH/2) + _tmpTop
                    ,_tmpShape = new IqdAdPostion(_tmpX, _tmpY, _tmpW, _tmpH);
                // set color and alpha using the rgba() method
                _tmpShape.setColor( _tmpColor );
                // push new shape item in global array
                _shapes.push( _tmpShape );
            };
            window.makeSingleShape = makeSingleShape;
            
            function makeCustomShape() {
                var _size_str = document.querySelector('#customPositionSize').value
                    ,_size = _size_str.split('x')
                    ,_sW = _size[0]*1 || 0
                    ,_sH = _size[1]*1 || 0;
                // exit on none valid input
                if(_size_str.indexOf('x') === -1) { showErrorMsg(window.ERRORMESSAGE['noSeparator']); return;}
                // minimal dimension of a shape
                _sW = (_sW <= 333) ? 333 : _sW;
                _sH = (_sH <= 150) ? 150 : _sH;
                // console.log('***** makeCustomShape ', _sW, ' _ ', _sH);
                makeSingleShape(_sW,_sH);
                document.querySelector('#customPositionSize').value = '';
            };
            window.makeCustomShape = makeCustomShape;
        /**
         * the mouse listener section
         */
            // helper used while dragging is true
            function onTimerTick() {
                //#console.log('+-+-+- onTimerTickshapes ', window.shapes);
                var _shapes = window.shapes
                    ,_numShapes = _shapes.length;
                // because of reordering, the dragging shape is the last one in the array.
                _shapes[_numShapes-1].x = _shapes[_numShapes-1].x + easeAmount*(targetX - _shapes[_numShapes-1].x);
                _shapes[_numShapes-1].y = _shapes[_numShapes-1].y + easeAmount*(targetY - _shapes[_numShapes-1].y);
                
                // stop the timer when the target position is reached (close enough)
                if ((!dragging)&&(Math.abs(_shapes[_numShapes-1].x - targetX) < 0.1) && (Math.abs(_shapes[_numShapes-1].y - targetY) < 0.1)) {
                    _shapes[_numShapes-1].x = targetX;
                    _shapes[_numShapes-1].y = targetY;
                    // stop timer:
                    clearInterval(window.timer);
                }
                drawScreen();
            };
            
            function mouseDownListener(evt) {
                //#console.log('# mouseDownLisetener');
                var _shapes = window.shapes
                    ,_numShapes = _shapes.length
                    ,i = _numShapes - 1 
                    // getting mouse position correctly 
                    ,_bRect = theCanvas.getBoundingClientRect();
                mouseX = (evt.clientX - _bRect.left)*(theCanvas.width/_bRect.width);
                mouseY = (evt.clientY - _bRect.top)*(theCanvas.height/_bRect.height);

                // for all items in shapes array do a hitTest
                // start at last item in array due to order
                // the loop exits on first match
                for (; i >= 0; i--) {
                    // if dragging is true the loop is stopped
                    // needed for proper deleting shapes
                    if (_shapes[i].hitTest(mouseX, mouseY) && !dragging) {    
                        dragging = true;
                        // the following variable will be reset if this loop repeats with another successful hit
                        // new behavior is different due to loop starts with last item and exits on first match
                        dragIndex = i;
                        console.log(dragIndex, evt);
                    }
                }

                if (dragging) {
                    window.addEventListener("mousemove", mouseMoveListener, false);

                    //place currently dragged shape on top
                    //#console.log('+-+-+- mouseDownListener shapes 1 ',shapes[dragIndex].deleteMe);
        //
                    if(_shapes[dragIndex].deleteMe === false) {
                        _shapes.push(_shapes.splice(dragIndex,1)[0]);

                        //shape to drag is now last one in array
                        dragHoldX = mouseX - _shapes[_numShapes-1].x;
                        dragHoldY = mouseY - _shapes[_numShapes-1].y;

                        //The "target" position is where the object should be if it were to move there instantaneously. But we will
                        //set up the code so that this target position is approached gradually, producing a smooth motion.
                        targetX = mouseX - dragHoldX;
                        targetY = mouseY - dragHoldY;

                        //stop timer - prevents multiple timers on dblclick an item
                        if(window.timer) { clearInterval(window.timer); }
                        //start timer
                        window.timer = setInterval(onTimerTick, 1000/30);
                        //#console.log('+-+-+- mouseDownListener shapes 2 deleteMe === false ', window.shapes);
        //
                        }
        ///*
                    if(_shapes[dragIndex].deleteMe === true) {
                        var _positionLabel = _shapes[dragIndex].positionLabel
                            ,_iatId = _shapes[dragIndex].iatId
                            ,_callbackBtnNo = function(dragIndex){
                                // console.log(this.id, dragIndex);
                                window.shapes[dragIndex].deleteMe = false;
                                // hide confirm message
                                toggleInformationWrapper('confirm');
                                
                            }
                            ,_callbackBtnYes = function(dragIndex){
                                // console.log(this.id, dragIndex);
                                // enable all options in iqadtileId datalist which are not used by any item in window.shapes
                                // if there are no items in window.shapes all options will be enabled
                                window.removeIqadtileId(dragIndex);
                                // remove the shape from array
                                window.shapes.splice(dragIndex,1);
                                // remove class from comment toggle button
                                document.getElementById('toggleComment').removeAttribute('class');
                                
                                window.drawScreen();
                                window.drawMiniMap();
                                // hide confirm message
                                toggleInformationWrapper('confirm');
                            }
                            ,_btnNo = {}
                            ,_btnYes = {};

                        showConfirmMsg( window.ERRORMESSAGE['userPromptDelete'].replace('###item###',_positionLabel+' '+_iatId) );
                        _btnNo = document.getElementById('confirmDeleteNo');
                        _btnYes = document.getElementById('confirmDeleteYes');
                        _btnNo.addEventListener('click', function(){_callbackBtnNo(dragIndex);});
                        _btnYes.addEventListener('click', function(){_callbackBtnYes(dragIndex);});
                    }
        //*/
                }
                //debugger;
                // set position sequence value
                positionSequencing();
                
                theCanvas.removeEventListener("mousedown", mouseDownListener, false);
                window.addEventListener("mouseup", mouseUpListener, false);

                // code below prevents the mouse down from having an effect on the main browser window:
                if (evt.preventDefault) {
                    evt.preventDefault();
                } // standard
                else if (evt.returnValue) {
                    evt.returnValue = false;
                } // older IE
                return false;
            };

            function mouseEnterListener() {
                //#console.log('# mouseEnterLisetener');
                // set position sequence value
                positionSequencing();
            };

            function mouseLeaveListener() {
                //#console.log('# mouseLeaveLisetener');
                // set position sequence value
                positionSequencing();
            };

            function mouseUpListener(evt) {
                //#
                // console.log('# begin mouseUpLisetener');
                theCanvas.addEventListener("mousedown", mouseDownListener, false);
                window.removeEventListener("mouseup", mouseUpListener, false);
                if (dragging) {
                    dragging = false;
                    window.removeEventListener("mousemove", mouseMoveListener, false);
                }
                // set position sequence value
                positionSequencing();
                // the minimap
                drawMiniMap();
                
                // console.log('# end mouseUpLisetener');
            };

            function mouseMoveListener(evt) {
                //#console.log('# mouseMoveLisetener ', shapes);
                if(window.shapes.length===0) { window.removeEventListener("mousemove", mouseMoveListener, false);return; }
                var posX
                    ,posY
                    ,_shapes = window.shapes
                    ,_numShapes = _shapes.length
                    //,shapeRad = shapes[numShapes-1].radius
                    ,shapeWidth = _shapes[_numShapes-1].width
                    ,shapeHeight = _shapes[_numShapes-1].height
                    ,minX = shapeWidth
                    ,maxX = theCanvas.width - shapeWidth
                    ,minY = shapeHeight
                    ,maxY = theCanvas.height - shapeHeight
                    // getting mouse position correctly 
                    ,_bRect = theCanvas.getBoundingClientRect();
                mouseX = (evt.clientX - _bRect.left)*(theCanvas.width/_bRect.width);
                mouseY = (evt.clientY - _bRect.top)*(theCanvas.height/_bRect.height);

                // clamp x and y positions to prevent object from dragging outside of canvas
                posX = mouseX - dragHoldX;
                posX = (posX < minX) ? minX : ((posX > maxX) ? maxX : posX);
                posY = mouseY - dragHoldY;
                posY = (posY < minY) ? minY : ((posY > maxY) ? maxY : posY);

                targetX = posX;
                targetY = posY;
            };
        /**
         * the drawing methods section
         */
            function drawMiniMap() {
                // copy canvas by DataUrl
                var _sourceImageData = theCanvas.toDataURL("image/png")
                    ,_destCanvasContext = contextMap
                    ,_destinationImage = new Image;
                _destinationImage.onload = function() {
                    var _scalingFactor = Math.floor(((window.innerHeight/1e3)*1e2))
                        ,_scaledWidth = ((1e2/1e2)*_scalingFactor)
                        ,_scaledHeight = ((1e3/1e2)*_scalingFactor)
                        ,_trgt = document.querySelector('#canvasMiniMap');
                    //#console.log('***** window.innerHeight ', window.innerHeight, (window.innerHeight/1e3)*1e2, ' _ ', ((1e2/1e2)*scalingFactor), ' _ ', ((1e3/1e2)*scalingFactor));
                    // _trgt.width = _scaledWidth;
                    // _trgt.height = _scaledHeight;

                    _destCanvasContext.drawImage(_destinationImage,0,0,_scaledWidth,_scaledHeight);
                    //#console.log('***** ', document.querySelector('[id=canvasMiniMap]').width, ' _ ', document.querySelector('[id=canvasMiniMap]').height);
                };
                contextMap.clearRect(0, 0, theCanvas.width, theCanvas.height);
                _destinationImage.src = _sourceImageData;
            };
            window.drawMiniMap = drawMiniMap;
            
            function drawMiniMapActiveArea() {
                //#console.log('***** drawMiniMapActiveArea called');
                var _ctx = contextMapActiveArea
                    // set color and alpha using the rgba() method
                    // green
                    ,_tmpR = 235 
                    ,_tmpG = 252
                    ,_tmpB = 121
                    ,_tmpA = 0.3
                    ,_tmpColor = "rgba(" + _tmpR + "," + _tmpG + "," + _tmpB + "," + _tmpA + ")"
                    ,_tmpHeight = window.innerHeight
                    ,_tmpTop = (document.getElementById('canvasOne').getBoundingClientRect().top < 0) ? Math.ceil(Math.abs(document.getElementById('canvasOne').getBoundingClientRect().top)) : 0
                    ,_scalingFactor = Math.floor(((window.innerHeight/1e3)*1e2))
                    ,_scaledWidth = ((1e2/1e2)*_scalingFactor)
                    ,_scaledHeight = ((1e3/1e2)*_scalingFactor)
                    ,_trgt = document.querySelector('#canvasMiniMapActiveArea');
                _tmpTop = ((_tmpTop/1e2) * _scalingFactor)/10;
                _tmpHeight = ((_tmpHeight/1e2) * _scalingFactor)/10;
                
                // _trgt.width = _scaledWidth;
                // _trgt.height = _scaledHeight;
                
                //ctx.globalCompositeOperation = "multiply";
                _ctx.clearRect(0, 0, theMapActiveArea.width, theMapActiveArea.height);
                _ctx.fillStyle = _tmpColor;
                _ctx.fillRect(0, Math.floor(_tmpTop), theMapActiveArea.width, Math.floor(_tmpHeight));
                //#console.log('***** drawMiniMapActiveArea ', tmpTop, ' _ ', tmpHeight, ' _ ', document.getElementById('canvasOne').getBoundingClientRect().top); 
            };
            window.drawMiniMapActiveArea = drawMiniMapActiveArea;
            
            function makeMiniMap() {
                //#console.log('***** makeMiniMap called');
                drawMiniMap();
                drawMiniMapActiveArea();
            }
            
            window.makeMiniMap = makeMiniMap;

            function drawText() {
                context.globalCompositeOperation = "source-over";
                //context.globalCompositeOperation = "destination-out";
                context.fillStyle = '#ffffff';
                context.font = 'normal bold 100px Verdana';
                context.textAlign = 'center';
                // fake header
                context.fillText('SITE HEADER', theCanvas.width/2, 250+250/2);
                // fake footer
                context.fillText('SITE FOOTER', theCanvas.width/2, (theCanvas.height-500)+250/2);
            };
            
            function drawShapes() {
                //#console.log('***** 1 shapes = ', shapes, ' _ ', numShapes);
                var i = 0
                    ,_shapes = window.shapes
                    ,_numShapes = _shapes.length
                    ,_tmpHTML = ''
                    ,_trgt = [
                        document.querySelector('#allSlotsizeList')
                        ,document.getElementById('toggleComment')
                        ,document.querySelector('#showIqadtileInformation')
                        ,document.querySelector('#showIqadtileComment')
                    ]
                    ,_flagSizeByType = function(item) {
                        var _tmpHTML = ''
                            ,_tmpStr = ''
                            ,_tmpItem = item
                            ,_tmpSize = _tmpItem.getSlotsize()
                            ,_tmpSizeLastItem = _tmpSize[_tmpSize.length-1]
                            ,_programmaticSize = window.IQDPROGRAMMATICSLOTSIZE
                            ,_item
                            ,_tmpColorSelector = 0;
                        
                        for ( _item in _tmpSize ) {
                            // console.log('colors depending on size owned ', _programmaticSize.indexOf(_tmpSize[_item]), ' _ ', _tmpSize[_item] );
                            _tmpColorSelector = (_programmaticSize.indexOf(_tmpSize[_item]) > -1) ? 1 : 0;
                            _tmpStr = (!!_tmpColorSelector) ? '<div class="sizeTile programmaticSize"><span> ' : '<div class="sizeTile"><span>';
                            _tmpStr += _tmpSize[_item]
                            _tmpStr += '</span></div>\r\n';
                            _tmpHTML += _tmpStr;
                        }
                        
                        //console.log('drawShapes > _flagSizeByType _tmpColorSelector = ', !!_tmpColorSelector, ' _ sizeItem = ', _tmpSize[_item]);
                        //console.log('drawShapes > _flagSizeByType _tmpHTML =', _tmpHTML);
                        return _tmpHTML;
                    };

                for (; i < _numShapes; i++) {
                    // set position label information
                    // shape item owning the topmost y-value is P1. shape owning highest y-value is last item in line.
                    // current active shape item
                    if ( i === _numShapes - 1 ) {
                        //#console.log('***** shapes[i].getSlotsize',shapes[i].getSlotsize());
                        // get and show all specific configuration values for current active position
                        // iqadtile-Id and all slotsizes
                        _trgt[0].value = window.shapes[i].getSlotsize();
                        // if shape item owns comment set button color
                        if ( _shapes[i].comment.length > 0 ) {
                            _trgt[1].setAttribute('class','hasComment');
                        } else {
                            _trgt[1].removeAttribute('class');
                        }
                        
                        _tmpHTML = '<p>' + window.shapes[i].getIqadtileId().split(',').join(',\r\n') + '</p>';
                        // flag programmatic sizes
                        _tmpHTML += _flagSizeByType(window.shapes[i]);
                        
                        _trgt[2].innerHTML = _tmpHTML;

                        _tmpHTML = '<p>' + window.shapes[i].getComment() + '</p>';//.toString().split(',').join(',\r\n') + '</p>';
                        _trgt[3].innerHTML = _tmpHTML;
                    }
                    // the drawing of the shape is handled by a function inside the external class.
                    // we must pass as an argument the context to which we are drawing the shape.
                    // i and numShapes are passed for visual differentiation of current active shape and all other shapes
                    window.shapes[i].drawToContext(context,i,_numShapes);
                }
                //#console.log('***** 2 shapes = ', shapes, ' _ ', numShapes);
                // update the global after drawing
        //        window.shapes = shapes;
            };

            function drawGrid() {
                //#console.log('***** dragging ', dragging);
                context.globalCompositeOperation = "source-over";
                //context.globalCompositeOperation = "destination-over";
                // fillRect(x,y,w,h)
                // fake header
                context.fillStyle = '#cccccc';
                context.fillRect(0,250,theCanvas.width,250);
                // fake footer
                context.fillStyle = '#cccccc';
                context.fillRect(0,theCanvas.height-500,theCanvas.width,250);
                // the grid
                context.fillStyle = '#cccccc';
                // vertical lines
                context.fillRect((theCanvas.width/3),0,1,theCanvas.height);
                context.fillRect((theCanvas.width/3)*2,0,1,theCanvas.height);
                // horizontal lines
                var i = 0
                    ,_step = 250
                    ,_max_step = theCanvas.height/_step
                    ,_itm = 10 // intermediate
                    ,_max_itm = theCanvas.height/_itm;
                for ( ; i < _max_step; i++ ) {
                    context.fillRect(0,i*_step,theCanvas.width,1);
                }
                i = 0;
                for ( ; i < _max_itm; i++ ) {
                    context.fillRect(0,i*_itm,10,1);
                    context.fillRect((theCanvas.width/3)-5,i*_itm,11,1);
                    context.fillRect(2*(theCanvas.width/3)-5,i*_itm,11,1);
                    context.fillRect(theCanvas.width-10,i*_itm,10,1);
                }
            };

            function drawBackground() {
                // background
                context.globalCompositeOperation = "source-over";//"destination-over";
                context.fillStyle = "#898989";
                context.fillRect(0,0,theCanvas.width,theCanvas.height);
                //context.globalAlpha = 0.7;  
            };

            function drawScreen() {
                //#console.log('+-+-+- drawscreen called');
                var _trgtInfo = document.querySelector('#showIqadtileInformation')
                    ,_trgtComment = document.querySelector('#showIqadtileComment')
                    //,_err = window.errorMultipleMessages
                    //,_errLength = _err.length
                    ,_item;
                context.clearRect(-.5, -.5, theCanvas.width, theCanvas.height);
                
                drawBackground();
                drawGrid();
                drawText();
                if(window.shapes.length > 0) {
                    _trgtInfo.removeAttribute('class');
                    drawShapes();
                } else {
                    // clear iqadtile information area
                    _trgtInfo.innerHTML = '';
                    _trgtComment.innerHTML = '';
                    _trgtInfo.setAttribute('class','hidden');
                }
            };
            window.drawScreen = drawScreen;

            function toggleInformationWrapper(com) {
                var _trgt = []
                    ,_item = '';
                if ( com === 'comment' ) {
                    _trgt = ['showCommentWrapper','showIqadtileComment'];
                }
                if ( com === 'error' ) {
                    _trgt = ['showErrorWrapper','showError'];
                }
                if ( com === 'config' ) {
                    _trgt = ['showSiteConfigWrapper','showSiteConfig'];
                }
                if( com ===  'confirm' ) {
                    _trgt = ['showConfirmWrapper','showConfirm'];
                }
                for( _item in _trgt ) {
                    //#console.log('***** toggleInformationWrapper called ', _trgt[_item], ' _ ', com);
                    document.getElementById(_trgt[_item]).classList.toggle('hidden');
                }
                // make body unscrollable
                document.body.classList.toggle('noscroll');
                // reset multiple error messages
                if( document.getElementById('showError').classList.contains('hidden') ) {
                    window.errorMultipleMessages = [];
                    document.getElementById('showError').innerHTML = '';
                }
                // reset confirm delete dialog
                if( document.getElementById('showConfirm').classList.contains('hidden') ) {
                    document.getElementById('showConfirm').innerHTML = '';
                }
            };
            window.toggleInformationWrapper = toggleInformationWrapper;

            function showConfirmMsg( confirmMsg ) {
                var _tmpMsg = confirmMsg+''
                    ,_trgt = document.getElementById('showConfirm')
                    ,_tmpText = document.createElement('p')
                    ,_btnNo = document.createElement('button')
                    ,_btnYes = document.createElement('button');
                _btnNo.innerHTML = 'Abbrechen';
                _btnYes.innerHTML = 'Löschen';
                _btnNo.id = 'confirmDeleteNo';
                _btnYes.id = 'confirmDeleteYes';
                _tmpText.innerHTML = confirmMsg;
                _trgt.appendChild(_tmpText);
                _trgt.appendChild(_btnNo);
                _trgt.appendChild(_btnYes);
                // show confirm dialog
                window.toggleInformationWrapper('confirm');
            };
            window.showConfirmMsg = showConfirmMsg;
            
            function showErrorMsg( errorMsg ) {
                var _tmpMsg = errorMsg+''
                    ,_trgt = document.getElementById('showError').innerHTML = '<p>' + _tmpMsg + '</p>'
                window.toggleInformationWrapper('error');
                console.error( _tmpMsg, ' _ ', Math.floor(new Date()) );
            };
            window.showErrorMsg = showErrorMsg;
            
            function showErrorMultipleMsg( errorMsg ) {
                var _tmpMsg = errorMsg + ''
                    ///,_err = window.errorMultipleMessages
                    ///,_errLength = _err.length;
                //window.toggleInformationWrapper('error');
                console.error( _tmpMsg );
                document.getElementById('showError').innerHTML += '<p>' + _tmpMsg + '</p>';
                if ( document.getElementById('showError').classList.contains('hidden') ) {
                    toggleInformationWrapper('error');
                }
                window.errorMultipleMessages.push( _tmpMsg );
            };
            window.showErrorMultipleMsg = showErrorMultipleMsg;
        // for debugging only - remove testSequence before launch
        window.testSequence = [];    
            function positionSequencing() {
                //#console.log('@@@ positionSequencing called');
                var _shapes = window.shapes
                    ,_tmpShapes = []
                    ,_item = ''
                    ,_item2 = '';
                //console.log('@@@ positionSequencing unsorted _tmpShapes');
                //console.table(_tmpShapes);
                _tmpShapes = [..._shapes];
                // sort ascending
                _tmpShapes = _tmpShapes.sort(function(a, b) {
                    //console.log('@@@ positionSequencing sort item a ', a);
                    //console.log('@@@ positionSequencing sort item b ', b);
                    return parseFloat(a.y) - parseFloat(b.y);
                });
                // for debugging only - remove testSequence before launch
                window.testSequence = _tmpShapes;
                //console.log('@@@ positionSequencing sorted by y _tmpShapes')
                //console.table(_tmpShapes);
                _item = '';
                for(_item in _tmpShapes) {
                    //
                    /*
                    console.log('@@@ positionSequencing _item ', _item, typeof _item);
                    console.log('@@@ positionSequencing _tmpShapes[_item] ', _tmpShapes[_item]);
                    console.log('@@@ positionSequencing _tmpShapes[_item].uid ', _tmpShapes[_item].uid);
                    console.log('@@@ positionSequencing _shapes[_item].uid ', _shapes[_item].uid);
                    console.log('@@@ positionSequencing x.uid === y.uid ', ( _shapes[_item].uid === _tmpShapes[_item].uid ));
                    //*/
                    for(_item2 in _shapes) {
                        if ( _shapes[_item2].uid === _tmpShapes[_item].uid ) { 
                            _shapes[_item2].setPositionLabel('P' + ( (_item * 1) + 1) ); 
                        }
                    }
                    //_shapes[_item2].drawToContext(context, (_item * 1), _shapes.length);
                }
                drawScreen();
            };
            window.positionSequencing = positionSequencing;
            // import TMS JSON
            function createPosition() {
                // reset shapes array to prevent double items
                if(!!window.shapes) { window.shapes = []; }
                var _shapes = window.shapes
                    ,_cloneShape = {}
                    ,_tmpRule = document.querySelector('[name="tmsConfigurationRule"]').value + ''
                    ,_tmpConfig = JSON.parse(document.querySelector('[name="tmsConfiguration"]').value)
                    ,_allIdOptionItem = document.querySelector('#all-theIqadtileId').options
                    ,_item = ''
                    ,_item2 = ''
                    ,_tmpTop = 125
                    ,_tmpTarget = {}
                    ,_tmpStyle = ''
                    ,_examineSize = function(size) {
                        var _segment = size.split('x');
                        _segment[0] *= 1;
                        _segment[1] *= 1;
                        //console.log('_examineSize(size) _segment[]', _segment);
                        // push item if not exists in target array
                        if ( _sizeThresholdIndex.indexOf(_segment[0]) === -1 ) {
                            _sizeThresholdIndex.push(_segment[0]);
                        }
                        // sort array items ascending
                        _sizeThresholdIndex.sort(function(a, b) {
                            //console.log('@@@ positionSequencing sort item a ', a);
                            //console.log('@@@ positionSequencing sort item b ', b);
                            return parseFloat(a) - parseFloat(b);
                        });
                    }
                    ,_sizeThresholdIndex = []
                    ,_sizeThresholdIndexItemMax = 0
                    ,_makeWidth = function(sizeSegment) {
                        var _segment = sizeSegment*1
                            ,_width = 1e3;
                        //console.log('_makeWidth sizeSegment ', _segment);
                        if ( _segment > 500 && _segment < 700 ) { _width = 666; }
                        if ( _segment < 500 ) { _width = 333; }
                        
                        return _width;
                    }
                    ,_makeXpos = function(tmpWidth) {
                        var _width = tmpWidth*1
                            ,_tmpXpos = 500;
                        //console.log('_makeXpos _width', _width);
                        //if ( _width === 1e3 ) { _tmpXpos = 500; }
                        if ( _width === 666 ) { _tmpXpos = (1e3-(1e3/3)); }
                        if ( _width === 333 ) { _tmpXpos = (1e3-((1e3/3)/2)); }
                        
                        return _tmpXpos;
                    }
                    ,_sizeThreshold = false
                    ,_tmpWidth = 1000
                    ,_tmpX = 500
                    ,_tmpConfigSize = []
                    ,_faktorSequence = []
                    ,_importSequence = function(_clientid,_item,_cloneShape,_faktorSequence) {
                        //console.log('createPosition > _importSequence 1 ', _clientid, ' _ ', _item, ' _ ', _cloneShape);
                        var _iatId = _item
                            ,_tmpTop = 250
                            ,_getFaktorArrayId = function (iatId,rulesArray,_faktorSequence) {
                                var _iatId = iatId
                                    ,_arr = rulesArray
                                    ,_result = _arr.indexOf(_iatId);
                                _faktorSequence.push(_result);
                                
                                // console.log('iatId matching item in rulesArray returns index = ', _result, ' _ ', iatId);
                                //console.log('unsorted', _faktorSequence);
                                //console.log('sequenceRule', _arr);
                                
                                //TODO - normalize _faktorSequence array
                                // _faktorSequence <- sort ascending and 
                                // find index by _result value in _faktorSequence and 
                                // return _faktorSequence index as _result
                                // aim: no gaps between sequenced shape items
                                _faktorSequence.sort(function(a, b) {
                                    //console.log('_faktorSequence - item a ', a);
                                    //console.log('_faktorSequence - item b ', b);
                                    //console.log('_faktorSequence - yeah? ', parseFloat(a)+1 === parseFloat(b));
                                    return parseFloat(a) - parseFloat(b);
                                });
                                //console.log('_faktorSequence - sorted', _faktorSequence);
                                //console.log('new result ', _faktorSequence.indexOf(_result), ' _ searchFor', _result, ' _ ', iatId);
                                return _result;
                                /*console.log('_arr.indexOf(_iatId) ', _iatId, ' _ ', _arr.indexOf(_iatId));
                                for ( _item in _arr ) {
                                    console.log('test ', _iatId, ' _ ', _item, ' _ ', _arr[_item]);
                                    if ( _iatId === _arr[_item] ) { return _item*1; break; }
                                }*/
                            }
                            ,_faktor = _getFaktorArrayId(_iatId,window.IMPORTRULES[_clientid],_faktorSequence);
                        _faktor += 1;
                        //
                        // console.log(' yeah? ', _faktor,' _ ',_faktorSequence);
                        _tmpTop *= _faktor;// ((_tmpTop)*_faktor)+125;
                        _tmpTop -= 125;
                        ///console.log('createPosition > _importSequence 2 _tmpTop _ _faktor _ _iatId');
                        ///console.log('createPosition > _importSequence 2 ', _tmpTop,' _ ', _faktor, ' _ ', _iatId);
                        // prevent header and footer overlay
                        if(_tmpTop >= 375 || _tmpTop === 9625) { _tmpTop += 250; }
                        
                        _cloneShape.y = _tmpTop;
                        //console.log('createPosition > _importSequence ',_tmpTop,' _ ', _faktor);
                    }
                    ,_delta = 0;

                for ( _item in _tmpConfig ) {
                    _shapes = window.shapes;
                    //console.log('test 2 ', _item, ' _ ', Object.keys(_tmpConfig), ' _ ', _tmpConfig[_item].enabled);
                    //if ( _tmpConfig[_item].enabled === false ) { console.log(_tmpConfig[_item].dom_id, ' _ ', window.IMPORTRULES[_tmpRule].indexOf(_tmpConfig[_item].dom_id)); _delta++; }
                    if ( _tmpConfig[_item].enabled && _item !== 'iqadtileOOP' && _item !== 'iqadtile2' ) {
                        //
                        // console.log('test 2 ', _item, ' _ ', _tmpConfig[_item].enabled, ' _ _delta ', _delta);
                        // create array of item sizes
                        _tmpConfigSize =  _tmpConfig[_item].size.split(',');
                        // examine each size and push the size-segment in array for threshold testing
                        // last item in array is biggest size-segment value due to sorting of array
                        _tmpConfigSize.forEach(_examineSize);
                        // setting shapes width depending on the first segment of all slotsizes of each item
                        // last item in array is biggest size segment
                        // possible visualization error - 600x1200 - if 600 is biggest size segment
                        // the shape item is drawn two third but should be fullsize
                        _sizeThresholdIndexItemMax = _sizeThresholdIndex[_sizeThresholdIndex.length-1];
                        // set shape width
                        _tmpWidth = _makeWidth(_sizeThresholdIndexItemMax);
                        // generate shape item
                        makeSingleShape(_tmpWidth, 250);
                        // customize shape item parameters
                        _cloneShape = _shapes[_shapes.length-1];
                        _cloneShape.iatId = _item;
                        // disable iqadtileId in datalist
                        for (_item2 in _allIdOptionItem) {
                            if ( typeof(_allIdOptionItem[_item2]) !== 'number' && 
                                typeof(_allIdOptionItem[_item2]) !== 'function' && 
                                typeof(_allIdOptionItem[_item2]) === 'object' && 
                                _allIdOptionItem[_item2].value === _item && 
                                !_allIdOptionItem[_item2].hasAttribute('disabled')
                            ) { _allIdOptionItem[_item2].setAttribute('disabled', true); }
                        }
                        _cloneShape.slotsize = _tmpConfig[_item].size;
                        _cloneShape.x = _makeXpos(_tmpWidth);
                        
                        _importSequence(_tmpRule,_item,_cloneShape,_faktorSequence/*,_delta*/);
                        
                        // remove gaps between enabled and disabled shapes
                        //console.log('yeah! ', _faktorSequence);

                        // set shapes sequence and call drawScreen
                        window.positionSequencing();
                        // reset threshold index array
                        _sizeThresholdIndex = [];
                    } else {
                        _delta++;
                    }
                }
                // draw minimap
                window.makeMiniMap();
            };
            window.createPosition = createPosition;
        /**
         * the main menu section
         */
            function makePwMenu() {
                //#console.log('***** makePwMenu called');
                // the menu items
                var _iatidDl = document.querySelector('#all-theIqadtileId')
                    ,_iatsizeDl = document.querySelector('#oneSlotsize')
                    ,_tiles = window.IQDTILEID
                    ,_sizes = window.IQDSLOTSIZE
                    ,_wizardButton = document.querySelectorAll('[data-button-type]');
                //console.log(_iatidDl);
                // datalists
                _iatidDl.innerHTML = makeDataListOptionFromArray(_iatidDl,_tiles);
                _iatsizeDl.innerHTML =  makeDataListOptionFromArray(_iatsizeDl,_sizes);
                // buttons
                makeButtonMethod(_wizardButton);
                
            };
            // helper generating datalist options
            function makeDataListOptionFromArray(trgt,data) {
            //#console.log('***** makeDataListOptionFromArray called trgt ',trgt,' _ data', data);
                var i = 0
                    ,_data = data
                    ,_dataL = _data.length
                    ,_theHTML =''
                    ,_tmpHTML = '';
                for(; i < _dataL;i++) {
                    //console.log('***** makePwMenu data[i] ', data[i]);
                    _tmpHTML = '<option value="' + _data[i] + '"></option>';//' + _data[i]+"" + '
                    _theHTML += _tmpHTML;
                }
                return _theHTML;
            };
            // helper make configuration text
            function makeConfigurationText(shapesItem) {
                var _shapesItem = shapesItem
                    ,_separator = [',\r\n\t','\r\n\r\n']
                    //,_tmpObjText = JSON.stringify(_shapesItem).split(',"').join(',\r\n\t"').split(',,\r\n\t"}').join(',,"\r\n},').split('",\r\n\t",').join('",",') + '\r\n'
                    ,_tmpText = _shapesItem.positionLabel + _separator[0] +
                        _shapesItem.iatId + _separator[0] +
                        _shapesItem.slotsize + _separator[0] +
                        _shapesItem.getComment('config') + _separator[1];
                    //JSON.stringify(_shapesItem.comment) + _separator[1] ;
                    
                // console.log(typeof _shapesItem, ' _ ', _shapesItem);
                
                return _tmpText;        
            };
            // helper make TMS JSON text
            function makeTmsConfigurationText(shapesItem) {
                var _shapesItem = shapesItem
                    ,_separator = [',\r\n\t','\r\n\r\n']
            /*     ,_tmpText = '"' + _shapesItem.iatId + '": {' +
                    '"enabled": true,' +
                    '"dom_id": "' + _shapesItem.iatId + '",' +
                    '"is_oop": false,' +
                    '"size": "' + _shapesItem.slotsize + '",' +
                    '"keywords": "' + _shapesItem.iatId + '",' +
                    '"tile": "' + _shapesItem.iatId.split('iqadtile').join('') + '",' +
                    '"sizeMapTablet": null,' +
                    '"sizeMapMobile": null,' +
                    '"maxWidth": "",' +
                    '"maxHeight": ""' +
                    '}';
                */
                    // use for TMS import type 'selektiv'
                    ,_tmpText = '\t"' + _shapesItem.iatId + '": {\r\n' +
                    '\t\t"enabled": true,' + // enabled is mandatory for import
                    '\t\t"size": "' + _shapesItem.slotsize + '"\r\n' +
                    '\t}';
                    
                // console.log(typeof _shapesItem, ' _ ', _shapesItem);
            
                return _tmpText;        
            };
            // helper test JSON
            function isValidJson(str) {
                var _str = str
                    ,_result = false;
                // console.log('isValidJson', _str);
                try { JSON.parse(_str); } catch (e) { return _result; }
                _result = true;
                return _result;
            }
            // helper button methods
            function makeButtonMethod(buttons) {
                //#console.log('***** makeButtonMethod called', buttons);
                //console.log(typeof buttons);
                var _tmpButtons = []
                    // the methods
                    ,_ALLBUTTONMETHODNAME = window.ALLBUTTONMETHODNAME
                    ,_allButtonMethodNameLength = window.ALLBUTTONMETHODNAME.length
                    ,i = 0;

                for(let item in buttons) {
                    //console.log(typeof buttons[item]);
                    if (typeof(buttons[item]) !== 'number' && typeof(buttons[item]) !== 'function' && typeof(buttons[item]) === 'object') {
                        _tmpButtons.push(buttons[item]);
                    }
                }
        // rethink the global style
        // https://developer.mozilla.org/de/docs/Web/JavaScript/Reference/Global_Objects/Function/bind
                // the methods
                //window.createFullsize = function() {
                window[_ALLBUTTONMETHODNAME[0]] = function() {
                    window.makeSingleShape(1000, 250);
                    window.positionSequencing();
                    window.makeMiniMap();
                };
                //window.createTwoTird = function() {
                window[_ALLBUTTONMETHODNAME[1]] = function() {
                    window.makeSingleShape(666, 250);
                    window.positionSequencing();
                    window.makeMiniMap();
                };
                //window.createOneTird = function() {
                window[_ALLBUTTONMETHODNAME[2]] = function() {
                    window.makeSingleShape(333, 250);
                    window.positionSequencing();
                    window.makeMiniMap();
                };
                //window.createCustom = function() {
                window[_ALLBUTTONMETHODNAME[3]] = function() {
                    window.makeCustomShape();
                    window.positionSequencing();
                    window.makeMiniMap();
                };
                //window.resizeCurrentPosition = function() {
                window[_ALLBUTTONMETHODNAME[16]] = function() {
                    // exit on no shapes available
                    if ( !window.shapes || window.shapes.length === 0 ) { showErrorMsg(window.ERRORMESSAGE['shapesEmptyArray']); return; }
                    var _shapes = window.shapes//(function() { console.log(shapes);return window.shapes; })()
                        ,_sourceShape = _shapes[_shapes.length-1]
                        ,_size_str = document.querySelector('#editCustomPositionSize').value
                        ,_size = _size_str.split('x')
                        ,_sW = _size[0]*1 || 0
                        ,_sH = _size[1]*1 || 0;
                    // exit on none valid input
                    if(_size_str.indexOf('x') === -1) { showErrorMsg(window.ERRORMESSAGE['noSeparator']); return;}
                    // fallback on no input minimal dimension of a shape
                    _sW = (_sW <= 333) ? 333 : _sW;
                    _sH = (_sH <= 150) ? 150 : _sH;
                    // exit on current shape item not found
                    if ( !_sourceShape ) { showErrorMsg(window.ERRORMESSAGE['shapeNull']); return; }
                    _sourceShape.width = _sW/2;
                    _sourceShape.realWidth = _sW;
                    _sourceShape.height = _sH/2;
                    _sourceShape.realHeight = _sH;
                    
                    window.positionSequencing();
                    window.makeMiniMap();
                    
                    document.querySelector('#editCustomPositionSize').value = '';
                };
                //window.cloneCurrentPosition = function() {
                window[_ALLBUTTONMETHODNAME[15]] = function() {
                    // exit on no shapes available
                    if ( !window.shapes || window.shapes.length === 0 ) { showErrorMsg(window.ERRORMESSAGE['shapesEmptyArray']); return; }
                    var _shapes = window.shapes
                        ,_sourceShape = _shapes[_shapes.length-1]
                        ,_cloneShape = {};
                    // exit on current shape item not found
                    if ( !_sourceShape ) { showErrorMsg(window.ERRORMESSAGE['shapeNull']); return; }
                    makeSingleShape(_sourceShape.realWidth, _sourceShape.realHeight);
                    _cloneShape = _shapes[_shapes.length-1];
                    //console.log(_sourceShape);
                    //console.log(_cloneShape);
                    _cloneShape.slotsize = _sourceShape.slotsize;
                    _cloneShape.x = _sourceShape.x + 10;
                    _cloneShape.y = _sourceShape.y + 10;
                    window.positionSequencing();
                    window.makeMiniMap();
                };
                //window.addIqadtileId = function() {
                window[_ALLBUTTONMETHODNAME[4]] = function() {
                    // method setIqadtileId
                    // exit on no shapes available
                    // exit on empty _iqadtileIdInput
                    // exit on not allowed _iqadtileIdInput
                    // exit on current shape item is undefined
                    // replace iatId - if iatId already exists remove it
                    // exit on is in use - check in shapes if id is already in use
                    // set id if not in use
                    // disable the selected option item in datalist

                    // exit on no shapes available
                    if ( !window.shapes || window.shapes.length === 0 ) { 
                        showErrorMsg(window.ERRORMESSAGE['shapesEmptyArray']); 
                        // enable all option items
                        for(_item in _allIdOptionItem) {
                            if ( typeof(_allIdOptionItem[_item]) !== 'number' && typeof(_allIdOptionItem[_item]) !== 'function' && typeof(_allIdOptionItem[_item]) === 'object' ) {
                                if ( _allIdOptionItem[_item].hasAttribute('disabled') ) {
                                    _allIdOptionItem[_item].removeAttribute('disabled');
                                }
                            }
                        }
                        // console.log('removed all disabled attributes from options in theIqadtileId datalist');
                        return; 
                    }
                    
                    // input value, current shape item, datalist options
                    var _iqadtileIdInput = document.querySelector('[name="theIqadtileId"]').value.split(',')[0] // only use first item if comma separated input
                        ,_currentShape = window.shapes.length-1
                        ,_allIdOptionItem = document.querySelector('#all-theIqadtileId').options
                        ,_allowedId = window.IQDTILEID
                        ,_usedId = []
                        ,_shapes = window.shapes
                        ,_item;
                    // exit on empty _iqadtileIdInput
                    if ( _iqadtileIdInput === '' ) { showErrorMsg(window.ERRORMESSAGE['idEmpty']); return; }
                    // exit on not allowed _iqadtileIdInput
                    if ( _allowedId.indexOf(_iqadtileIdInput) === -1 ) { showErrorMsg(window.ERRORMESSAGE['idForbidden']); return; }
                    // exit on current shape item is undefined
                    if ( !_shapes[_currentShape] ) { showErrorMsg(window.ERRORMESSAGE['shapeNull']); return; }
                    // replace iatId - if iatId already exists remove it
                    if ( _allowedId.indexOf(_shapes[_currentShape].iatId) > -1 ) { window.removeIqadtileId(_currentShape); }
                    
                    for(_item in _shapes) {
                        // console.log('***** setIqadtileId check unused option values in shapes ', _shapes[_item].iatId, ' _ ', _iqadtileIdInput);
                        // exit on is in use - check in shapes if id is already in use
                        if ( _iqadtileIdInput === _shapes[_item].iatId) { showErrorMsg(window.ERRORMESSAGE['idDouble']);  return; }
                        // collect the ids in use
                        if (_shapes[_item].iatId !== 'iqadtile[N]' && _usedId.indexOf(_shapes[_item].iatId) === -1) {
                            _usedId.push(_shapes[_item].iatId);
                        }
                    }
                    
                    // console.log('***** setIqadtileId ', _iqadtileIdInput, ' _ ', _usedId);
                    
                    // enable unused ids matching against _usedId
                    for(_item in _allIdOptionItem) {
                        if ( typeof(_allIdOptionItem[_item]) !== 'number' && typeof(_allIdOptionItem[_item]) !== 'function' && typeof(_allIdOptionItem[_item]) === 'object' ) {
                            ///console.log('***** setIqadtileId options ', _allIdOptionItem[_item].value, ' _ ', _usedId.indexOf(_allIdOptionItem[_item].value));
                            if ( _usedId.indexOf(_allIdOptionItem[_item].value) === -1 && _allIdOptionItem[_item].hasAttribute('disabled') ) {
                                _allIdOptionItem[_item].removeAttribute('disabled');
                            }
                        }
                    }
                    
                    // set iatid of item in shapes
                    _shapes[_currentShape].setIqadtileId( _iqadtileIdInput );
                    
                    // disable the selected option item in datalist
                    for(_item in _allIdOptionItem) {
                        if ( typeof(_allIdOptionItem[_item]) !== 'number' && typeof(_allIdOptionItem[_item]) !== 'function' && typeof(_allIdOptionItem[_item]) === 'object' ) {
                            ///console.log('***** setIqadtileId options ', _allIdOptionItem[_item], ' _ ', _iqadtileIdInput);
                            if ( _allIdOptionItem[_item].value === _iqadtileIdInput ) {
                                _allIdOptionItem[_item].setAttribute('disabled', true);
                            }
                        }
                    }
        // begin section clean option list
                    // enable option items in datalist which are not used in shapes items
                    // check all iatId used in shapes
                    // if disabled option is not in use - for instance it was changed
                    // remove the disabled attribute from option element
                    _shapes = window.shapes;
                    for(_item in _shapes) {
                        // console.log('***** setIqadtileId check unused option values in shapes ', _shapes[_item].iatId, ' _ ', _iqadtileIdInput);
                        // collect the ids in use
                        if (_shapes[_item].iatId !== 'iqadtile[N]' && _usedId.indexOf(_shapes[_item].iatId) === -1) {
                            _usedId.push(_shapes[_item].iatId);
                        }
                    }
                    // console.log('***** setIqadtileId used ids eq option values in shapes ', _usedId);
                    // enable unused ids matching against _usedId
                    for(_item in _allIdOptionItem) {
                        if ( typeof(_allIdOptionItem[_item]) !== 'number' && typeof(_allIdOptionItem[_item]) !== 'function' && typeof(_allIdOptionItem[_item]) === 'object' ) {
                            ///console.log('***** setIqadtileId options ', _allIdOptionItem[_item].value, ' _ ', _usedId.indexOf(_allIdOptionItem[_item].value));
                            if ( _usedId.indexOf(_allIdOptionItem[_item].value) === -1 && _allIdOptionItem[_item].hasAttribute('disabled') ) {
                                _allIdOptionItem[_item].removeAttribute('disabled');
                            }
                        }
                    }
        // end section clean option list
                    // clear input value
                    document.querySelector('[name="theIqadtileId"]').value = '';
                    drawScreen();
                    _usedId = [];
                };
                //window.removeIqadtileId = function() {
                window[_ALLBUTTONMETHODNAME[5]] = function(dragIndex) {
                    console.log(dragIndex);
                    // removeIqadtileId
                    // exit on no shapes available
                    // exit on not allowed _iqadtileIdInput
                    // exit on current shape item is undefined
                    // unset id if is in use
                    // enable option in list

                    // exit on no shapes available
                    if ( !window.shapes || window.shapes.length === 0 ) { 
                        showErrorMsg(window.ERRORMESSAGE['shapesEmptyArray']); 
                        // enable all option items
                        for(_item in _allIdOptionItem) {
                            if ( typeof(_allIdOptionItem[_item]) !== 'number' && typeof(_allIdOptionItem[_item]) !== 'function' && typeof(_allIdOptionItem[_item]) === 'object' ) {
                                if ( _allIdOptionItem[_item].hasAttribute('disabled') ) {
                                    _allIdOptionItem[_item].removeAttribute('disabled');
                                }
                            }
                        }
                        // console.log('removed all disabled attributes from options in theIqadtileId datalist');
                        return; 
                    }
                    //alert('dragIndex = '+dragIndex+' _ '+typeof dragIndex);
                    // input value, current shape item, datalist options
                    var _shapes = window.shapes;
                    var _shapesLength = window.shapes.length -1;
                        // if a none active shape should be deleted - use dragIndex 
                        // if an active shape should be deleted - use shapes length minus one
                    var  _currentShape = (!!dragIndex && dragIndex !== _shapesLength-1) ? dragIndex : _shapesLength-1;
                    _currentShape = (_currentShape < 0) ? 0 : _currentShape;
                    var _iqadtileIdInput = _shapes[_currentShape].iatId;
                    var _allIdOptionItem = document.querySelector('#all-theIqadtileId').options;
                    var _allowedId = window.IQDTILEID;
                    var _usedId = [];
                    var _item;
                    var _deleteId = '';

                    //alert('dragIndex,_currentShape = '+dragIndex+','+_currentShape);
                    
                    // exit on not allowed _iqadtileIdInput
                    if ( 'iqadtile[N]' !== _iqadtileIdInput && _allowedId.indexOf(_iqadtileIdInput) === -1 ) { showErrorMsg(window.ERRORMESSAGE['idForbidden']);  return; }
                    // exit on current shape item is undefined
                    if ( !_shapes[_currentShape] ) { showErrorMsg(window.ERRORMESSAGE['shapeNull']); return; }
                    // grab the current iatid
                    _deleteId = _shapes[_currentShape].iatId;
                    // unset iatid of item in shapes
                    _shapes[_currentShape].setIqadtileId('iqadtile[N]');
        // begin section clean option list
                    // enable option items in datalist which are not used in shapes items
                    // check all iatId used in shapes
                    // if disabled option is not in use - for instance it was changed
                    // remove the disabled attribute from option element
                    _shapes = window.shapes;
                    for(_item in _shapes) {
                        // console.log('***** removeIqadtileId check unused option values in shapes ', _shapes[_item].iatId, ' _ ', _iqadtileIdInput);
                        // collect the ids in use
                        if (_shapes[_item].iatId !== 'iqadtile[N]' && _usedId.indexOf(_shapes[_item].iatId) === -1) {
                            _usedId.push(_shapes[_item].iatId);
                        }
                    }
                    // remove _deleteId _usedId
                    if (_usedId.indexOf(_deleteId) > -1 ) {
                        _usedId.splice(_usedId.indexOf(_deleteId),1);
                    }
                    // console.log('***** removeIqadtileId used ids eq option values in shapes ', _usedId);
                    // enable unused ids matching against _usedId
                    for(_item in _allIdOptionItem) {
                        if ( typeof(_allIdOptionItem[_item]) !== 'number' && typeof(_allIdOptionItem[_item]) !== 'function' && typeof(_allIdOptionItem[_item]) === 'object' ) {
                            ///console.log('***** removeIqadtileId options ', _allIdOptionItem[_item].value, ' _ ', _usedId.indexOf(_allIdOptionItem[_item].value));
                            if ( _usedId.indexOf(_allIdOptionItem[_item].value) === -1 && _allIdOptionItem[_item].hasAttribute('disabled') ) {
                                _allIdOptionItem[_item].removeAttribute('disabled');
                            }
                        }
                    }
        // end section clean option list
                    // clear input value
                    document.querySelector('[name="theIqadtileId"]').value = '';
                    drawScreen();
                    _usedId = [];
                };
                //window.addOneSlotsize = function() {}
                window[_ALLBUTTONMETHODNAME[6]] = function() {
                    // input value, current shape item
                    // exit on no shapes available
                    if ( !window.shapes || window.shapes.length === 0 ) { showErrorMsg(window.ERRORMESSAGE['shapesEmptyArray']);  return; }
                    var _shapes = window.shapes
                        ,_currentShape = _shapes.length-1
                        ,_currentIqadtileSlotsize = _shapes[_currentShape].getSlotsize()
                        ,_systemSlotsize = window.IQDSLOTSIZE
                        ,_inputSlotsize = document.querySelector('[name="oneSlotsize"]').value.split(',')
                        ,_size = ''
                        ,_sizeSystem = ''
                        ,_sizeInput = '';
                    // exit on empty size input
                    if(_inputSlotsize.join() === '') { showErrorMsg(window.ERRORMESSAGE['sizeEmpty']); return; }
                    // exit on current shape item not found
                    if ( !_shapes[_currentShape] ) { showErrorMsg(window.ERRORMESSAGE['shapeNull']); return; }
                    // console.log( '*****  ? ', typeof(_inputSlotsize), ' _ ', _inputSlotsize );
                    for ( _sizeInput in _inputSlotsize) {
                        // console.log( '*****  ? ',  _inputSlotsize[_sizeInput] );
                        var _isAllowed = _systemSlotsize.indexOf(_inputSlotsize[_sizeInput])
                            ,_isInUse = _currentIqadtileSlotsize.indexOf(_inputSlotsize[_sizeInput]);
                        // is input-size allowed
                        // console.log( '***** is input-size allowed ? ',  _isAllowed, ' _ ', !!_isAllowed );
                        // is input-size already in use
                        // console.log( '***** is input-size already in use ? ', _isInUse, ' _ ', !!_isInUse );
                        
                        // rules to add
                        // if _isAllowed eq false exit error "slotsize not allowed"
                        if ( _isAllowed === -1 ) { showErrorMsg(window.ERRORMESSAGE['sizeForbidden'] + ' ' + _inputSlotsize[_sizeInput]); return; }
                        // if _isInUse eq true exit error "slotsize already set"
                        if ( _isInUse > -1 ) { showErrorMsg(window.ERRORMESSAGE['sizeDouble'] + ' ' + _inputSlotsize[_sizeInput]); return; }
                        // if _isAllowed eq true and _isInUse eq false then add by calling:
                            // IqdAdPostion.prototype.addSlotsize(size_str)
                        if (_isAllowed > -1 && _isInUse === -1) {
                            // set the slotsize
                            _shapes[_currentShape].addSlotsize(_inputSlotsize[_sizeInput]);
                            // clear input value
                            document.querySelector('[name="oneSlotsize"]').value = '';
                            //drawScreen();       
                        }
                    }
                    drawScreen();
                };
                //window.removeOneSlotsize = function() {}
                window[_ALLBUTTONMETHODNAME[7]] = function() {
                    // input value, current shape item
                    // exit on no shapes available
                    if ( !window.shapes || window.shapes.length === 0 ) { showErrorMsg(window.ERRORMESSAGE['shapesEmptyArray']);  return; }
                    var _shapes = window.shapes
                        ,_currentShape = _shapes.length-1
                        ,_currentIqadtileSlotsize = _shapes[_currentShape].getSlotsize()
                        ,_systemSlotsize = window.IQDSLOTSIZE
                        ,_inputSlotsize = document.querySelector('[name="oneSlotsize"]').value.split(',')
                        ,_size = ''
                        ,_sizeSystem = ''
                        ,_sizeInput = '';
                    // exit on empty size input
                    if(_inputSlotsize.join() === '') { showErrorMsg(window.ERRORMESSAGE['sizeEmpty']); return; }
                    // exit on current shape item not found
                    if ( !_shapes[_currentShape] ) { showErrorMsg(window.ERRORMESSAGE['shapeNull']); return; }
                    // console.log( '*****  ? ', typeof(_inputSlotsize), ' _ ', _inputSlotsize );
                    for ( _sizeInput in _inputSlotsize) {
                        // console.log( '*****  ? ',  _inputSlotsize[_sizeInput] );
                        var _isAllowed = _systemSlotsize.indexOf(_inputSlotsize[_sizeInput])
                            ,_isInUse = _currentIqadtileSlotsize.indexOf(_inputSlotsize[_sizeInput]);
                        // is input-size allowed
                        // console.log( '***** is input-size allowed ? ',  _isAllowed, ' _ ', !!_isAllowed );
                        // is input-size already in use
                        // console.log( '***** is input-size already in use ? ', _isInUse, ' _ ', !!_isInUse );
                        
                        // rules to remove
                        // if _isAllowed eq false exit error "slotsize not allowed"
                        if ( _isAllowed === -1 ) { showErrorMsg(window.ERRORMESSAGE['sizeForbidden'] + ' ' + _inputSlotsize[_sizeInput]);  return; }
                        // if _isInUse eq true exit error "slotsize already set"
                        if ( _isInUse === -1 ) { showErrorMsg(window.ERRORMESSAGE['sizeNull'] + ' ' + _inputSlotsize[_sizeInput]);  return; }
                        // if _isAllowed eq true and _isInUse eq true then remove by calling:
                            // IqdAdPostion.prototype.removeSlotsize(size_str)
                        if (_isAllowed > -1 && _isInUse > -1) {
                            // remove the slotsize
                            _shapes[_currentShape].removeSlotsize(_inputSlotsize[_sizeInput]);
                            // clear input value
                            document.querySelector('[name="oneSlotsize"]').value = '';
                            //drawScreen();       
                        }
                    }
                    drawScreen();
                };
                //window.addMultipleSlotsize = function() {}
                window[_ALLBUTTONMETHODNAME[8]] = function() {
                    // input value, current shape item
                    // exit on no shapes available
                    if ( !window.shapes || window.shapes.length === 0 ) { showErrorMsg(window.ERRORMESSAGE['shapesEmptyArray']);  return; }
                    var _shapes = window.shapes
                        ,_currentShape = _shapes.length-1
                        ,_currentIqadtileSlotsize = _shapes[_currentShape].getSlotsize()
                        ,_systemSlotsize = window.IQDSLOTSIZE
                        ,_inputSlotsize = document.querySelector('[name="slotsizes"]').value.split(' ').join('').split(',')
                        ,_size = ''
                        ,_sizeSystem = ''
                        ,_sizeInput = '';
                    // exit on empty size input
                    if(_inputSlotsize.join() === '') { showErrorMsg(window.ERRORMESSAGE['sizeEmpty']); return; }
                    // exit on current shape item not found
                    if ( !_shapes[_currentShape] ) { showErrorMsg(window.ERRORMESSAGE['shapeNull']); return; }
                    // console.log( '*****  ? ', typeof(_inputSlotsize), ' _ ', _inputSlotsize );
                    for ( _sizeInput in _inputSlotsize) {
                        // update after iteration - to prevent double entries
                        _currentIqadtileSlotsize = _shapes[_currentShape].getSlotsize()
                        
                        // console.log( '*****  ? ',  _inputSlotsize[_sizeInput] );
                        var _isAllowed = _systemSlotsize.indexOf(_inputSlotsize[_sizeInput])
                            ,_isInUse = _currentIqadtileSlotsize.indexOf(_inputSlotsize[_sizeInput])
                            ,_isEmpty = _inputSlotsize[_sizeInput] === '';
                        
                        // is input-size allowed
                        // console.log( '***** is input-size allowed ? ',  _isAllowed, ' _ ', !!_isAllowed );
                        // is input-size already in use
                        // console.log( '***** is input-size already in use ? ', _isInUse, ' _ ', !!_isInUse );
                        
                        // rules to add
                        // if _isAllowed eq false exit error "slotsize not allowed"
                        if ( _isAllowed === -1 ) { 
                            var _err = (_isEmpty) ? 'sizeEmpty' : 'sizeForbidden'
                            showErrorMultipleMsg(window.ERRORMESSAGE[_err] + ' ' + _inputSlotsize[_sizeInput]); 
                        }
                        // if _isInUse eq true exit error "slotsize already set"
                        if ( _isInUse > -1 ) { showErrorMultipleMsg(window.ERRORMESSAGE['sizeDouble'] + ' ' + _inputSlotsize[_sizeInput]); }
                        // if _isAllowed eq true and _isInUse eq false then add by calling:
                            // IqdAdPostion.prototype.addSlotsize(size_str)
                        if (_isAllowed > -1 && _isInUse === -1) {
                            // set the slotsize
                            _shapes[_currentShape].addSlotsize(_inputSlotsize[_sizeInput]);
                            // clear input value
                            document.querySelector('[name="slotsizes"]').value = '';
                            //drawScreen();       
                        }
                    }
                    drawScreen();
                };
                //window.removeMultipleSlotsize = function() {}
                window[_ALLBUTTONMETHODNAME[9]] = function() {
                    // input value, current shape item
                    // exit on no shapes available
                    if ( !window.shapes || window.shapes.length === 0 ) { showErrorMsg(window.ERRORMESSAGE['shapesEmptyArray']); return; }
                    var _shapes = window.shapes
                        ,_currentShape = _shapes.length-1
                        ,_currentIqadtileSlotsize = _shapes[_currentShape].getSlotsize()
                        ,_systemSlotsize = window.IQDSLOTSIZE
                        ,_inputSlotsize = document.querySelector('[name="slotsizes"]').value.split(' ').join('').split(',')
                        ,_size = ''
                        ,_sizeSystem = ''
                        ,_sizeInput = '';
                    // exit on empty size input
                    if(_inputSlotsize.join() === '') { showErrorMsg(window.ERRORMESSAGE['sizeEmpty']); return; }
                    // exit on current shape item not found
                    if ( !_shapes[_currentShape] ) { showErrorMsg(window.ERRORMESSAGE['shapeNull']); return; }
                    // console.log( '*****  ? ', typeof(_inputSlotsize), ' _ ', _inputSlotsize );
                    for ( _sizeInput in _inputSlotsize) {
                        // console.log( '*****  ? ',  _inputSlotsize[_sizeInput] );
                        var _isAllowed = _systemSlotsize.indexOf(_inputSlotsize[_sizeInput])
                            ,_isInUse = _currentIqadtileSlotsize.indexOf(_inputSlotsize[_sizeInput])
                            ,_isEmpty = _inputSlotsize[_sizeInput] === '';
                        // is input-size allowed
                        // console.log( '***** is input-size allowed ? ',  _isAllowed, ' _ ', !!_isAllowed );
                        // is input-size already in use
                        // console.log( '***** is input-size already in use ? ', _isInUse, ' _ ', !!_isInUse );
                        
                        // rules to remove
                        // if _isAllowed eq false exit error "slotsize not allowed"
                        if ( _isAllowed === -1 ) { 
                            var _err = (_isEmpty) ? 'sizeEmpty' : 'sizeForbidden'
                            showErrorMultipleMsg(window.ERRORMESSAGE[_err] + ' ' + _inputSlotsize[_sizeInput]); 
                        }// if _isInUse eq false exit error "slotsize is not set"
                        if ( _isInUse === -1 ) { showErrorMultipleMsg(window.ERRORMESSAGE['sizeNull'] + ' ' + _inputSlotsize[_sizeInput]);  }
                        // if _isAllowed eq true and _isInUse eq true then remove by calling:
                            // IqdAdPostion.prototype.removeSlotsize(size_str)
                        if (_isAllowed > -1 && _isInUse > -1) {
                            // remove the slotsize
                            _shapes[_currentShape].removeSlotsize(_inputSlotsize[_sizeInput]);
                            // clear input value
                            document.querySelector('[name="slotsizes"]').value = '';
                            //drawScreen();       
                        }
                    }
                    drawScreen();
                }
                //window.removeAllSlotsize = function() {}
                window[_ALLBUTTONMETHODNAME[10]] = function() {
                // input value, current shape item
                    var _shapes = window.shapes
                        ,_currentShape = _shapes.length-1;
                    _shapes[_currentShape].slotsize = '99x1';
                    drawScreen();
                }
                //window.addComment = function() {}
                window[_ALLBUTTONMETHODNAME[11]] = function(userId) {
                    // exit on no shapes available
                    // if ( !window.shapes || window.shapes.length === 0 ) { showErrorMsg(window.ERRORMESSAGE['shapesEmptyArray']); return; }
                    // input value, current shape item
                    var _shapes = window.shapes
                        ,_currentShape = _shapes.length-1
                        ,_commentInput = document.querySelector('[name="comment"]').value
                        ,_tmpComment = {};
                    // exit on empty _commentInput
                    // if ( _commentInput === '' ) { showErrorMsg(window.ERRORMESSAGE['commentEmpty']); return; }
                    // set comment
                    _tmpComment = new IqdAdPositionComment(_commentInput, userId);
                    _shapes[_currentShape].comment.push(_tmpComment);
                    // sort comment items by date descending - lifo
                    _shapes[_currentShape].sortCommentByDate();
                    // console.log('***** _shapes[_currentShape].comment ', _shapes[_currentShape].comment);
                    document.querySelector('[name="comment"]').value = '';
                    drawScreen();
                    // if ( document.getElementById('showIqadtileComment').classList.contains('hidden') ) {
                    //     window.toggleComment();
                    // }
                }
                //window.toggleComment = function() {}
                window[_ALLBUTTONMETHODNAME[12]] = function() {
                    // exit on no shapes available
                    if ( !window.shapes || window.shapes.length === 0 ) { showErrorMsg(window.ERRORMESSAGE['shapesEmptyArray']); return; }
                    // exit shape item got empty comment
                    if (window.shapes[window.shapes.length-1].comment.length < 1)  { showErrorMsg(window.ERRORMESSAGE['shapeCommentEmpty']); return; }
                    window.toggleInformationWrapper('comment');
                }
                //window.toggleConfiguration = function() {}
                window[_ALLBUTTONMETHODNAME[13]] = function() {
                    var _shapes = window.shapes
                        ,_item = ''
                        ,_tmpText = '';
                    // exit on no shapes available
                    if ( !_shapes || _shapes.length === 0 ) { showErrorMsg(window.ERRORMESSAGE['shapesEmptyArray']); return; }
                    // create output of each item
                    for(_item in _shapes) {
                        _tmpText += makeConfigurationText(_shapes[_item]);
                    }
                    document.getElementById('showSiteConfig').innerHTML = '<textarea>' + _tmpText + '</textarea>';
                    window.toggleInformationWrapper('config');
                    // console.log('***** _shapes ', _tmpText)
                }
                //window.copyConfiguration = function() {}
                window[_ALLBUTTONMETHODNAME[14]] = function() {
                    var _shapes = window.shapes
                        ,_item = ''
                        ,_tmpText = ''
                        ,_tmpTextarea = document.createElement("textarea");
                    // exit on no shapes available
                    if ( !_shapes || _shapes.length === 0 ) { showErrorMsg(window.ERRORMESSAGE['shapesEmptyArray']); return; }
                    // create output of each item
                    for(_item in _shapes) {
                        _tmpText += makeConfigurationText(_shapes[_item]);
                    }
                    _tmpTextarea.value = _tmpText;
                    _tmpTextarea.id = 'copyText';
                    _tmpTextarea.style='width:1px;height:1px;background-color:transparent;position:absolute;top:-1000px;';
                    document.getElementById('showIqadtileInformation').appendChild(_tmpTextarea);
                    _tmpTextarea.focus();
                    _tmpTextarea.select();
                    document.execCommand('copy');
                    document.getElementById('showIqadtileInformation').removeChild(_tmpTextarea);
                }
                // window.importTmsConfiguration = function() {};
                window[_ALLBUTTONMETHODNAME[17]] = function() {
                    // disabled - not using findIndex anymore
                    // findIndex - exit on missing feature
                    //if(!Array.prototype.findIndex) { showErrorMsg(window.ERRORMESSAGE['missingFeature']); return; }
                    
                    var _tmsJsonInput = document.querySelector('[name="tmsConfiguration"]').value+'';
                    // exit on empty input
                    if ( _tmsJsonInput === '' ) { showErrorMsg(window.ERRORMESSAGE['tmsEmpty']); return; }
                    // exit on malformed JSON data
                    if ( !isValidJson(_tmsJsonInput) ) { showErrorMsg(window.ERRORMESSAGE['tmsNoJson']); return; }
                    // create shapes from JSON
                    window.createPosition();
                }
                //window.copyTmsConfiguration = function() {}
                window[_ALLBUTTONMETHODNAME[18]] = function() {
                    var _shapes = window.shapes
                        ,_shapesLength = _shapes.length-1
                        ,_item = ''
                        ,_tmpText = ''
                        ,_tmpTextarea = document.createElement("textarea");
                    // exit on no shapes available
                    if ( !_shapes || _shapes.length === 0 ) { showErrorMsg(window.ERRORMESSAGE['shapesEmptyArray']); return; }
                    // create output of each item
                    for(_item in _shapes) {
                        _tmpText += makeTmsConfigurationText(_shapes[_item]);
                        if ( _item*1 !== _shapesLength ) { _tmpText += ','; }
                    }
                    _tmpText = '{\r\n' + _tmpText + '\r\n}';
                    _tmpTextarea.value = _tmpText;
                    _tmpTextarea.id = 'copyText';
                    _tmpTextarea.style='width:1px;height:1px;background-color:transparent;position:absolute;top:-1000px;';
                    document.getElementById('showIqadtileInformation').appendChild(_tmpTextarea);
                    _tmpTextarea.focus();
                    _tmpTextarea.select();
                    document.execCommand('copy');
                    document.getElementById('showIqadtileInformation').removeChild(_tmpTextarea);
                }        
                //window.saveConfiguration = function() {}
                // window[_ALLBUTTONMETHODNAME[19]] = function() {
                //     showErrorMsg(' ->> not yet implemented <<- '); return;
                // }
                //window.toggleAppliedRules = function() {}
                window[_ALLBUTTONMETHODNAME[20]] = function() {
                    showErrorMsg(' ->> not yet implemented <<- '); return;
                }
                // bind methods to buttons
                // by loop
                for(; i < _allButtonMethodNameLength; i++) {
                    if(i !== 19 && i !== 11 && i !== 17 && i !== 12){
                        let cache = document.querySelector('[data-button-type="'+_ALLBUTTONMETHODNAME[i]+'"]');
                        if(cache) cache.onclick = window[_ALLBUTTONMETHODNAME[i]];
                    }
                }
            };
            // do globalize and call the method
            window.makePwMenu = makePwMenu;
            window.makePwMenu();
        }; // end canvasApp
        /**
         * the class
         */
        //var testId = 0;
        function IqdAdPostion(posX, posY, posW, posH) {
            this.positionLabel = '';
            this.iatId = 'iqadtile[N]';
            this.slotsize = '99x1';
            this.comment = [];
            this.uid = 'id'+Date.now();
            this.x = posX;
            this.y = posY;
            this.velX = 0;
            this.velY = 0;
            this.accelX = 0;
            this.accelY = 0;
            this.color = "#FF0000";
            this.radius = 30;
            this.width = posW/2;
            this.height = posH/2;
            this.realWidth = posW;
            this.realHeight = posH;
            this.deleteMe = false;
            this.sepSign = ',';
            this.sep2Sign = this.sepSign+this.sepSign;
            //this.testId = testId;
            //this.dragLock= false;
            //testId++;
        };
        /**
         * getter and setter functions section
         */
        //set color
        IqdAdPostion.prototype.setColor = function(color_str) {
            //#console.log('***** particle - IqdAdPostion.prototype.setColor ', color_str);
            this.color = color_str;
        };
        //set the position label
        IqdAdPostion.prototype.setPositionLabel = function(positionLabel_str) {
            //#console.log('***** particle - IqdAdPostion.prototype.setPositionLabel ', positionLabel_str);
            this.positionLabel = positionLabel_str.toLowerCase();
        };
        // get the position label
        IqdAdPostion.prototype.getPositionLabel = function() {
            //#console.log('***** particle - IqdAdPostion.prototype.getPositionLabel ', this.positionLabel);
            return this.positionLabel;
        };
        // set the iqadtile id
        IqdAdPostion.prototype.setIqadtileId = function(iatId_str) {
            //#console.log('***** particle - IqdAdPostion.prototype.setIqadtileId ', iatId_str);
            this.iatId = iatId_str;
        };
        // get the iqadtile id
        IqdAdPostion.prototype.getIqadtileId = function() {
            //#console.log('***** particle - IqdAdPostion.prototype.getIqadtileId ', this.iatId);
            return this.iatId;
        };
        // sort the slotsizes by value - smallest size is first in list
        IqdAdPostion.prototype.sortSlotsize = function() {
            // console.log(this.slotsize);
            var _tmpSize = this.slotsize.split(',');
            // sort descending - lifo - and reassign comment items
            _tmpSize = _tmpSize.sort(function(a, b) {
                var _segmentA = a.split('x')
                    ,_segmentB = b.split('x')
                    ,_tmpResult = 0;
                _segmentA[0] *= 1;
                _segmentA[1] *= 1;
                _segmentB[0] *= 1;
                _segmentB[1] *= 1;
                if ( _segmentA[0] !== _segmentB[0] ) {
                    _tmpResult = parseFloat(_segmentA[0]) - parseFloat(_segmentB[0]);
                } else {
                    _tmpResult = parseFloat(_segmentA[1]) - parseFloat(_segmentB[1]);
                }
                return _tmpResult;
            });
            this.slotsize = _tmpSize.join();
        };
        // get the slotsize
        IqdAdPostion.prototype.getSlotsize = function() {
            //#console.log('***** particle - IqdAdPostion.prototype.getSlotsize ', this.slotsize);
            return this.slotsize;
        };
        // add a slotsize
        IqdAdPostion.prototype.addSlotsize = function(size_str) {
            //#console.log('***** particle - IqdAdPostion.prototype.addSlotsize ', size_str );
            this.slotsize += this.sepSign + size_str;
            this.slotsize = this.slotsize.split(this.sep2Sign).join(this.sepSign);
            // sort slotsize
            this.sortSlotsize();
        };
        // remove a slotsize
        IqdAdPostion.prototype.removeSlotsize = function(size_str) {
            //#console.log('***** particle - IqdAdPostion.prototype.removeSlotsize 1 ', size_str, ' _ this.slotsize ', this.slotsize);
            // exit on mandatory slotsize
            if (size_str === '99x1') {window.showErrorMsg(window.ERRORMESSAGE['sizeMandatory'], ' ', size_str); return; }
            var _tmpSlotsize = this.slotsize.split(',')
                ,_isInArray = _tmpSlotsize.indexOf(size_str);
            //console.log('***** particle - _tmpSlotsize.indexOf(size_str) ', _tmpSlotsize.indexOf(size_str), ' _ size_str ', size_str);
            if ( _isInArray > -1 ) {
                // remove size from array
                _tmpSlotsize.splice(_isInArray, 1);
            }
            // stringify the rest of the array
            this.slotsize = _tmpSlotsize.join();
            //console.log('***** particle - IqdAdPostion.prototype.removeSlotsize 2 ', size_str, ' _ this.slotsize ', this.slotsize);
            // remove last sepsign
            if( (this.slotsize.length-1) === this.slotsize.lastIndexOf(this.sepSign) ) {
                this.slotsize = this.slotsize.slice( 0,this.slotsize.lastIndexOf(this.sepSign) );
            }
            // remove foremost sepsign 
            if( 0 === this.slotsize.indexOf(this.sepSign) ) {
                this.slotsize = this.slotsize.slice(this.slotsize.indexOf(this.sepSign)+1,this.slotsize.length);
            }
            // mandatory slotsize
            if( this.slotsize === '') {
                this.slotsize = '99x1';
            }
            //console.log('***** particle - IqdAdPostion.prototype.removeSlotsize 3 ', size_str, ' _ this.slotsize.length ', this.slotsize.length, ' _ this.slotsize.lastIndexOf(",") ', this.slotsize.lastIndexOf(this.sepSign));
        };
        // sort the comments by date - latest date eq first in list
        IqdAdPostion.prototype.sortCommentByDate = function() {
            // console.log(this.comment);
            var _tmpComment = this.comment;
            // sort descending by date - lifo - and reassign comment items
            this.comment = _tmpComment.sort(function(a, b) {
                return parseFloat(b.item.creationdate) - parseFloat(a.item.creationdate);
            });
        };
        //get the comment
        IqdAdPostion.prototype.getComment = function(com) {
            //#console.log('***** particle - IqdAdPostion.prototype.getComment ', typeof(this.comment) , ' _ ', this.comment);
            var _item = ''
                ,_tmpDate = ''
                ,_dateFormat = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' }
                ,_comment = this.comment
                ,_tmpText = ''
                ,_separator = (com !== 'config' || !com) ? '</br>\r\n' : '\r\n\t';

            for(_item in _comment) {
                //console.log(_comment[_item]);
                if(_comment[_item].item.creationdate) {
                    _tmpDate = new Date(_comment[_item].item.creationdate);
                    _tmpText += _tmpDate.toLocaleDateString('de-DE', _dateFormat);
                }
                _tmpText += ' - ';
                if(_comment[_item].item.owner) {
                    _tmpText += _comment[_item].item.owner;
                }
                _tmpText += ':' + _separator;
                if(_comment[_item].item.text) {
                    _tmpText += atob(_comment[_item].item.text) + _separator;
                }
                _tmpText += _separator;
            }

            //console.log(_tmpText);

            return _tmpText;
        };
        /**
         * class item hitTest method
         */
        IqdAdPostion.prototype.hitTest = function(hitX,hitY) {
        /*
            console.log('****************************************');
            console.log('***** 0 this', this);
            console.log('***** 1 hitX _ hitY', hitX, ' _ ', hitY);
            console.log('***** 2 this.x _ this.y', this.x, ' _ ', this.y);
            console.log('***** 3 this.deleteMe', this.deleteMe);
            console.log('***** 4 window.shapes:');
            console.table(window.shapes);
            console.log('***** 5 (hitX > ((this.x + this.width) - 25))', (hitX > ((this.x + this.width) - 25)));
            console.log('***** 6 (hitY < ((this.y - this.height) + 25))', (hitY < ((this.y - this.height) + 25)));
            console.log('****************************************');
        */
            var isHit = false;
            isHit = (
                (hitX > this.x - this.width)&&
                (hitX < this.x + this.width)&&
                (hitY > this.y - this.height)&&
                (hitY < this.y + this.height)
            );

            // item close button hit value
            this.deleteMe = ( isHit && ( (hitX > ((this.x + this.width) - 25)) && (hitY < ((this.y - this.height) + 25)) ) ) ? true : false;
            // if(this.deleteMe === true) { console.log('DELETE ME', this.positionLabel, ' _ ', this.iatId); }
            // if(this.deleteMe === false) { console.log('DO NOT DELETE ME', this.positionLabel, ' _ ', this.iatId); }
            return isHit;
        };

        IqdAdPostion.prototype.generateFromObj = function(obj){
            for(let prop in obj){
                this[prop] = obj[prop];
            }
        }

        /**
         * class item draw the item to CanvasContext method
         */
        IqdAdPostion.prototype.drawToContext = function(ctx,i,numShapes) {
            //#
            /*
            console.log('***** particle drawToContext - shapes i = ', i, ' _ numShapes', numShapes);
            console.table(shapes);
            console.log('***** particle drawToContext - shapes === window.shapes', shapes === window.shapes);
            */
            ctx.globalCompositeOperation = "source-over"; // draw above all
            // shape
            // set color and alpha using the rgba() method
            //
            // console.log('colors depending on size owned ', this.slotsize, ' _ ', this.iatId);
            // _tmpColor[blue,purple]
            var _tmpR = [9,255]
                ,_tmpG = [160,87]
                ,_tmpB = [230,151]
                ,_tmpA = 0.8
                ,_tmpColor = [
                    "rgba(" + _tmpR[0] + "," + _tmpG[0] + "," + _tmpB[0] + "," + _tmpA + ")"
                    ,"rgba(" + _tmpR[1] + "," + _tmpG[1] + "," + _tmpB[1] + "," + _tmpA + ")"
                ]
                ,_programmaticSize = window.IQDPROGRAMMATICSLOTSIZE
                ,_tmpSize = this.slotsize
                ,_tmpColorSelector = 0
                ,_tmpText =[]
                ,_tmpTextLength = _tmpText.length
                ,_tmpDelta = 0
                ,_item = '';
            // console.log('colors depending on size owned ', _tmpSize);
            // console.log('colors depending on size owned ', _programmaticSize);
            // rectangle - switch active/inactive
            // active color = purple in case programmatic slotsizes are owned by current shape item
            for ( _item in _tmpSize ) {
                //console.log('colors depending on size owned ', _programmaticSize.indexOf(_tmpSize[_item]) );
                _tmpColorSelector = (_programmaticSize.indexOf(_tmpSize[_item]) > -1) ? 1 : 0;
            }
            //console.log('colors depending on size owned ', _tmpColorSelector, ' _ ', this.iatId);
            ctx.fillStyle = ((i+1)===numShapes) ? _tmpColor[_tmpColorSelector] : this.color;
            ctx.fillRect(this.x - this.width, this.y - this.height, 2*this.width, 2*this.height);
            // closebutton
            ctx.fillStyle = '#FF0000';
            ctx.fillRect(this.x+this.width-25, this.y-this.height, 25, 25);
            // border - switch active/inactive
            ctx.strokeStyle = ((i+1)===numShapes) ? "#ebfc79" : "#FFFFFF";
            ctx.lineWidth = ((i+1)===numShapes) ? 4 : 2;
            //#console.log('***** particle - shapes ctx.lineWidth = ', ctx.lineWidth);
            ctx.strokeRect(this.x+ctx.lineWidth/2 - this.width, this.y+ctx.lineWidth/2 - this.height, 2*this.width-ctx.lineWidth, 2*this.height-ctx.lineWidth);
            // position label information
            ctx.fillStyle = '#FFFFFF';
            ctx.beginPath();
            ctx.arc((this.x-this.width)+40, (this.y-this.height)+40, this.radius, 0, 2*Math.PI, false);
            ctx.closePath();
            ctx.fill();
            ctx.fillStyle = '#000000';
            ctx.font = 'normal bold 18px Verdana';
            ctx.textAlign = 'center';
            //console.log('***** particle - this.positionLabel ', this.positionLabel);
            ctx.fillText(this.positionLabel, (this.x-this.width)+40, (this.y-this.height)+44);
            // iqadtile id information
            ctx.fillStyle = '#FFFFFF';
            // set font size depending on tile size
            ctx.font = (this.realWidth <= 400 && this.realHeight <= 250) ? 'normal bold 18px Verdana' : 'normal bold 36px Verdana' ;
            ctx.textAlign = 'center';
            ctx.fillText(this.iatId, this.x, this.y);
            // slotsize information
            // now implemented in app js - drawShapes function - due to not-enough-space-on-item
            // it is now an extra section showing the current active iqadtile-id and its slotsizes
        };

        /**
         * the class
         */
        function IqdAdPositionComment(posComment, userId) {
            // console.log('// userId _ posComment ', userId, ' _ ', posComment);
        // create timestamp
        // sanitize comment text
        // set item object
            var _ts = Math.floor(new Date())
                ,_userId = userId
                ,_tmpText = sanitizeText(posComment);
            // sanitize comment text
            
            // set item object
            this.item = {
                    'creationdate': _ts,
                    'owner': _userId,
                    'text': _tmpText
            }
        };
        function sanitizeText(str) {
            //#
            // console.log('sanitize this: ', str);
            var _str = str + ''
                ,_str = btoa(_str);
            // btoa is fake sanitizing
            // regex - 
            // script-tag - /<script[^>]*>([\\S\\s]*?)<\/script>/img
            // eval call - 
            // bg-img-url : document.body.style = "background-image(url:"+alert('hi again');+");";
            return _str;
        };
    }
    destroy(){
        window.removeEventListener("scroll", window.drawMiniMapActiveArea, false);
    }
}

export default PlanningWizz;