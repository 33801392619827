import React from "react";
import { Redirect } from "react-router-dom";
import helper from "./helper.js";
import Headline from "./Headline.js"
import Loader from "./Loader";

import "./AdminSchedule.css"
import "./style.css";

class AdminSchedule extends React.Component{
    constructor(props){
        super(props);
        this.state={
            isLoading: true,
            hasOldSchedule: false,
            tmsValues: [],
            mandantenList: [],
            checkedAll: false,
            isSearchActive: false,
            doesTmsConnectionExists: false,
            newConnectionOpen: false,
            searchInput: "",
            bulkInput: {
                mandant: "", endgerät: "", status: ""
            }
        }
        this.fetchData = this.fetchData.bind(this);
        this.urlTms= this.urlTms.bind(this);
        this.urlTmsAvailable = this.urlTmsAvailable.bind(this);
        this.urlScheduleImport = this.urlScheduleImport.bind(this);
        this.searchOnChangeHandler = this.searchOnChangeHandler.bind(this);
        this.checkOnChangeHandler = this.checkOnChangeHandler.bind(this);
        this.checkAllOnChangeHandler = this.checkAllOnChangeHandler.bind(this);
        this.inputChangeWrapper = this.inputChangeWrapper.bind(this);
        this.bulkInputChangeWrapper = this.bulkInputChangeWrapper.bind(this);
        this.submitClickHandler = this.submitClickHandler.bind(this);
        this.renewImport = this.renewImport.bind(this);
    }
    componentDidMount(){
        Promise.all([
        this.props._get(window.api + "/tms/schedule"),
        this.fetchData(this.urlTmsAvailable, null, ""),])
        .then((data) => {
            let stateCache = {isLoading: false};
            if(data[0]){
                stateCache.hasOldSchedule = true;
                stateCache.oldSchedule = JSON.parse(data[0]);
                try{
                    if(Object.keys(stateCache.oldSchedule).length < 1){
                        stateCache.oldSchedule = null;
                    }
                }
                catch(err){
                    stateCache.oldSchedule = null;
                }
            }
            if(data[1]){
                let mandantenCache = data[1].map((el) => {el.checked = false;el.inputs = {mandant: "",endgerät: "",status: ""};return el}).sort();
                stateCache.mandantenList = mandantenCache;
            }
            this.setState(stateCache);
        }).catch((err) => console.log(err));
    }
    async toggleNewConnectionOpen(){
        if(this.state.tmsValues.length > 0){
            this.setState({
                newConnectionOpen: !this.state.newConnectionOpen,
            });
        }
        else{
            await new Promise((resolve, reject) =>{
                this.setState({
                    newConnectionOpen: !this.state.newConnectionOpen,
                    isLoading: true,
                }, resolve());
            });
            this.fetchData(this.urlTms, "name")
            .then((data) => {
                console.log(data);
                this.setState({
                    isLoading: false,
                    tmsValues: data,
                });
            }).catch((err) =>{
                console.error(err);
                this.setState({
                    isLoading: false,
                });
            });
        }
    }
    fetchData(urlFunction, sortingKey=null, arg=null){
        return new Promise((resolve, reject) =>{
            const url = urlFunction(arg);
            this.props._get(url)
                .then((data) =>{
                    let c;
                    if(sortingKey && sortingKey !== "name"){
                        c = JSON.parse(data).map((el) =>{
                            console.log(el);
                            let ca = el;
                            // Ich weiß nicht warum, aber man kann die tms values nicht mit der helper Funktion capizalizen
                                ca[sortingKey] = helper.capitalize(ca[sortingKey]);
                            return ca;
                        });
                    }
                    else{
                       c = JSON.parse(data) ;
                    }
                    if(sortingKey){
                        c.sort((a, b) => {
                            let aName = a[sortingKey].toUpperCase();
                            let bName = b[sortingKey].toUpperCase();
                            if(aName < bName){
                                return -1;
                            }
                            else if(aName > bName){
                                return 1;
                            }
                            else{
                                return 0;
                            }
                        });
                    }
                    resolve(c);
                }).catch((err) =>{
                    reject(err);
                })
            })
    }
    urlMandant(arg){
        return window.api + "/tms/planung?nodes=1";
    }
    urlEndgerät(arg){
        return window.api + "/tms/planung?nodes=" + arg + "&device=1";
    }
    urlTms(arg){
        return window.api + "/tms/export" + arg;
    }
    urlTmsAvailable(arg){
        return window.api + "/tms/exportAvailable" + arg;
    }
    urlScheduleImport(arg){
        return window.api + "/tms/scheduleImport";
    }
    fetchTmsData(){
        return new Promise((resolve, reject) =>{
            const url = window.api + "/tms/export";
            this.props._get(url)
                .then((data) =>{
                    let c = JSON.parse(data).map((el) =>{return {nodeId: el.nodeId, name: el.name}});
                    c.sort((a, b) => {
                        let aName = a.name.toUpperCase();
                        let bName = b.name.toUpperCase();
                        if(aName < bName){
                            return -1;
                        }
                        else if(aName > bName){
                            return 1;
                        }
                        else{
                            return 0;
                        }
                    });
                    resolve(c);
                }).catch((err) =>{
                    reject(err);
                })
            })
    }
    searchOnChangeHandler(evt){
        this.setState({
            searchInput: evt.target.value,
            isSearchActive: evt.target.value.length > 0,
            checkedAll: false,
        })
    }
    checkOnChangeHandler(i){
        let x = [].concat(this.state.mandantenList);
        x[i].checked = !x[i].checked;
        this.setState({
            mandantenList: x,
        });
    }
    checkAllOnChangeHandler(){
        let stateCache = {};
        stateCache.mandantenListCache = [].concat(this.state.mandantenList);
        if(this.state.checkedAll){
            stateCache.checkedAll = false;
            stateCache.mandantenListCache = stateCache.mandantenListCache.map((el,i) =>{
                el.checked = false;
                return el;
            });
        }
        else{
            stateCache.checkedAll = true;
            stateCache.mandantenListCache = stateCache.mandantenListCache.map((el,i) => {
                if(this.state.searchInput.length > 0 && el.name.indexOf(this.state.searchInput) > -1){
                    el.checked = true;
                } 
                else if(this.state.searchInput.length <= 0){
                    el.checked = true;
                }
                return el;
            });
        }
        this.setState(stateCache);
    }
    inputChangeWrapper(e,index,type){
        let mandantenListCache = [].concat(this.state.mandantenList);
        let t = e.target.value;
        mandantenListCache[index].inputs[type] = t;
        this.setState({
            mandantenList: mandantenListCache
        });
    }
    bulkInputChangeWrapper(e,index,type){
        let mandantenListCache = [].concat(this.state.mandantenList);
        let bulkInputCache = Object.assign(this.state.bulkInput);
        let t = e.target.value;
        mandantenListCache = mandantenListCache.map((el,i) =>{
            if(el.checked){
                el.inputs[type] = t;
            }
            return el;
        });
        bulkInputCache[type] = t;
        this.setState({
            mandantenList: mandantenListCache,
            bulkInput: bulkInputCache
        })
    }
    submitClickHandler(e){
        if(window.confirm("Bereits ein Import vorhanden: Überschreiben?")){
            let nodeCache = this.state.mandantenList.filter((el) => el.checked);
            nodeCache = nodeCache.map((el) =>{
                return {
                    nodeId: el.nodeId,
                    mandant: el.inputs.mandant, 
                    endgerät: el.inputs.endgerät,
                    status: el.inputs.status
                };
            })
            let data = {
                selectedNodes: nodeCache,
            }
                this.setState({isLoading: true});
                this.props._post(this.urlScheduleImport(), JSON.stringify(data))
                .then((d) =>{
                    alert("Automatisierten Report erfolgreich angelegt");
                    return this.props._get(window.api + "/tms/schedule")
                })
                .then((d) =>{
                    this.setState({
                        isLoading: false,
                        hasOldSchedule: true,
                        oldSchedule: JSON.parse(d)
                    })
                })
                .catch((err) =>{
                    alert("ERROR: " + err);
                    this.setState({isLoading: false});
                })
        }
    }
    renewImport(){
        this.setState({isLoading: true});
        this.props._post(window.api + "/tms/renewImport", "") 
        .then((d) =>{
            alert("Erfolgreich importiert");
            this.setState({isLoading: false});
        })
        .catch((err) =>{
            alert("Import konnte nicht durchgeführt werden");
            this.setState({isLoading: false});
        })
    }
    render(){
        return(
            <div style={{alignContent: "flex-start"}} className="whiteWrapper" id="adminImportComponent">
                <Headline breadcrumb="data" headline="TMS Anbindung" other={["Automatisierter Import"]}/>
                <div id="positioner" style={{flexWrap: "wrap", height: "initial", alignItems: "flex-start", marginTop: 50}}>
                    <div className="scheduleWrapper">
                        <div className="currentScheduleWrapper">
                            <div className="currentSchedule">
                                {!this.state.isLoading && 
                                <>
                                    <div className="schedule_top">
                                        <div><h2>Aktueller Import</h2></div>
                                        { this.state.oldSchedule && 
                                            <div className="schedule_topleft" style={{}}>
                                                <div onClick={this.renewImport}><i style={{fontWeight: "700", fontSize: 40, cursor: "pointer"}}className="material-icons">autorenew</i></div>
                                            </div>
                                        }
                                    </div>
                                    <div className="schedule_bottom">
                                        {this.state.oldSchedule && this.state.oldSchedule.selectedNodes.sort((a,b) =>{
                                            if(a.mandant.toLowerCase() < b.mandant.toLowerCase()){
                                                return -1
                                            }
                                            else if(a.mandant.toLowerCase() > b.mandant.toLowerCase()){
                                                return 1
                                            }
                                            if(a.endgerät.toLowerCase() < b.endgerät.toLowerCase()){
                                                return -1
                                            }
                                            else if(a.endgerät.toLowerCase() > b.endgerät.toLowerCase()){
                                                return 1
                                            }
                                            return 0;
                                        }).map((el, i) =>{
                                            return(
                                                <div className="schedule_node" key={i}>
                                                    <div style={{textAlign: "center", fontWeight: 700}}>{helper.capitalize(el.mandant)}</div>
                                                    <div style={{textAlign: "center"}}>{helper.capitalize(el.endgerät)}</div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </>
                                }
                                {this.state.isLoading && 
                                    <Loader 
                                        scale={0.4} 
                                    /> 
                                }
                            </div>
                        </div>
                        {!this.state.isLoading && 
                            <>
                                <div ><h2>Neuen Automatisierten Import anlegen</h2></div>
                                <div className="searchBarWrapper">
                                    <input onChange={this.searchOnChangeHandler} id="searchBar" type="text" placeholder="Suche" value={this.state.searchInput}></input>
                                </div>
                            </>
                        }
                        <div className="checkboxWrapper">
                            {!this.state.isLoading && 
                                <>
                                    <div className="mandantenCheckboxItem" style={{width: "100%"}}>
                                        <input id={"schedulecheckboxAll"} checked={this.state.checkedAll} onChange={this.checkAllOnChangeHandler} name={"schedulecheckboxAll"} type="checkbox"/>
                                        <label htmlFor={"schedulecheckboxAll"} style={{fontWeight: 700}}>Alle</label>
                                    </div>
                                    {this.state.mandantenList.sort((a,b) =>{
                                        if(a.name.toLowerCase() < b.name.toLowerCase()){
                                            return -1
                                        }
                                        else if(a.name.toLowerCase() > b.name.toLowerCase()){
                                            return 1
                                        }
                                        return 0;
                                    }).map((el,i) => {
                                        if(!this.state.isSearchActive || (el.name.toLowerCase()).indexOf(this.state.searchInput.toLowerCase()) > -1){
                                            return(
                                                <div className="mandantenCheckboxItem" key={i}>
                                                    <input id={"schedulecheckbox" + i} checked={this.state.mandantenList[i].checked} onChange={(evt) => this.checkOnChangeHandler(i)} name={"schedulecheckbox" + i} type="checkbox"/>
                                                    <label htmlFor={"schedulecheckbox" + i}>{el.name}</label>
                                                </div>
                                            )
                                        }
                                    })}
                                </>
                            }
                            {this.state.isLoading && 
                                <Loader /> 
                            }
                        </div>
                    </div>
                </div>
                <div style={{margin: "auto"}}>
                    { (!this.state.isLoading && this.state.mandantenList.reduce((acc, curr) =>{return (curr.checked) ? acc+1 : acc},0) > 0) &&
                    <>
                        <ScheduleInputWrapper
                            inputState = {this.state.bulkInput}
                            nodeName = {"Massenbearbeitung"}
                            inputChangeHandler = {this.bulkInputChangeWrapper}
                            index = {1}
                            bgColor = "lightskyblue"
                        />
                        <hr/>
                    </>
                    }
                    {!this.state.isLoading && this.state.mandantenList.map((el,i) => {
                        if(el.checked){
                            return(
                                <ScheduleInputWrapper
                                    inputState = {this.state.mandantenList[i].inputs}
                                    nodeName = {this.state.mandantenList[i].name}
                                    inputChangeHandler = {this.inputChangeWrapper}
                                    index = {i}
                                    key = {i}
                                />
                            )
                        }
                    })}
                    {(!this.state.isLoading && this.state.mandantenList.reduce((acc, curr) =>{return (curr.checked) ? acc+1 : acc},0) > 0) &&
                        <div id="abschickenButtonWrapper">
                            <button className="button" onClick={this.submitClickHandler}>Abschicken</button>
                        </div>
                    }
                </div>
            </div>
        )
    }
}

const ScheduleInputWrapper = function({inputState, nodeName, inputChangeHandler, index, bgColor, isBulk=false}){
    return(
        <div className="schedule_inputWrapper" style={{background: (bgColor) ? bgColor : ""}}>
            <div className="nodeNameWrapper">
                <span>{nodeName}</span>
            </div>
            <div className="scheduleInputWrapper">
                <input onChange={(e) => inputChangeHandler(e, index, "mandant")} value={inputState.mandant} className="niceSelect schedule_input" type="text" placeholder="Mandant"></input>
                <input onChange={(e) => inputChangeHandler(e, index, "endgerät")} value={inputState.endgerät} className="niceSelect schedule_input" type="text" placeholder="Endgerät"></input>
                <input onChange={(e) => inputChangeHandler(e,index, "status")} value={inputState.status} className="niceSelect schedule_input" type="text" placeholder="Status"></input>
            </div>
        </div>
    )
}

export default AdminSchedule;