import React from "react";
import { Switch, Route } from "react-router-dom";

import Navbar from "./Navbar.js";
import Start from "./Start.js";
import Planung from "./Planung.js";
import PlanningWizzard from "./PlanningWizzard.js";
import Abfrage from "./Abfrage.js";
import LoaderWrapper from "./LoaderWrapper.js";
import Admin from "./Admin.js";
import AdminUser from "./AdminUser.js";
import AdminDaten from "./AdminDaten.js";
import AdminMandanten from "./AdminMandanten.js";
import AdminImport from "./AdminImport.js";
import AdminTMS from "./AdminTMS.js";
import AdminSchedule from "./AdminSchedule.js";

import "./Login.css";
import "./App.css";
import "./style.css";

class App extends React.Component{
	constructor(props){
		super(props);
		this.state ={
			name: "",
            session: "",
            permission: 0,
            isAuthed: false,
            checkedAuth: false,
            isLoading: false,
            wrongCredentials: false,
            reports: [""],
            clickedLogout: false,
        };
        window.api = "https://xtabelle.iqd-ao.de/api";
        // window.api = "http://localhost:8000/api";
        this.checkAuthentication();
        this.checkAuthentication = this.checkAuthentication.bind(this);
        this.toggleGui = this.toggleGui.bind(this);
        this.toggleLoadingState = this.toggleLoadingState.bind(this);
        this.setAppState = this.setAppState.bind(this);
        this.logout = this.logout.bind(this);
        // this.loginHandle = this.loginHandle.bind(this);
    }
    setAppState(obj){
        this.setState(obj);
    }
    checkAuthentication(){
        this._get(window.api + "/auth")
            .then((response) => {
                let parsed = JSON.parse(response);
                if(parsed.isAuthed){
                    this.setState({
                        isAuthed: true,
                        isLoading: false,
                        checkedAuth: true,
                        name: parsed.name,
                        permission: parsed.permission,
                    })
                }
                else{
                    this.setState({
                        isAuthed: false,
                        isLoading: false,
                        checkedAuth: true,
                    });
                }
            }).catch( (err) => {
                this.setState({
                    isAuthed: false,
                    isLoading: false,
                    checkedAuth: true,
                });
            })
    }
	toggleGui(className, classLoading){
		return (this.state.isLoading) ? className + " " + classLoading : className;
    }
    toggleLoadingState(bool){
        this.setState({
            isLoading: (bool !== undefined) ? bool : !this.state.isLoading,
        })
    }
    toggleAuthed(bool){
        this.setState({
            isAuthed: (bool !== undefined) ? bool : !this.state.isAuthed,
        })
    }
    _get(url,header=null) {
        return new Promise(function(resolve, reject) {
            var req = new XMLHttpRequest();
            req.open('GET', url);
            req.withCredentials = true;
            req.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
            req.setRequestHeader("Cache-Control", "no-cache");
            if(header){
                header.forEach((el) =>{
                    req.setRequestHeader(el.key, el.value);
                })
            }
            req.onload = function() {
                if (req.status === 200) {
                    resolve(req.response);
                }
                else {
                    reject(new Error(req));
                }
            };
            req.onerror = function() {
                reject(Error("Network Error"));
            };
            req.send();
        });
    }
    _post(url, data, json=false) {
        return new Promise(function(resolve, reject) {
            var req = new XMLHttpRequest();
            req.open('POST', url);
            req.withCredentials = true;
            // if(!json){
            //     req.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
            // }
            req.setRequestHeader("Content-Type", "application/json");
            req.onreadystatechange = function() {
                if (this.status === 200) {
                    if(this.readyState === 4){
                        resolve(this);
                    }
                }
                else {
                    reject(new Error(this.status));
                }
            };
            req.onerror = function() {
                reject(new Error("Network Error"));
            };

            req.send(data);
        });
    }
    _delete(url, data, json=false) {
        return new Promise(function(resolve, reject) {
            var req = new XMLHttpRequest();
            req.open('DELETE', url);
            req.withCredentials = true;
            // if(!json){
            //     req.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
            // }
            req.setRequestHeader("Content-Type", "application/json");
            req.onreadystatechange = function() {
                if (this.status === 200) {
                    if(this.readyState === 4){
                        resolve(this);
                    }
                }
                else {
                    reject(new Error(this.status));
                }
            };
            req.onerror = function() {
                reject(new Error("Network Error"));
            };
            req.send(data);
        });
    }
    _put(url, data, json=false){
        return new Promise(function(resolve, reject) {
            var req = new XMLHttpRequest();
            req.open('PUT', url);
            req.withCredentials = true;
            // if(!json){
            //     req.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
            // }
            req.setRequestHeader("Content-Type", "application/json");
            req.onreadystatechange = function() {
                if (this.status === 200) {
                    if(this.readyState === 4){
                        resolve(this);
                    }
                }
                else {
                    reject(Error(this.status));
                }
            };
            req.onerror = function() {
                reject(Error("Network Error"));
            };
            req.send(data);
        });
    }
    logout(){
        this.setState({
            clickedLogout: true,
        });
        this._get(window.api + "/logout")
            .then( () =>{
                this.setState({
                    isAuthed: false,
                    clickedLogout: false,
                    permission: 0,
                    name: "",
                })
            }).catch( (err) => console.error(err));
    }
    render(){
        return (
            <div className="App">
                <header className="App-header" style={(this.state.isAuthed !== "") ? {minHeight: "0px", height: "150px"} : {minHeight: "0px", height: "100px"}}>
                    <Navbar 
                        isAuthed={this.state.isAuthed} 
                        permission = {this.state.permission}
                        logout={this.logout}
                    />
                </header>
                <section style={{paddingBottom: 80, boxSizing: "border-box"}}>
                    {this.state.clickedLogout &&
                        <LoaderWrapper
                            checkAuthentication={this.checkAuthentication}
                            setAppState={this.setAppState}
                            checkedAuth={false}
                            isAuthed={this.state.isAuthed}
                            toggleGui={this.toggleGui}
                            toggleLoadingState = {this.toggleLoadingState}
                            isLoading = {this.state.isLoading}
                            _post={this._post}
                            wrongCredentials={this.state.wrongCredentials}
                        >
                        </LoaderWrapper>
                    }
                    {!this.state.clickedLogout &&
                    <Switch>
                        <Route 
                            path="/start" 
                            render={(props)=>{
                                return(
                                    <LoaderWrapper
                                        checkAuthentication={this.checkAuthentication}
                                        setAppState={this.setAppState}
                                        checkedAuth={this.state.checkedAuth}
                                        isAuthed={this.state.isAuthed}
                                        toggleGui={this.toggleGui}
                                        toggleLoadingState = {this.toggleLoadingState}
                                        isLoading = {this.state.isLoading}
                                        _post={this._post}
                                        wrongCredentials={this.state.wrongCredentials}
                                        {...props}
                                    >
                                        <Start/>
                                    </LoaderWrapper>
                                )
                            }}/>
                        <Route
                            path="/planung/planner"
                            render={(props) =>{
                                return(
                                    <LoaderWrapper
                                        checkAuthentication={this.checkAuthentication}
                                        name={this.state.name}
                                        setAppState={this.setAppState}
                                        checkedAuth={this.state.checkedAuth}
                                        isAuthed={this.state.isAuthed}
                                        toggleGui={this.toggleGui}
                                        isLoading={this.state.isLoading}
                                        toggleLoadingState = {this.toggleLoadingState}
                                        _post={this._post}
                                        wrongCredentials={this.state.wrongCredentials}
                                        {...props}
                                    >
                                        <PlanningWizzard 
                                            breadcrumb= {"Planung"}
                                            headline= {"Zeit > Desktop > Live > Artikel"}
                                            checkAuthentication={this.checkAuthentication}
                                            name={this.state.name}
                                            isLoading={this.state.isLoading}
                                            setAppState={this.setAppState}
                                            checkedAuth={this.state.checkedAuth}
                                            isAuthed={this.state.isAuthed}
                                            _post={this._post}
                                            _get={this._get}
                                            wrongCredentials={this.state.wrongCredentials}
                                            permission={this.state.permission}
                                            {...props}
                                        />
                                    </LoaderWrapper>
                                )
                            }}/>
                        <Route 
                            path="/planung" 
                            render={(props)=>{
                                return(
                                    <LoaderWrapper
                                        checkAuthentication={this.checkAuthentication}
                                        setAppState={this.setAppState}
                                        checkedAuth={this.state.checkedAuth}
                                        isAuthed={this.state.isAuthed}
                                        toggleGui={this.toggleGui}
                                        toggleLoadingState = {this.toggleLoadingState}
                                        _post={this._post}
                                        wrongCredentials={this.state.wrongCredentials}
                                        {...props}
                                    >
                                        <Planung 
                                            _get={this._get}
                                            _post={this._post}
                                            permission={this.state.permission}
                                        >
                                        </Planung>
                                    </LoaderWrapper>
                                )
                            }}/>
                        <Route 
                            path="/abfrage/report" 
                            render={(props)=>{
                                return(
                                    <LoaderWrapper
                                        checkAuthentication={this.checkAuthentication}
                                        setAppState={this.setAppState}
                                        checkedAuth={this.state.checkedAuth}
                                        isAuthed={this.state.isAuthed}
                                        toggleGui={this.toggleGui}
                                        toggleLoadingState = {this.toggleLoadingState}
                                        _post={this._post}
                                        wrongCredentials={this.state.wrongCredentials}
                                        {...props}
                                    >
                                        <Abfrage
                                            setAppState={this.setAppState}
                                            _get={this._get}
                                            _post={this._post}
                                            _delete={this._delete}
                                            toggleGui={this.toggleGui}
                                            toggleLoadingState = {this.toggleLoadingState}
                                            checkAuthentication = {this.checkAuthentication}
                                            checkedAuth = {this.state.checkedAuth}
                                            isLoading={this.state.isLoading}
                                            reports={this.state.reports}
                                            buildQueryString={this.buildQueryString}
                                            {...props}
                                        />
                                    </LoaderWrapper>
                                )
                            }}/>
                        <Route 
                            path="/abfrage/neu" 
                            render={(props)=>{
                                return(
                                    <LoaderWrapper
                                        checkAuthentication={this.checkAuthentication}
                                        setAppState={this.setAppState}
                                        checkedAuth={this.state.checkedAuth}
                                        isAuthed={this.state.isAuthed}
                                        toggleGui={this.toggleGui}
                                        toggleLoadingState = {this.toggleLoadingState}
                                        _post={this._post}
                                        wrongCredentials={this.state.wrongCredentials}
                                        {...props}
                                    >
                                        <Abfrage
                                            _get={this._get}
                                            _post={this._post}
                                            _delete={this._delete}
                                            setAppState={this.setAppState}
                                            toggleGui={this.toggleGui}
                                            toggleLoadingState = {this.toggleLoadingState}
                                            checkAuthentication = {this.checkAuthentication}
                                            checkedAuth = {this.state.checkedAuth}
                                            isLoading={this.state.isLoading}
                                            reports={this.state.reports}
                                            {...props}
                                        />
                                    </LoaderWrapper>
                                )
                            }}/>
                        <Route 
                            path="/abfrage" 
                            render={(props)=>{
                                return(
                                    <LoaderWrapper
                                        checkAuthentication={this.checkAuthentication}
                                        setAppState={this.setAppState}
                                        checkedAuth={this.state.checkedAuth}
                                        isAuthed={this.state.isAuthed}
                                        toggleGui={this.toggleGui}
                                        toggleLoadingState = {this.toggleLoadingState}
                                        _get={this._get}
                                        _post={this._post}
                                        wrongCredentials={this.state.wrongCredentials}
                                        {...props}
                                    >
                                        <Abfrage
                                            _get={this._get}
                                            _post={this._post}
                                            _delete={this._delete}
                                            setAppState={this.setAppState}
                                            toggleGui={this.toggleGui}
                                            toggleLoadingState = {this.toggleLoadingState}
                                            checkAuthentication = {this.checkAuthentication}
                                            checkedAuth = {this.state.checkedAuth}
                                            isLoading={this.state.isLoading}
                                            reports={this.state.reports}
                                            {...props}
                                        />
                                    </LoaderWrapper>
                                )
                            }}/>
                        <Route 
                            path="/data/layout" 
                            render={(props)=>{
                                return(
                                    <LoaderWrapper
                                        checkAuthentication={this.checkAuthentication}
                                        setAppState={this.setAppState}
                                        checkedAuth={this.state.checkedAuth}
                                        isAuthed={this.state.isAuthed}
                                        toggleGui={this.toggleGui}
                                        toggleLoadingState = {this.toggleLoadingState}
                                        _get={this._get}
                                        _post={this._post}
                                        wrongCredentials={this.state.wrongCredentials}
                                        needAdmin = {true}
                                        permission = {this.state.permission}
                                        {...props}
                                    >
                                        <Admin 
                                            _get = {this._get} 
                                            _post = {this._post}
                                        />
                                    </LoaderWrapper>
                                )
                            }}/>
                        <Route 
                            path="/data/daten" 
                            render={(props)=>{
                                return(
                                    <LoaderWrapper
                                        checkAuthentication={this.checkAuthentication}
                                        setAppState={this.setAppState}
                                        checkedAuth={this.state.checkedAuth}
                                        isAuthed={this.state.isAuthed}
                                        toggleGui={this.toggleGui}
                                        toggleLoadingState = {this.toggleLoadingState}
                                        _get={this._get}
                                        _post={this._post}
                                        wrongCredentials={this.state.wrongCredentials}
                                        needAdmin = {true}
                                        permission = {this.state.permission}
                                        {...props}
                                    >
                                        <AdminDaten
                                            _get = {this._get} 
                                            _post = {this._post}
                                        />
                                    </LoaderWrapper>
                                )
                            }}/>
                        <Route 
                            path="/data/mandanten" 
                            render={(props)=>{
                                return(
                                    <LoaderWrapper
                                        checkAuthentication={this.checkAuthentication}
                                        setAppState={this.setAppState}
                                        checkedAuth={this.state.checkedAuth}
                                        isAuthed={this.state.isAuthed}
                                        toggleGui={this.toggleGui}
                                        toggleLoadingState = {this.toggleLoadingState}
                                        _get={this._get}
                                        _post={this._post}
                                        wrongCredentials={this.state.wrongCredentials}
                                        needAdmin = {true}
                                        permission = {this.state.permission}
                                        {...props}
                                    >
                                        <AdminMandanten 
                                            _get = {this._get} 
                                            _post = {this._post}
                                        />
                                    </LoaderWrapper>
                                )
                            }}/>
                        <Route 
                            path="/data/user" 
                            render={(props)=>{
                                return(
                                    <LoaderWrapper
                                        checkAuthentication={this.checkAuthentication}
                                        setAppState={this.setAppState}
                                        checkedAuth={this.state.checkedAuth}
                                        isAuthed={this.state.isAuthed}
                                        toggleGui={this.toggleGui}
                                        toggleLoadingState = {this.toggleLoadingState}
                                        _get={this._get}
                                        _post={this._post}
                                        wrongCredentials={this.state.wrongCredentials}
                                        needAdmin = {true}
                                        permission = {this.state.permission}
                                        adminOnly = {true}
                                        {...props}
                                    >
                                        <AdminUser
                                            _get = {this._get} 
                                            _post = {this._post}
                                            _delete = {this._delete}
                                            name = {this.state.name}
                                        />
                                    </LoaderWrapper>
                                )
                            }}/>
                        <Route 
                            path="/data/tms" 
                            render={(props)=>{
                                return(
                                    <LoaderWrapper
                                        checkAuthentication={this.checkAuthentication}
                                        setAppState={this.setAppState}
                                        checkedAuth={this.state.checkedAuth}
                                        isAuthed={this.state.isAuthed}
                                        toggleGui={this.toggleGui}
                                        toggleLoadingState = {this.toggleLoadingState}
                                        _get={this._get}
                                        _post={this._post}
                                        wrongCredentials={this.state.wrongCredentials}
                                        needAdmin = {true}
                                        permission = {this.state.permission}
                                        adminOnly = {true}
                                        {...props}
                                    >
                                        <AdminTMS
                                            _get = {this._get} 
                                            _post = {this._post}
                                            _delete = {this._delete}
                                            name = {this.state.name}
                                            toggleLoadingState = {this.toggleLoadingState}
                                            isLoading = {this.state.isLoading}
                                        />
                                    </LoaderWrapper>
                                )
                            }}/>
                        <Route 
                            path="/data/schedule" 
                            render={(props)=>{
                                return(
                                    <LoaderWrapper
                                        checkAuthentication={this.checkAuthentication}
                                        setAppState={this.setAppState}
                                        checkedAuth={this.state.checkedAuth}
                                        isAuthed={this.state.isAuthed}
                                        toggleGui={this.toggleGui}
                                        toggleLoadingState = {this.toggleLoadingState}
                                        _get={this._get}
                                        _post={this._post}
                                        wrongCredentials={this.state.wrongCredentials}
                                        needAdmin = {true}
                                        permission = {this.state.permission}
                                        adminOnly = {true}
                                        {...props}
                                    >
                                        <AdminSchedule
                                            _get = {this._get} 
                                            _post = {this._post}
                                            _delete = {this._delete}
                                            name = {this.state.name}
                                            toggleLoadingState = {this.toggleLoadingState}
                                            isLoading = {this.state.isLoading}
                                        />
                                    </LoaderWrapper>
                                )
                            }}/>
                        <Route 
                            path="/data/import" 
                            render={(props)=>{
                                return(
                                    <LoaderWrapper
                                        checkAuthentication={this.checkAuthentication}
                                        setAppState={this.setAppState}
                                        checkedAuth={this.state.checkedAuth}
                                        isAuthed={this.state.isAuthed}
                                        toggleGui={this.toggleGui}
                                        toggleLoadingState = {this.toggleLoadingState}
                                        _get={this._get}
                                        _post={this._post}
                                        wrongCredentials={this.state.wrongCredentials}
                                        needAdmin = {true}
                                        permission = {this.state.permission}
                                        adminOnly = {true}
                                        {...props}
                                    >
                                        <AdminImport
                                            _get = {this._get} 
                                            _post = {this._post}
                                            _delete = {this._delete}
                                            name = {this.state.name}
                                            toggleLoadingState = {this.toggleLoadingState}
                                            isLoading = {this.state.isLoading}
                                        />
                                    </LoaderWrapper>
                                )
                            }}/>
                        <Route 
                            path="/data" 
                            render={(props)=>{
                                return(
                                    <LoaderWrapper
                                        checkAuthentication={this.checkAuthentication}
                                        setAppState={this.setAppState}
                                        checkedAuth={this.state.checkedAuth}
                                        isAuthed={this.state.isAuthed}
                                        toggleGui={this.toggleGui}
                                        toggleLoadingState = {this.toggleLoadingState}
                                        _get={this._get}
                                        _post={this._post}
                                        wrongCredentials={this.state.wrongCredentials}
                                        needAdmin = {true}
                                        permission = {this.state.permission}
                                        {...props}
                                    >
                                        <Admin 
                                            permission = {this.state.permission}
                                        />
                                    </LoaderWrapper>
                                )
                            }}/>
                        <Route 
                            path="/" 
                            render={(props)=>{
                                return(
                                    <LoaderWrapper
                                        checkAuthentication={this.checkAuthentication}
                                        setAppState={this.setAppState}
                                        checkedAuth={this.state.checkedAuth}
                                        isAuthed={this.state.isAuthed}
                                        toggleGui={this.toggleGui}
                                        toggleLoadingState = {this.toggleLoadingState}
                                        _post={this._post}
                                        wrongCredentials={this.state.wrongCredentials}
                                        {...props}
                                    >
                                        <Start />
                                    </LoaderWrapper>
                                )
                            }}/>
                    </Switch>
                    }
                </section>
            </div>
        );
    }
}

export default App;