import React from "react";
import { Link } from "react-router-dom";

import "./Start.css";
import Loader from "./Loader.js";

class Start extends React.Component{
    render(){
            return(
                <div id="startWrapper">
                    <Link className="link" to="/planung">
                        <div className="item">
                            Planung 
                        </div>
                    </Link>
                    <Link className="link" to={{
                        pathname:"/abfrage",
                        state: {elements: []},
                        }}>
                        <div className="item">
                            Abfrage 
                        </div>
                    </Link>
                    {/* <Link disabled="disabled" className="link" to="/abgleich"> */}
                        <div style={{display: "flex", justifyContent: "center", alignItems: "center", alignContent: "center",color: "grey", borderColor: "grey", flexWrap: "wrap",}} className="item">
                            <div  >Abgleich</div>
                            <div style={{fontWeight: "400", fontStyle: "italic"}} >Coming Soon...</div>
                        </div>
                    {/* </Link> */}
                </div>
            )
    }
}

export default Start;