import React from "react";
import Loader from "./Loader.js";
import "./NeutralInputDiv.css"

/**
 * @class NeutralInputDiv
 * @param {function} clickHandler @param {Number} index
 * @param {Array:String} values
 * @param {number} selectedIndex
 * @param {boolean} isLoading
 * @param {Number} scaleWidth - 
 * @param {Number} scaleHeight - 
 */

class NeutralInputDiv extends React.Component{
    constructor(props){
        super(props);
    }
    render(){
        return(
            <div style={{width: this.props.scaleWidth || null, height: this.props.scaleHeight || null}}className="neutralInputDiv">
                <div className="listDiv">
                    {this.props.isLoading && 
                        <div style={{width: "100%", height:"100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
                            <Loader
                                scale="0.5"
                            /> 
                        </div>
                    }
                    {(!this.props.isLoading && this.props.values.length > 0) && this.props.values.map((el,i) =>{
                        let clName = (this.props.selectedIndex === i) ? "listItem selected": "listItem";
                        return(
                            <ListItem
                                clName = {clName}
                                selectedIndex = {this.props.selectedIndex}
                                el = {el}
                                key = {i}
                                index = {i}
                                clickHandler = {this.props.clickHandler}
                                buttons = {this.props.listItemButtons}
                                valueOrder = {this.props.valueOrder}
                                valueStyles = {this.props.valueStyles}
                                listItemHeight = {this.props.listItemHeight}
                                valueHeadlines = {this.props.valueHeadlines}
                            />
                        )
                        })
                    }
                    {(!this.props.isLoading && this.props.values.length === 0) &&
                        <div style={{color: "#adadad", display: "flex", justifyContent: "center", cursor: "auto"}} className="listItem"><span>Noch keine Einträge vorhanden</span></div>                    
                    }
                </div>
            </div>
        )
    }
}

/**
 * @class ListItem
 * @param {function} clickHandler @param {Number} index
 * @param {String} el - Value
 * @param {String} clName - className
 * @param {Number} index
 * @param {Number} selectedIndex
 * @param {Array:Object} buttons - additional Buttons
 */
class ListItem extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            hover: false,
        }
        this.clickHandlerWrapper = this.clickHandlerWrapper.bind(this);
        this.toggleHover = this.toggleHover.bind(this);
    }
    clickHandlerWrapper(){
        this.props.clickHandler(this.props.index)
    }
    toggleHover(){
        this.setState({
            hover: !this.state.hover,
        })
    }
    render(){
        let objectKeys = (typeof this.props.el === "object" && Object.keys(this.props.el).length >0) ? Object.keys(this.props.el) : null;
        return(
            <div onClick={this.clickHandlerWrapper} className={this.props.clName} style={(this.props.listItemHeight) ? {height: this.props.listItemHeight} : {}}>
                {(!objectKeys) &&
                    <div>{this.props.el}</div>
                }
                {(this.props.valueOrder) &&
                    <div>
                        {this.props.valueOrder.map((l, i) =>{
                            return(
                                    <span key={i} style={{display: "block",...this.props.valueStyles[i]}}>
                                        {(this.props.valueHeadlines[i]) ? this.props.valueHeadlines[i] + ": " + this.props.el[l] : this.props.el[l]}
                                    </span>
                            )
                        })
                        }
                    </div>
                    
                }
                {(this.props.selectedIndex === this.props.index && this.props.buttons) &&
                    <div>{this.props.buttons.map((el,ind) =>{
                        let iconColor = (this.state.hover) ? el.hoverColor : "white";
                        return(
                            <i key={ind} style={{color: iconColor}} onClick={el.clickHandler} onMouseEnter={this.toggleHover} onMouseLeave={this.toggleHover} className="material-icons additionalButton">{el.iconName}</i>
                        )
                        })
                        }
                    </div>
                }
            </div>
        )
    }
}

export default NeutralInputDiv;