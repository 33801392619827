import React from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";

class Navbar extends React.Component{
    render(){
        return (
            <div id="navbarWrapper" >
                <div id="logoLinkWrapper">
                    <Link id="logoLink" className="navLink" to="/"><span id="orange_bold">IQD </span>𝕏-Tabelle </Link>
                </div>
                <div id="navItemsWrapper">
                    <div id="startLinkWrapper" className="linkWrapper">
                        <Link id="startLink" className="navLink" to="/start">Start</Link>
                    </div>
                    {(this.props.isAuthed && this.props.permission < 3 ) &&
                        <div id="adminLinkWrapper" className="linkWrapper">
                            <Link id="adminLink" className="navLink" to="/data">Data</Link>
                        </div>
                    }
                    {this.props.isAuthed && 
                        <div id="logoutLinkWrapper" className="linkWrapper" onClick={this.props.logout}>
                            <span className="navLink">Logout</span>
                        </div>
                    }
                </div>
            </div>
        )
    }
}


export default Navbar;