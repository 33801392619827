import React from "react";
import helper from "./helper.js";

class AddSlotDialog extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            currentData: {
                slotWidth: "Auswählen",
                iqadtileId: "Auswählen",
                slotSizeCurrent: "Auswählen",
                slotSizes: ["99x1"],
                schemaSizes: [],
                schemaAdtiles: [],
                template: "Auswählen",
            },
            errorMsg: null,
        }
        this.templateChangeHandler = this.templateChangeHandler.bind(this);
        this.slotWidthChangeHandler = this.slotWidthChangeHandler.bind(this);
        this.iqadtileIdChangeHandler = this.iqadtileIdChangeHandler.bind(this);
        this.slotSizesChangeHandler = this.slotSizesChangeHandler.bind(this);
        this.addSizeClickHandler = this.addSizeClickHandler.bind(this);
        this.deleteSizeClickHandler = this.deleteSizeClickHandler.bind(this);
        this.addButtonClickHandler = this.addButtonClickHandler.bind(this);
        this.editButtonClickHandler = this.editButtonClickHandler.bind(this);
    }
    componentDidMount(){
        if(this.props.mode === "edit"){
            let c = Object.assign(this.state.currentData);
            let cShape = window.shapes[window.shapes.length-1]
            switch(Math.floor(cShape.width/100)){
                case 1:
                    c.slotWidth = "1/3";
                    break;
                case 3:
                    c.slotWidth = "2/3";
                    break;
                case 5:
                    c.slotWidth = "3/3";
                    break;
                default: 
                    c.slotWidth = "Auswählen"
            }
            c.iqadtileId = cShape.iatId;
            c.slotSizes = [...cShape.slotsize];
            this.setState({
                currentData: c,
            })
        }
    }
    templateChangeHandler(evt){
        let c = Object.assign(this.state.currentData);
        c.template = evt.target.value;
        if(evt.target.value !== "Keine Vorlage" && evt.target.value !== "Auswählen"){
            let cShape = window.shapes.filter((el) => el.iatId === evt.target.value);
            switch(cShape[0].width){
                case 166:
                    c.slotWidth = "1/3";
                    break;
                case 332:
                    c.slotWidth = "2/3";
                    break;
                case 500:
                    c.slotWidth = "3/3";
                    break;
                default: 
                    c.slotWidth = "Auswählen"
            }
            c.slotSizes = helper.sortSlotSizes([...cShape[0].slotsize]);
        }
        this.setState({
            currentData: c,
            errorMsg: null,
        });
    }
    slotWidthChangeHandler(evt){
        let c = Object.assign(this.state.currentData);
        c.slotWidth = evt.target.value;
        this.setState({
            currentData: c,
            errorMsg: null,
        });
    }
    iqadtileIdChangeHandler(evt){
        let c = Object.assign(this.state.currentData);
        c.iqadtileId = evt.target.value;
        this.setState({
            currentData: c,
            errorMsg: null,
        });
    }
    slotSizesChangeHandler(evt){
        let c = Object.assign(this.state.currentData);
        c.slotSizeCurrent = evt.target.value;
        this.setState({
            currentData: c,
            errorMsg: null,
        });
    }
    addSizeClickHandler(evt){
        let c = Object.assign(this.state.currentData);
        if(c.slotSizes.includes(this.state.currentData.slotSizeCurrent)){
            this.setState({
                errorMsg: "Diese Größe wurde bereits hinzugefügt!"
            })
        }
        else if(this.state.currentData.slotSizeCurrent === "init" || !this.state.currentData.slotSizeCurrent){
            this.setState({
                errorMsg: "Bitte wähle eine Größe aus!"
            })
        }
        else{
            c.slotSizes = helper.sortSlotSizes([...this.state.currentData.slotSizes].concat(this.state.currentData.slotSizeCurrent));
            this.setState({
                currentData: c,
                errorMsg: null,
            });
        }
    }
    deleteSizeClickHandler(index){
        if(this.state.currentData.slotSizes[index] === "99x1"){
            this.setState({
                errorMsg: "Slotgröße für Ausbuchungen! Kann nicht gelöscht werden."
            })
        }
        else{
            let c = Object.assign(this.state.currentData);
            c.slotSizes.splice(index, 1);
            this.setState({
                currentData: c,
            });
        }
    }
    filterAdtileIds(){
        window.IQDTILEID.filter((el) =>{
            if(window.shapes.map((il) => il.iatId).includes(el)){
                return false;
            }
            else{
                return true;
            }
        })
    }
    addButtonClickHandler(){
        let slotWidth;
        switch(this.state.currentData.slotWidth){
            case "1/3":
                slotWidth = 333;
                break;
            case "2/3":
                slotWidth = 666;
                break;
            default:
                slotWidth = 1000;
        }
        if(
            (this.state.currentData.iqadtileId && this.state.currentData.iqadtileId !== "") &&
            (this.state.currentData.slotSizes) &&
            (this.state.currentData.slotWidth)
        ){
            this.props.addAdtile(this.state.currentData.iqadtileId, this.state.currentData.slotSizes, slotWidth);
            this.props.toggleDialogModal();
        }
        else{
            this.setState({
                errorMsg: "Alle Felder müssen ausgefüllt werden!"
            });
        }
    }
    editButtonClickHandler(){
        let cShape = window.shapes[window.shapes.length-1];
        let slotWidth;
        switch(this.state.currentData.slotWidth){
            case "1/3":
                slotWidth = 166;
                break;
            case "2/3":
                slotWidth  = 332;
                break;
            default:
                slotWidth = 500;
        }
        if(
            (this.state.currentData.iqadtileId && this.state.currentData.iqadtileId !== "") &&
            (this.state.currentData.slotSizes) &&
            (this.state.currentData.slotWidth)
        ){
            cShape.width = slotWidth;
            cShape.iatId = this.state.currentData.iqadtileId;
            cShape.slotsize = this.state.currentData.slotSizes;
            this.props.toggleDialogModal();
        }
        else{
            this.setState({
                errorMsg: "Alle Felder müssen ausgefüllt werden!"
            });
        }
    }
    render(){
        let addSlotDialogGridStyle = (this.props.mode === "edit") ? {} : {gridTemplateRows: "repeat(5, 1.5fr) 1fr 5fr"};
        let slotSizeContainer = (this.props.mode === "edit") ? {} : {gridRow: "6/8"};
        return(
            <div id="addSlotDialog">
                <div style={{
                    width: "90%",
                    height: "95%",
                }}>
                    <div >
                        <h4 style={{textAlign: "left", marginBottom: 50}}>{(this.props.mode === "edit") ? "Werbeplatz bearbeiten" : "Werbeplatz hinzufügen"}</h4>
                    </div>
                    <div id="addSlotDialogGrid" style={addSlotDialogGridStyle}>
                        {this.props.mode !== "edit" &&
                        <InputFieldRow 
                            labelName="Vorlage" 
                            needsDatalist={true}
                            listOptions = {["Keine Vorlage", ...window.shapes.map((el) => el.iatId)]}
                            value = {this.state.currentData.template}
                            onChange = {this.templateChangeHandler}
                        />
                        }
                        <InputFieldRow 
                            labelName="Slotbreite" 
                            needsDatalist={true}
                            listOptions = {["1/3", "2/3", "3/3"]}
                            value = {this.state.currentData.slotWidth}
                            onChange = {this.slotWidthChangeHandler}
                        />
                        <InputFieldRow 
                            labelName="IqadtileID" 
                            needsDatalist={true}
                            // listOptions = {this.state.schemaAdtiles.filter((elm) => !window.shapes.map((el) => el.iatId).includes(elm))}
                            listOptions = {this.props.schemaAdtiles}
                            value = {this.state.currentData.iqadtileId}
                            onChange = {this.iqadtileIdChangeHandler}
                            mode = {this.props.mode}
                        />
                        <InputFieldRow 
                            labelName="Slotgröße" 
                            needsDatalist={true}
                            listOptions={this.props.schemaSizes}
                            value = {this.state.currentData.slotSizeCurrent}
                            onChange = {this.slotSizesChangeHandler}
                            additionalClass = {"needsButton"}
                        >
                            <div style={{gridColumn: "3/5"}}>
                                <button onClick={this.addSizeClickHandler} className="button" style={{border: "2px solid rgb(255,135,36)", minWidth: 1, height: "100%", width: "100%", margin:0}}>Hinzufügen</button>
                            </div>
                        </InputFieldRow>
                        {this.state.errorMsg && 
                            <div id="errorMsg"><span>{this.state.errorMsg}</span></div>
                        }
                        <div id="slotSizeContainer" style={slotSizeContainer}>
                            {
                                this.state.currentData.slotSizes.map((el,index) => {
                                    return(
                                        <SizeChip 
                                            size = {el} 
                                            key = {index}
                                            index = {index}
                                            deleteSizeClickHandler = {this.deleteSizeClickHandler}
                                        />
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div id="addSlotSave">
                        {this.props.mode === "add" && 
                            <button onClick={this.addButtonClickHandler} className="button">Hinzufügen</button>
                        }
                        {this.props.mode === "edit" && 
                            <button onClick={this.editButtonClickHandler} className="button">Speichern</button>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

class InputFieldRow extends React.Component{
    render(){
        let topClassName = "inputFieldRow ";
        topClassName +=(this.props.additionalClass) ? this.props.additionalClass : "";
        let listOptions = Array.from(this.props.listOptions);
        if(this.props.mode === "edit"){
            listOptions.push(window.shapes[window.shapes.length-1].iatId);
            listOptions.sort();
        }
        return(
            <div className={topClassName}>
                <div className="addSlotLabel">
                    <label >{this.props.labelName}</label>
                    {this.props.disclaimer &&
                        <span style={{display: "block", width: "100%", fontSize: 11, textAlign: "center"}}>{this.props.disclaimer}</span>
                    }
                </div>
                <div className="addSlotInput">
                {this.props.needsDatalist &&
                    <select value={this.props.value} onChange={this.props.onChange}>
                        <option value="init">Auswählen</option>
                        {this.props.listOptions && listOptions.map((el,ind) =>{
                            return(
                                <option value={el} key={ind}>{el}</option>
                            )
                        })
                        }
                    </select>
                }
                {!this.props.needsDatalist &&
                    <input onChange={this.props.onChange} value={this.props.value}></input> 
                }
                </div>
                {this.props.children}
            </div>
        )
    }
}

class SizeChip extends React.Component{
    constructor(props){
        super(props);
        this.deleteSizeClickWrapper = this.deleteSizeClickWrapper.bind(this);
    }
    deleteSizeClickWrapper(){
        this.props.deleteSizeClickHandler(this.props.index);
    }
    render(){
        return(
            <div className="sizeChip">
               <span>{this.props.size}</span><i onClick={this.deleteSizeClickWrapper} className="material-icons">cancel</i>
            </div>
        )
    }
}

export default AddSlotDialog;