import React from "react";
import helper from "./helper.js"
import {Link, Redirect} from "react-router-dom";

import Loader from "./Loader.js";


class AbfrageReport extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            filter: this.fillFilter() || {},
            tableHeaders: (this.props.report) ? this.props.report.elements.map( (el) => el.name.toLowerCase()) : this.props.elements.map( (el) => el.name.toLowerCase()),
            tableBody:[],
            page: 1,
            itemsPerPage: 50,
            sumPages: null,
        }
        this.translationTable = {
            mandant: "site",
            layout: "handle",
            iqadtile: "pos_tab.0.tile_id",
            aktiviert: "pos_tab.0.tile_data.enabled",
            größen: "pos_tab.0.tile_data.size",
            endgerät: "device",
        }
        this.fetchData();
        this.generateCsv = this.generateCsv.bind(this);
        this.arrowPageClickHandler = this.arrowPageClickHandler.bind(this);
        this.arrowEndPageClickHandler = this.arrowEndPageClickHandler.bind(this);
    }
    fillFilter(){
        let c = {};
        let curr = (this.props.report) ? this.props.report.elements : this.props.elements;
        try{
            for(let i = 0 ; i < curr.length; i++){
                c[curr[i].name.toLowerCase()] = [];
                if(curr[i].filtered){
                    curr[i].setFilters.forEach( (e) =>{
                        if(e[1]){
                            c[curr[i].name.toLowerCase()].push(e[0]);
                        }
                    })
                }
            }
        }
        catch(err){
            console.log(err);
        }
        console.log(c);
        return c;
    }
    fetchingDataBase(download = false){
        let url = (!download) ? window.api + "/abfrage/report?metrics=" : window.api + "/abfrage/report/download?metrics="
        let query = "";
        let elements;
        if(this.props.report){
            elements = this.props.report.elements;
        }
        else{
            elements = this.props.elements;
        }
        elements.forEach((el) =>{
            if(url[url.length-1] !== "="){
                url += ",";
            }
            url += el.name;
            if(el.filtered){
                query += "&" + el.name + "=";
                for(let i = 0; i < el.setFilters.length; i++){
                    if(el.setFilters[i][1]){
                        if(query[query.length-1] !== "="){
                            query += ","
                        }
                        query += el.setFilters[i][0].toLowerCase();
                    }
                }
            }
        })
        return this.props._get(url + query);
    }
    fetchData(){
        this.props.setAppState({
            isLoading: true,
        });
        let request = this.fetchingDataBase(false);
        request.then( (data) => {
            let parsedData = JSON.parse(data);
            let sumPages = (parsedData.length - (parsedData.length % this.state.itemsPerPage)) / this.state.itemsPerPage;
            sumPages = (sumPages < 1) ? 1 : Math.round(sumPages);
            this.setState({
                tableBody: parsedData,
                sumPages: sumPages,
            })
        }).catch((err)=>console.log(err));
    }
    generateCsv(){
        let request = this.fetchingDataBase(true);
        request.then((data) =>{
            let a = document.createElement('a');
            a.href= "data:text/csv;charset='UTF-8',"+data;
            window.lawl = data;
            a.download = ((this.props.reportName) ? this.props.reportName : "report") + ".csv"
            a.dispatchEvent(new MouseEvent("click"));
        }).catch((err) =>{
            console.error(err);
        });
    }
    componentDidMount(){
        this.props.setUrlHandler("AbfrageReport")
    }
    arrowPageClickHandler(evt){
        let isPrev = (/Prev/.test(evt.target.id));
        if(isPrev){
            if(this.state.page -1 >0){
                this.setState({
                    page: this.state.page-1
                });
            }
        }
        else{
            if(this.state.page+1 <= this.state.sumPages){
                this.setState({
                    page: this.state.page+1
                });
            }
        }
    }
    arrowEndPageClickHandler(evt){
        let isFirst = (/First/.test(evt.target.id));
        if(isFirst){
            this.setState({
                page: 1,
            });
        }
        else{
            this.setState({
                page: this.state.sumPages,
            });
        }
    }
    render(){
        return(
            <div id="abfrageReportWrapper">
                <div id="headerMenuWrapper">
                    <div className="headerMenuElement">
                        <h3>Name</h3>
                        <span id="headerMenuReportname">{(this.props.reportName) ? this.props.reportName : "Unbenannt"}</span>
                        <Link 
                            to={{
                                pathname:"/abfrage/neu", 
                                state: {report: this.props.report, elements: this.props.elements}}} 
                            style={{color: "#282c34", fontWeight: "700", textDecoration: "none"}}>
                            <i style={{display: "block",}} className="material-icons">create</i>
                        </Link>
                    </div>
                    <div className="headerMenuElement" >
                        <h3>Filter</h3>
                        <div id="filterWrapper">
                            {
                                (this.state.filter.length < 1) &&
                                <div style={{display: "flex", justifyContent: "center", alignItems: "center", color: "#929292"}}>Keine Metriken gefiltert</div>
                            }
                            {
                                this.state.tableHeaders.map((headerElement, headerIndex) => {
                                    if(this.state.filter[headerElement].length >0){
                                        return(
                                            <div className="filterSwitch" key={headerIndex}>
                                                <div className="filterElementHead">
                                                    <span>{headerElement[0].toUpperCase()+headerElement.substring(1)}</span>
                                                </div>
                                                <div className="filterElementBody">
                                                    <ul>
                                                        {
                                                            this.state.filter[headerElement].map( (filterElement, filterIndex) =>{
                                                                return(
                                                                    <li key={filterIndex}>{(filterElement === "true" || filterElement === "false") ? (filterElement === "true") ? "Ja" : "Nein" : filterElement}</li>
                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        )
                                    }
                                })
                            }
                        </div>
                    </div>
                    <div className="headerMenuElement" style={{display: "flex", justifyContent: "center", flexWrap: "wrap"}}>
                        <h3 style={{display: "block", width: "100%"}}>Export</h3>
                            <span style={{textAlign: "center", lineHeight: 3.5}} className="button downloadSpan" onClick={this.generateCsv}>CSV</span>
                    </div>
                </div> 
                <div style={{display: "flex", justifyContent: "flex-end", alignItems: "center"}}>
                    <PageNavigation 
                        page={this.state.page} 
                        sumPages={this.state.sumPages} 
                        arrowPageClickHandler={this.arrowPageClickHandler}
                        arrowEndPageClickHandler={this.arrowEndPageClickHandler}
                    />
                </div>
                <DataTable 
                    renderType="table"
                    isLoading={this.props.isLoading}
                    tableHeaders={this.state.tableHeaders}
                    tableBody={this.state.tableBody}
                    itemsPerPage={this.state.itemsPerPage}
                    page={this.state.page}
                    translationTable={this.translationTable}
                    sorted={this.state.sorted}
                    sortingFunctions={this.sortingFunctions}
                    />
                <PageNavigation 
                    page={this.state.page} 
                    sumPages={this.state.sumPages} 
                    arrowPageClickHandler={this.arrowPageClickHandler}
                    arrowEndPageClickHandler={this.arrowEndPageClickHandler}
                />
            </div>
        )
    }
}

class DataTable extends React.Component{
    constructor(props){
        super(props);
        this.rowClickHandler = this.rowClickHandler.bind(this);
    }
    rowClickHandler(evt){
        let oldSelected = document.querySelector("#rowSelected");
        if(oldSelected){
            oldSelected.id = "";
        }
        let el = evt.target;
        while(el.tagName !== "TR"){
            el = el.parentElement;
        }
        if(oldSelected === el){
            oldSelected.id = "";
        }
        else{
            el.id = "rowSelected"
        }
    }
    render(){
        // Wie bekomme ich den tableBody von hinten nach vorne Sortiert?
        let sorted = this.props.tableBody;
        for(let i = this.props.tableHeaders.length-1; i >= 0; i--){
            sorted = helper.sortNested(sorted, this.props.tableHeaders[i]);
        }
        return(
            <div className="noSelect" style={{width: "100%", margin: "0 auto"}}>
                {(!this.props.isLoading) && 
                <div>
                    <table style={{tableLayout: "fixed", borderSpacing: 0}}>
                        <thead>
                            <tr>
                                { this.props.tableHeaders.map( (x,i) =>{
                                        return(
                                            <th key={i}>{x[0].toUpperCase()+x.substring(1)}</th>
                                        )
                                    })
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {sorted.map((dataElement, dataIndex) =>{
                                if((dataIndex === 0 && this.props.page === 1) ||
                                    (dataIndex > this.props.itemsPerPage*(this.props.page-1) && 
                                    dataIndex <= this.props.itemsPerPage*this.props.page)
                                    ){
                                    return(
                                        <tr onClick={this.rowClickHandler} key={dataIndex}>
                                            {this.props.tableHeaders.map( (headerElement,headerIndex) =>{
                                                if(headerElement === "slotgrößen"){
                                                    return(
                                                        <td key={headerIndex}>
                                                            <div className="größen" style={{display: "flex", flexWrap: "wrap",alignItems: "center",alignContent: "center", userSelect: "text"}}>{helper.sortSlotSizes(dataElement.slotgrößen).map( (l, li) => {
                                                        return(<div key={li} className="size tag">{l}</div>)
                                                        })}</div>
                                                        </td>
                                                    );
                                                }
                                                else{
                                                    return(
                                                        <td key={headerIndex}>{helper.capitalize(dataElement[headerElement])}</td>
                                                    )
                                                }
                                            })}
                                        </tr>
                                    )
                                }
                            })
                            }
                        </tbody>
                    </table>
                </div>
                }
                {(this.props.isLoading) &&
                    <div style={{width: "100%", margin: "0 auto"}}>
                        <table>
                            <thead>
                                <tr>

                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <Loader />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                }
            </div>
        )
    }
}

class PageNavigation extends React.Component{

    render(){
        return(
            <div className="noSelect" style={{height: 100, display: "flex", justifyContent: "flex-end", alignItems: "center"}}>
                <div>{"Seite " + this.props.page + " von " + this.props.sumPages}</div>
                <div onClick={this.props.arrowEndPageClickHandler} style={{marginLeft: 20, cursor: "pointer"}}><i id="arrowFirstPage" className="material-icons">first_page</i></div>
                <div onClick={this.props.arrowPageClickHandler} style={{cursor: "pointer"}}><i id="arrowPrevPage" className="material-icons">chevron_left</i></div>
                <div onClick={this.props.arrowPageClickHandler} style={{cursor: "pointer"}}><i id="arrowNextPage" className="material-icons">chevron_right</i></div>
                <div onClick={this.props.arrowEndPageClickHandler} style={{cursor: "pointer"}}><i id="arrowLastPage" className="material-icons">last_page</i></div>
            </div>
        )
    }
}

export default AbfrageReport;