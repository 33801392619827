import React from "react";
import helper from "./helper.js";
import Loader from "./Loader.js";

class InputDiv extends React.Component{
    constructor(props){
        super(props);
    }
    render(){
        let headline = this.props.listName[0].toUpperCase() + this.props.listName.substring(1);
        let disabledCheck = !(this.props.active) ? "disabled" : "";
        let buttonClass = (!this.props.active) ? "button button_deactivated": "button";
        return(
            <div className={"inputDiv noSelect"} id={"inputDiv_" + this.props.listName} >
                <span>{headline}</span>
                <UList 
                    values={this.props.values} 
                    listName={this.props.listName}
                    activeHandler={this.props.activeHandler} 
                    index={this.props.index}
                    active={this.props.active}
                    setPlanungState = {this.props.setPlanungState}
                    selectedItems = {this.props.selectedItems}
                    addNewItemActive = {this.props.addNewItemActive}
                    addNewItemIndex = {this.props.addNewItemIndex}
                >
                    {this.props.children}
                </UList>
                { (this.props.permission < 3) &&
                    <AddNewInput 
                        active = {this.props.active}
                        newItemInput = {this.props.newItemInput}
                        index = {this.props.index}
                        disabledCheck = {disabledCheck}
                        buttonClass = {buttonClass}
                        addNewItemActive = {this.props.addNewItemActive}
                        addNewItemIndex = {this.props.addNewItemIndex}
                        addNewItem = {this.props.addNewItem}
                        newItemInputHandleChange= {this.props.newItemInputHandleChange}
                        newItemInputValue= {this.props.newItemInputValue}
                        schemaBluePrints = {this.props.schemaBluePrints}
                        loadingBluePrints = {this.props.loadingBluePrints}
                        schemaHandleChange = {this.props.schemaHandleChange}
                    />
                }
            </div>
        )
    }
}
InputDiv.defaultProps = {
    values: [],
}


class UList extends React.Component{
    constructor(props){
        super(props);
        this.clickHandler = this.clickHandler.bind(this);
        this.setSelected = this.setSelected.bind(this);
        this.listItems = [];
    }
    setSelected(element){
        let selected_before = this.props.selectedItems[this.props.listName].element;
        let c = {...this.props.selectedItems};
        c[this.props.listName].element = element;
        c[this.props.listName].name = element.innerText;
        console.log(c);
        this.props.setPlanungState({
            selectedItems: c,
        });
        if(selected_before){
            selected_before.classList.remove("selectedItem");
        } 
        c[this.props.listName].element.classList.add("selectedItem");
    }
    clickHandler(event){
        let c = (event.target.tagName === "SPAN") ? event.target.parentNode : event.target;
        this.setSelected(c);
        this.props.activeHandler(this.props.index);
    }
    render(){
        let className = (!this.props.active) ? "selectField selectField_deactivated" : "selectField";
        let c = this.props.values;
        let style = {height: (this.props.addNewItemActive && this.props.addNewItemIndex === Number(this.props.index)) ? 300 : 500}
        return(
            <div style={style} className={className}>
                {c.sort().map((value, index) =>{
                    return (
                        <ListItem 
                            index={index} 
                            listName={this.props.listName} 
                            clickHandler={this.clickHandler} 
                            val={value} 
                            key={index}
                            />
                        )
                })}
                {this.props.children}
            </div>
        )
    }
}
class AddNewInput extends React.Component{
    constructor(props){
        super(props);
        this.newItemInputWrapper = this.newItemInputWrapper.bind(this);
    }
    newItemInputWrapper(){
        if(this.props.addNewItemActive){
            this.props.newItemInput(null);
        }
        else{
            this.props.newItemInput(this.props.index);
        }
    }
    render(){
        let active = this.props.addNewItemActive && this.props.addNewItemIndex === Number(this.props.index);
        return(
            <div>
                {(active && this.props.loadingBluePrints) && 
                    <Loader
                        scale={0.5}
                    />
                }
                {(active && !this.props.loadingBluePrints) &&
                    <div style={{marginTop: 10, display: "flex", justifyContent: "center", alignItems: "flex-start", flexWrap: "wrap"}}> 
                        <select onChange={this.props.schemaHandleChange} id="selectSchema" style={{width: "85%", height: 30, fontSize: 18, background: "white", margin: "10px 0"}}>
                            <option value="-1">Kein Schema verwenden</option>
                            {this.props.schemaBluePrints && this.props.schemaBluePrints.map((el, index) => {
                                return(<option value={index} key={index}>{el.idString}</option>)
                            })}
                        </select>
                        <input value={this.props.newItemInputValue} onChange={this.props.newItemInputHandleChange} style={{width: "85%", height: 30, textAlign: "center", background: "white", fontSize: 18}}></input>
                        <div style={{display: "flex", justifyContent: "center", alignItems:"flex-start"}}>
                            <button className="button" style={{marginTop: 10}} onClick={this.props.addNewItem}>Speichern</button>
                            <button className="button" style={{marginTop: 10}} onClick={this.newItemInputWrapper}>Abbrechen</button>
                        </div>
                    </div>
                }
                {(Number(this.props.index) > 1 && this.props.active && !active) &&
                    <button className="button" style={{marginTop: 10}} onClick={this.newItemInputWrapper}>Neu Hinzufügen</button>
                }
                { (Number(this.props.index) > 1 && !this.props.active && !active ) &&
                    <button disabled={this.props.disabledCheck} className={this.props.buttonClass} style={{marginTop: 10}}>Neu Hinzufügen</button>
                }
            </div>
        )
    }
}

class ListItem extends React.Component{
    render(){
        return(
            <div id={"listItem_"+ this.props.listName + "_" + this.props.index} onClick={this.props.clickHandler} className={"selectItem"}>
                <span >{helper.capitalize(this.props.val)}</span>
            </div>
        )
    }
}


export default InputDiv;