"use strict";
/**
 * the class
 */
function IqdAdPositionComment(posComment, userId) {
    console.log('// userId _ posComment ', userId, ' _ ', posComment);
// create timestamp
// sanitize comment text
// set item object
    var _ts = Math.floor(new Date())
        ,_userId = userId
        ,_tmpText = sanitizeText(posComment);
    // sanitize comment text
    
    // set item object
    this.item = {
            'creationdate': _ts,
            'owner': _userId,
            'text': _tmpText
    }
};
function sanitizeText(str) {
    //#
    console.log('sanitize this: ', str);
    var _str = str + ''
        ,_str = btoa(_str);
    // btoa is fake sanitizing
    // regex - 
    // script-tag - /<script[^>]*>([\\S\\s]*?)<\/script>/img
    // eval call - 
    // bg-img-url : document.body.style = "background-image(url:"+alert('hi again');+");";
    return _str;
};

export default IqdAdPositionComment;