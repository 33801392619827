import React from "react";
import Loader from "./Loader.js";

class CanvasMainMenuInfo extends React.Component{
    constructor(props){
        super();
    }
    render(){
        return(
            <div id="CanvasMainMenuInfo" style={{display: "flex", alignItems: "center", margin: "10px 0px"}}>
                <SiteInfo 
                    mandant = {this.props.mandant} 
                    endgerät = {this.props.endgerät} 
                    status = {this.props.status} 
                    layout = {this.props.layout} 
                />
            </div>
        )
    }
}

class SiteInfo extends React.Component{
    render(){
        return(
            <div style={{display: "flex", justifyContent: "flex-start", alignItems: "center"}}>
                <div style={{
                        width: "30%",
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems:"center",
                        flexWrap: "wrap",
                        marginLeft: 20,
                    }}>
                    <div style={{width: "100%"}} className="div_100">
                        <div><span>Mandant: </span></div>
                    </div>
                    <div style={{width: "100%"}} className="div_100">
                        <div><span>Endgerät: </span></div>
                    </div>
                    <div style={{width: "100%"}} className="div_100">
                        <div><span>Status: </span></div>
                    </div>
                    <div style={{width: "100%"}} className="div_100">
                        <div><span>Layout: </span></div>
                    </div>
                </div>
                <div style={{
                        width: "40%",
                        minWidth: 140,
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems:"center",
                        flexWrap: "wrap",
                        fontWeight: "700",
                        marginLeft: 10,
                    }}>
                    <div style={{width: "100%"}} className="div_100">
                        <div><span>{this.props.mandant}</span></div>
                    </div>
                    <div style={{width: "100%"}} className="div_100">
                        <div><span>{this.props.endgerät}</span></div>
                    </div>
                    <div style={{width: "100%"}} className="div_100">
                        <div><span>{this.props.status}</span></div>
                    </div>
                    <div style={{width: "100%"}} className="div_100">
                        <div><span>{this.props.layout}</span></div>
                    </div>
                </div>
            </div>
        )
    }
}

export default CanvasMainMenuInfo;