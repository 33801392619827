import React from "react";
import { Link } from "react-router-dom";
import Headline from "./Headline.js"
import "./style.css"
import "./Admin.css"

class Admin extends React.Component{
    constructor(props){
        super(props);
    }
    render(){
        return(
            <div className="whiteWrapper" id="adminComponent">
                <Headline breadcrumb="data" headline="Auswahl"/>
                <div id="admin_buttonWrapper">
                    { this.props.permission === 1 &&
                        <Link 
                            to={{pathname: "/data/user"}}
                            className="adminLink" 
                        >
                            <ChoiceButton 
                                icon = "face"  
                                text = "Userverwaltung"
                            />
                        </Link>
                    }
                    <Link 
                        to={{pathname: "/data/mandanten"}}
                        className="adminLink" 
                    >
                        <ChoiceButton 
                            icon = "import_contacts"  
                            text = "Planungsverwaltung"
                        />
                    </Link>
                    <Link 
                        to={{pathname: "/data/daten"}}
                        className="adminLink" 
                    >
                        <ChoiceButton 
                            icon = "storage"
                            text = "Datenverwaltung"
                        />
                    </Link>
                    <Link 
                        to={{pathname: "/data/tms"}}
                        className="adminLink" 
                    >
                        <ChoiceButton 
                            icon = "import_export"
                            text = "TMS Anbindung"
                        />
                    </Link>
                </div>
            </div>
        )
    }
}

class ChoiceButton extends React.Component{
    render(){
        return(
            <div className="admin_choiceButton">
                <div><i className="material-icons">{this.props.icon}</i></div>
                <div><span>{this.props.text}</span></div>
            </div>
        )
    }
}

export default Admin;