import React from "react";
import "./NiceSelect.css";

class NiceSelect extends React.Component{
    constructor(props){
        super(props);
        this.onChangeWrapper = this.onChangeWrapper.bind(this);
    }
    onChangeWrapper(evt){
        this.props.onChangeHandler(evt.target.value)
    }
    render(){
        let selectStyle = (this.props.button) ? {gridColumn: "2/3"} : {gridColumn: "2/4"};
        if(!this.props.isLoading){
            return(
                <div className="niceSelect">
                    <div className="labelWrapper">
                        <span className="label">{this.props.label}</span>
                    </div>
                    <div className="selectWrapper" style={selectStyle}>
                        <select value={this.props.selected} onChange={this.onChangeWrapper}>
                            <option value={"Auswählen"}>Auswählen</option>
                            {(this.props.valueKey && this.props.displayKey) && 
                                this.props.values.sort((a,b) =>{
                                    if(a.name < b.name){
                                        return -1;
                                    }
                                    else if(a.name > b.name){
                                        return 1;
                                    }
                                    else{
                                        return 0;
                                    }
                                }).map((el,i) =>{
                                    return(
                                        <option key={i} value={el[this.props.valueKey]}>{el[this.props.displayKey]}</option>
                                    )
                                })
                            }
                            {(!this.props.valueKey || !this.props.displayKey) &&
                                this.props.values.map((el,i) =>{
                                    let isObject = (typeof el === "object")
                                    let keys = (isObject) ? Object.keys(el) : null;
                                    return(
                                        <option key={i} value={(typeof el === "object") ? keys.reduce((sum, curr) => sum+= el[curr] + " - ", "") : el}>{(typeof el === "object") ? keys.reduce((sum, curr) => sum+= el[curr] + " - ", "") : el}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                    {this.props.button &&
                        <div>
                            <button onClick={this.props.buttonClickHandler} id="selectButton" className="button">{this.props.buttonText}</button>
                        </div> 
                    }
                </div> 
            )
        }
        else{
            return(
                <div className="niceSelect">
                    <div className="labelWrapper">
                        <span className="label">...</span>
                    </div>
                    <div className="selectWrapper">
                        <div className="isLoading"></div>
                    </div>
                </div> 
            )
        }
    }
}

export default NiceSelect;