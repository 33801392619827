import React from "react";
import { Link } from "react-router-dom";
import Headline from "./Headline.js"
import "./style.css"
// import "./AdminTMS.css"

class AdminTMS extends React.Component{
    constructor(props){
        super(props);
    }
    render(){
        return(
            <div className="whiteWrapper" id="adminComponent">
                <Headline breadcrumb="TMS Anbindung" headline="Auswahl"/>
                <div id="admin_buttonWrapper">
                    <Link 
                        to={{pathname: "/data/import"}}
                        className="adminLink" 
                    >
                        <ChoiceButton 
                            icon = "archive"
                            text = "Mandant einzeln importieren"
                        />
                    </Link>
                    <Link 
                        to={{pathname: "/data/schedule"}}
                        className="adminLink" 
                    >
                        <ChoiceButton 
                            icon = "schedule"
                            text = "Automatisierter Import"
                        />
                    </Link>
                </div>
            </div>
        )
    }
}

class ChoiceButton extends React.Component{
    render(){
        return(
            <div className="admin_choiceButton">
                <div><i className="material-icons">{this.props.icon}</i></div>
                <div><span>{this.props.text}</span></div>
            </div>
        )
    }
}

export default AdminTMS;