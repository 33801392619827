import React from "react";
import "./UserCard.css"
import "./UserCirle.js"
import UserCircle from "./UserCirle.js";

class UserCard extends React.Component{
    constructor(props){
        super(props);
        this.permissionOnChangeHandler = this.permissionOnChangeHandler.bind(this);
        this.convertPermission = this.convertPermission.bind(this);
    }
    permissionOnChangeHandler(evt){
        console.log(evt);
        this.props.selectPermission(evt.target.value);
    }
    convertPermission(){
        switch(this.props.selectedUser.permission){
            case 1:
                return "Admin";
            case 2:
                return "Planer";
            case 3:
                return "Nutzer";
            default:
                return "Error";
        }
    }
    render(){
        let convertedName = this.props.selectedUser.name.split(" ").map( (el) =>{return el[0].toUpperCase()+el.substring(1)+ " "});
        let convertedDepartment = this.props.selectedUser.department.split(" ").map( (el) =>{return el[0].toUpperCase()+el.substring(1) + " "}); 
        return(
            <div id="userCard">
                <UserCircle
                    name={this.props.selectedUser.name}
                />
                <hr className="userCardHr"/>
                <div id="cardItemName" className="cardItem">
                    <span>{convertedName}</span>
                </div>
                <div className="cardItem">
                    <span>{this.props.selectedUser.email}</span>
                </div>
                <div className="cardItem">
                    <span>{convertedDepartment}</span>
                </div>
                {this.props.activatedFolder === 1 &&
                <div className="cardItem">
                    <span>{this.convertPermission()}</span>
                </div>
                }
                {this.props.clickedAccept &&
                    <div style={{marginTop: 20}}>
                        <div className="cardItem">
                            <select value={this.props.selectedPermission} onChange={this.permissionOnChangeHandler} id="permissionSelect" className={(this.props.noPermissionError) ? "noPermissionError" : ""}>
                                <option value={0}>Berechtigung wählen</option>
                                <option value={3}>Nutzer</option>
                                <option value={2}>Planer</option>
                                <option value={1}>Admin</option>
                            </select>
                        </div>
                        <div className="cardItem" style={{display: "flex", justifyContent: "center", alignItems: "center", flexWrap: "wrap", marginTop: 10}}>
                            <div id="cardItemButtons" style={{marginTop: 5, width:"100%", display: "flex", justifyContent: "center"}}>
                                <button onClick={this.props.acceptUserClickHandler} id="cardItemButtonAccept">Hinzufügen</button>
                            </div>
                            <div >
                                <span onClick={this.props.toggleAccept} id="declineSpan">Abbrechen</span>
                            </div>
                        </div>
                    </div>
                }
                {(!this.props.clickedAccept && this.props.activatedFolder === 0) && 
                    <div id="cardItemButtons"className="cardItem">
                        <button onClick={this.props.toggleAccept} id="cardItemButtonAccept">Akzeptieren</button>
                        <button onClick={this.props.declineUserClickHandler} id="cardItemButtonDecline">Ablehnen</button>
                    </div>
                }
                {(!this.props.clickedAccept && this.props.activatedFolder === 1) && 
                    <div id="cardItemButtons"className="cardItem">
                        <button onClick={this.props.deleteUserClickHandler} id="cardItemButtonDecline">Löschen</button>
                    </div>
                }
            </div>
        )
    }
}
export default UserCard;