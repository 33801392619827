import React from "react";
import helper from "./helper.js"
import Loader from "./Loader.js";
import {Redirect} from "react-router-dom";

class AbfrageNeu extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            availableDimensions: ["mandant","endgerät","status","layout","iqadtile","slotgrößen"],
            pickedDimension: [],
            elements: (this.props.elements) ? this.props.elements : [new Dimension("select")],
            reportName: "",
            reportId: (this.props.report) ? this.props.report._id : null,
            loadedSavedReport: !((this.props.report || {})._id),
            openFilterDropdown: null,
            filterCache: {},
            clickedFilter: false,
            clickedSave: false,
            currentlySaving: false,
            reportSaved: false,
            runWithoutSaving: false,
            showSubmitWarning: false,
            editFlags: (this.props.report) ? this.props.report.elements.map( (el)=>{return (el.filtered)}) : null,
        }
        this.addClickHandler = this.addClickHandler.bind(this);
        this.dropdownChangeHandler = this.dropdownChangeHandler.bind(this);
        this.deleteClickHandler = this.deleteClickHandler.bind(this);
        this.eventListenerCallbackSave = this.eventListenerCallbackSave.bind(this);
        this.eventListenerCallbackFilter = this.eventListenerCallbackFilter.bind(this);
        this.eventListenerCallbackPropagation = this.eventListenerCallbackPropagation.bind(this);
        this.runClickHandler = this.runClickHandler.bind(this);
        this.saveAndRunClickHandler = this.saveAndRunClickHandler.bind(this);
        this.saveAndRunInputHandler = this.saveAndRunInputHandler.bind(this);
        this.saveAndRunSubmitHandler = this.saveAndRunSubmitHandler.bind(this);
        this.saveAndRunCancelHandler = this.saveAndRunCancelHandler.bind(this);
        this.filterClickHandler = this.filterClickHandler.bind(this);
        this.filterElementClickHandler = this.filterElementClickHandler.bind(this);
        this.generateAvailableDimensions = this.generateAvailableDimensions.bind(this);
        console.log(this);
    }
    eventListenerCallbackSave(e){
        this.setState({
            clickedSave: false,
        })
    }
    eventListenerCallbackFilter(e){
        this.setState({
            clickedFilter: false,
            openFilterDropdown: null,
        })
    }
    eventListenerCallbackPropagation(e){
        e.stopPropagation();
    }
    addClickHandler(){
        let c = this.state.elements;
        c.push(new Dimension("select"));
        this.setState({
            elements: c,
        })
    }
    dropdownChangeHandler(id, selectionString){
        let c = this.state.elements;
        let cc = this.state.filterCache;
        c[id] = new Dimension((selectionString === "Auswählen") ? "select" : selectionString);
        cc[this.state.elements[id].name] = undefined;
        this.setState({
            elements: c,
            filterCache: cc,
            clickedFilter: false,
        })
    }
    deleteClickHandler(id){
        let c = this.state.elements;
        let cc = this.state.filterCache;
        cc[this.state.elements[id].name] = undefined;
        c.splice(id,1);
        this.setState({
            elements: c,
            filterCache: cc,
        })
    }
    filterClickHandler(id, filterMetric){
        if(this.state.elements[id] !== undefined && this.state.elements[id].name !== "select"){
            this.setState({
                openFilterDropdown: id,
                clickedFilter: true,
            });
            this.props.toggleLoadingState(true);
            if(this.state.filterCache[filterMetric] !== undefined){
                this.props.toggleLoadingState(false);
            }
            else{
                let url = window.api + "/abfrage/neu?key=" + filterMetric.toLowerCase();
                this.state.elements.forEach((el) =>{
                // einmal über alle elemente loopen und die queryStrings richtig anhängen
                    if(el.name !== "select" && el.name !== filterMetric){
                        if(el.filtered){
                            url += "&"+el.name+"=";
                            el.setFilters.forEach((l,i) =>{
                                if(i !== 0 && l[1]){
                                    if(url[url.length-1] !== "="){
                                        url += ",";
                                    }
                                    url += l[0].toLowerCase();
                                }
                            })
                        }
                    }
                });
                let request = this.props._get(url);
                request.then( (data) => {
                    let obj = this.state.filterCache;
                    let c = this.state.elements;
                    let editC = this.state.editFlags;
                    obj[filterMetric] = JSON.parse(data).map((el) => helper.capitalize(el)).sort( (a,b) => {
                        let stringA = a.toUpperCase();
                        let stringB = b.toUpperCase();
                        if(stringA < stringB){
                            return -1;
                        }
                        if(stringA > stringB){
                            return 1;
                        }
                        return 0;

                    });
                    if(this.state.editFlags){
                        let all = true;
                        c[id].setFilters = obj[filterMetric].map( (el) => {
                            let elementsLength = this.state.elements[id].setFilters.length;
                            for(let i=0; i < elementsLength; i++){
                                if(el === this.state.elements[id].setFilters[i][0]){
                                    all = false;
                                    editC[id] = false;
                                    return [el, true];
                                }
                            }
                            return [el, false];
                        });
                        c[id].setFilters.unshift(["Alle", all]);
                    }
                    else{
                        c[id].setFilters = obj[filterMetric].map( (el) => [helper.capitalize(el), true]);
                        c[id].setFilters.unshift(["Alle", true]);
                    }
                    this.setState({
                        filterCache: obj,
                        elements: c,
                        editFlags: editC,
                    });
                    this.props.toggleLoadingState(false);
                }).catch((err) => {
                    console.error(err);
                })
            }
        }
    }
    filterElementClickHandler(idName, index){
        let id = (idName === "filterElementSelectAll") ? 0 : Number(idName.match(/\d+/));
        let c = Object.assign(this.state.elements);
        if(id === 0){
            let bool = c[index].setFilters[0][1];
            c[index].setFilters = c[index].setFilters.map( (el, i) =>{
                return[el[0], !bool];
            });
        }
        else{
            c[index].setFilters[id][1] = !c[index].setFilters[id][1];
        }
        c[index].filtered = false;
        for(let i = 1; i < c[index].setFilters.length; i++){
            if(!c[index].setFilters[i][1]){
                c[index].filtered = true;
                c[index].setFilters[0][1] = false;
                break;
            }
        }
        if(!c[index].filtered){
            c[index].setFilters[0][1] = true;
        }
        this.setState({
            elements: c,
        });
    }
    runClickHandler(){
        if(this.checkIfAllowedToSubmit()){
            this.setState({
                reportSaved: true,
                runWithoutSaving: true,
            })
        }
        else{
            this.setState({
                showSubmitWarning: true,
            })
        }
    }
    saveAndRunClickHandler(){
        if(this.checkIfAllowedToSubmit()){
            this.setState({
                clickedSave: true
            });
        }
        else{
            this.setState({
                showSubmitWarning: true,
            })
        }
    }
    saveAndRunInputHandler(evt){
        let c  = evt.target.value;
        this.setState({
            reportName: c,
        })
    }
    saveAndRunCancelHandler(evt){
        this.setState({
            clickedSave: false,
        })
    }
    checkIfAllowedToSubmit(){
        let countSelect = this.state.elements.reduce( (acc, curr) =>{
            if(curr.name === "select") acc+=1;
            console.log("name: "+curr.name + "\r\n" + "acc: " + acc);
            return acc;
        }, 0);
        console.log("countSelect" + countSelect);
        return !(countSelect > 0);
    }
    saveAndRunSubmitHandler(evt){
        this.setState({
            currentlySaving: true,
        });
        let c = {
            elements: this.state.elements,
            reportName: this.state.reportName,
        }
        if(this.state.reportId){
            c["reportId"] = this.state.reportId;
        }
        c = JSON.stringify(c);
        let request = this.props._post(window.api + "/abfrage/neu/save", c, true);
        request.then( (data) =>{
            this.setState({
                currentlySaving: false,
                reportSaved: true,
            });
        })
    }
    componentDidMount(){
        if(this.state.reportId){
            this.props._get(window.api + "/abfrage/?id=" + this.state.reportId)
            .then((data) =>{
                data = JSON.parse(data);
                this.setState({
                    reportName: data[0].name,
                    elements: data[0].elements,
                    loadedSavedReport: true
                });
            })
        }
        else{
            this.props.setUrlHandler("AbfrageNeu")
        }
    }
    generateAvailableDimensions(index){
        return this.state.availableDimensions.filter((el) =>{
            for(let i = 0; i < index; i++){
                if(this.state.elements[i].name === el){
                    return false;
                }
            }
            return true;
        })
    }
    render(){
        if(this.state.reportSaved){
            return(
                <Redirect
                    to={{
                        pathname: "./report",
                        // TODO: wenn wir ein Reporting nur generieren und nicht speichern & generieren sollten wir auf Basis dieses States den reportstate hier null setzen
                        state:{
                            reportName: this.state.reportName, 
                            report: (this.state.runWithoutSaving) ? null : (this.props.report) ? this.props.report : null, 
                            elements: this.state.elements, 
                            reportId: this.state.reportId
                        }
                    }}
                />
            )
        }
        if(!this.state.loadedSavedReport){
            return(
                <div>
                    <Loader marginTop={150}/>
                </div>
            )
        }
        else{
            return(
                <div style={{display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gridTemplateRow: "1fr",justifyContent: "center", alignItems: "center"}}>
                    <div id="abfrageNeuWrapper">
                        <div>
                            <button className="button" onClick={this.addClickHandler}>
                                <i className="material-icons">add</i>
                            </button>
                        </div>
                        <div style={{display: "flex", justifyContent: "center", alignItems: "center", flexWrap: "wrap"}}>
                            {
                                this.state.elements.map((x,i) => {
                                    if(i === 0){
                                        return(
                                            <DimensionSelection 
                                                label="Top Level Dimension"
                                                className="dimWrapper tld"
                                                id={0}
                                                value={this.state.elements[i].name}
                                                elements={this.state.elements}
                                                availableDimensions={this.state.availableDimensions}
                                                filterCache = {this.state.filterCache}
                                                openFilterDropdown = {this.state.openFilterDropdown}
                                                clickedFilter = {this.state.clickedFilter}
                                                toggleGui = {this.props.toggleGui}
                                                mountHandler={this.mountHandler}
                                                deleteClickHandler={this.deleteClickHandler}
                                                dropdownChangeHandler={this.dropdownChangeHandler}
                                                filterClickHandler={this.filterClickHandler}
                                                key={i}
                                                eventListenerCallbackFilter={this.eventListenerCallbackFilter}
                                            />
                                        )
                                    }
                                    else{
                                        return(
                                            <DimensionSelection 
                                                label={"Dimension "+(i+1)}
                                                value={this.state.elements[i].name}
                                                elements={this.state.elements}
                                                className="dimWrapper"
                                                id={i}
                                                availableDimensions={this.generateAvailableDimensions(i)}
                                                filterCache = {this.state.filterCache}
                                                openFilterDropdown = {this.state.openFilterDropdown}
                                                clickedFilter = {this.state.clickedFilter}
                                                toggleGui = {this.props.toggleGui}
                                                mountHandler={this.mountHandler}
                                                deleteClickHandler={this.deleteClickHandler}
                                                dropdownChangeHandler={this.dropdownChangeHandler}
                                                filterClickHandler={this.filterClickHandler}
                                                key={i}
                                                eventListenerCallbackFilter={this.eventListenerCallbackFilter}
                                            />
                                        )
                                    }
                                })
                            }
                        </div>
                    </div>
                    <div id="abfrageNeuButtonWrapper">
                        {
                            (this.state.clickedFilter) &&
                            <FilterDropdown 
                                filterCache={this.state.filterCache}
                                value={this.state.elements[this.state.openFilterDropdown].name}
                                toggleGui = {this.props.toggleGui}
                                index = {this.state.openFilterDropdown}
                                elements={this.state.elements}
                                filterElementClickHandler={this.filterElementClickHandler}
                            />
                        }
                            { (!this.state.clickedSave && !this.state.clickedFilter) && 
                                <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                    <button style={{width: 150}} onClick={this.runClickHandler} className="button">Generieren</button>
                                    <button style={{width: 150}} onClick={this.saveAndRunClickHandler} className="button">Speichern und generieren</button> 
                                    {this.state.showSubmitWarning &&
                                        <span style={{paddingTop: 20, color: "red", display: "block"}}>Bitte wähle für alle Dimensionen einen Wert aus!</span>
                                    }
                                </div>
                            }
                            { (this.state.clickedSave && !this.state.clickedFilter) && 
                                <div className="divNotClickable" style={{textAlign: "left"}}>
                                    <i onClick={this.saveAndRunCancelHandler} style={{display: "block", marginBottom: 10, marginleft: "-5px", cursor: "pointer"}}className="material-icons">keyboard_backspace</i> 
                                    <span className="divNotClickable">Name des Reports:</span>
                                    <div className="divNotClickable" id="inputButton" >
                                        <input className="divNotClickable" placeholder="Nicht gespeichert" value={this.state.reportName} onChange={this.saveAndRunInputHandler}></input>
                                    </div>
                                    <button onClick={this.saveAndRunSubmitHandler} className="button" style={{zIndex: 10, marginLeft: 0}}>Speichern und generieren</button> 
                                </div>
                            }
                    </div>
                </div>
            )
        }
    }
}

class DimensionSelection extends React.Component{
    constructor(props){
        super(props)
        this.deleteClickHandlerWrapper = this.deleteClickHandlerWrapper.bind(this);
        this.dropdownChangeHandlerWrapper = this.dropdownChangeHandlerWrapper.bind(this);
        this.filterClickHandlerWrapper = this.filterClickHandlerWrapper.bind(this);
    }
    deleteClickHandlerWrapper(){
        this.props.deleteClickHandler(this.props.id);
    }
    filterClickHandlerWrapper(e){
        this.props.filterClickHandler(this.props.id, this.props.value);
    }
    dropdownChangeHandlerWrapper(event){
        this.props.dropdownChangeHandler(this.props.id, event.target.value)
    }
    render(){
        return(
            <div className={this.props.className} id={this.props.id} style={(this.props.clickedFilter && this.props.openFilterDropdown === this.props.id) ? {marginRight: "-80px", transition: "all 400ms"}: {}}>
                <div className="dimLabelName">
                    <span>{this.props.label}</span>
                </div>
                <div className="dimSelect">
                    <select 
                        value={this.props.value}
                        onChange={this.dropdownChangeHandlerWrapper} 
                    >
                        <option>Auswählen</option>
                        {
                            this.props.availableDimensions.map((x, i) =>{
                                return(
                                    <option key={i} value={x}>{x[0].toUpperCase() + x.substring(1)}</option>
                                )
                            })
                        }
                    </select>
                </div>
                <div className="dimEdit">
                    { (this.props.clickedFilter && this.props.openFilterDropdown === this.props.id) ?
                    <i onClick={this.props.eventListenerCallbackFilter} className="material-icons">keyboard_backspace</i> : <i onClick={this.filterClickHandlerWrapper} className={
                        (() => {
                            let c = "material-icons";
                            if(this.props.elements[this.props.id].name === "select"){
                                c += " notSelected";
                            }
                            else{
                                if(this.props.elements[this.props.id].filtered){
                                    c += " filtered";
                                }
                            }
                            return c;
                        })()
                     }>filter_list</i> }
                    {
                        (this.props.className !== "dimWrapper tld") && 
                        <i className="material-icons" onClick={this.deleteClickHandlerWrapper}>delete</i>
                        }
                </div>
            </div>
        )
    }
}

class FilterDropdown extends React.Component{
    constructor(props){
        super(props);
        this.filterElementClickHandlerWrapper = this.filterElementClickHandlerWrapper.bind(this);
    }

    filterElementClickHandlerWrapper(evt){
        let el = (evt.target.tagName === "SPAN") ? evt.target.parentNode : evt.target;
        this.props.filterElementClickHandler(el.id, this.props.index);
    }

    render(){
        return(
            <div className="filterDropdown divNotClickable" style={{marginTop: 2}}>
                <div style={{margin: "25% auto", padding: 10}} className={this.props.toggleGui("cssLoader", "cssLoaderActivated")}></div>
                <div>
                    <ul className="filterElementWrapper">
                    {(this.props.filterCache[this.props.value] !== undefined) &&
                        <li id="filterElementSelectAll" className={(this.props.elements[this.props.index].setFilters[0][1]) ? "checked": ""} onClick={this.filterElementClickHandlerWrapper}>
                            <span style={{fontWeight: "700"}}>Alle</span>
                        </li>
                    }
                    {
                        (this.props.filterCache[this.props.value] !== undefined) && this.props.filterCache[this.props.value].map( (filterElement, filterIndex) =>{
                            console.log();
                            return(
                                <li className={(this.props.elements[this.props.index].setFilters[filterIndex+1][1]) ? "checked": ""} id={"filterElement"+(filterIndex+1)} key={filterIndex+1} onClick={this.filterElementClickHandlerWrapper}>
                                    <span>{(this.props.value !== "aktiviert") ? filterElement : (filterElement === "true") ? "Ja" : "Nein"}</span>
                                </li>
                            )
                        })
                    }
                    </ul>
                </div>
            </div>
        )
    }
}

export default AbfrageNeu;

class Dimension{
    constructor(name){
        this.name = name;
        this.setFilters = [];
        this.filtered = false;
    }
}