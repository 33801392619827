import React from "react";
import "./style.css";
import ModalFolder from "./ModalFolder";
import CommentDialog from "./CommentDialog";
import AddSlotDialog from "./AddSlotDialog";
import SaveThrowDialog from "./SaveThrowDialog";

class DialogModal extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            activeFolder: 0,
        };
        this.folderClickHandler = this.folderClickHandler.bind(this);
    }
    componentDidMount(){
        document.querySelector("body").style.overflow = "hidden";
    }
    componentWillUnmount(){
        document.querySelector("body").style.overflow = "auto";
    }
    folderClickHandler(index){
        this.setState({
            activeFolder: index,
        });
    }
    render(){
        let dimensions = {};
        let folders = {};
        dimensions.save = {w: 500, h: 100};
        dimensions.throw = {w: 500, h: 100};
        dimensions.add = {w: 800, h: 600};
        dimensions.edit = {w: 800, h: 600};
        dimensions.comment = {w: 800, h: 600};
        folders.save = [];
        folders.throw = [];
        folders.add = [];
        folders.edit = [];
        folders.comment = ["Seite", "Adtiles"];
        return(
            <div id="dialogWrapper">
                <div id="folderPlusModal">
                    <FolderWrapper 
                        folders={folders[this.props.dialogType]} 
                        activeFolder = {this.state.activeFolder}
                        folderClickHandler = {this.folderClickHandler}
                    />
                    <div id="dialogModal" 
                        className={(folders[this.props.dialogType].length > 0) ? "borderFolder" : "borderNoFolder"} 
                        style={{width: dimensions[this.props.dialogType].w, height: dimensions[this.props.dialogType].h}}>
                        <div style={{display: "flex", justifyContent: "center", alignItems: "center", borderRadius: 50, position: "absolute", top: 0, right: 0, fontSize: 20, cursor:"pointer"}} onClick={this.props.toggleDialogModal}>
                            <i className="material-icons">close</i>
                        </div>
                        {(this.props.dialogType === "save" || this.props.dialogType === "throw") &&
                            <SaveThrowDialog 
                                dialogType = {this.props.dialogType}
                                toggleDialogModal ={this.props.toggleDialogModal}
                                saveConfig = {this.props.saveConfig}
                                throwConfig = {this.props.throwConfig}
                            />
                        }
                        {(this.props.dialogType === "add") &&
                            <AddSlotDialog
                                toggleDialogModal ={this.props.toggleDialogModal}
                                addAdtile = {this.props.addAdtile}
                                mode = "add"
                                schemaAdtiles = {this.props.schemaAdtiles}
                                schemaSizes = {this.props.schemaSizes}
                            />
                        }
                        {(this.props.dialogType === "edit") &&
                            <AddSlotDialog
                                toggleDialogModal ={this.props.toggleDialogModal}
                                addAdtile = {this.props.addAdtile}
                                schemaAdtiles = {this.props.schemaAdtiles}
                                schemaSizes = {this.props.schemaSizes}
                                mode = "edit"
                            />
                        }
                        {(this.props.dialogType === "comment") &&
                            <CommentDialog 
                                topLevelComments = {this.props.topLevelComments} 
                                activeFolder = {this.state.activeFolder}
                                mandant = {this.props.mandant}
                                endgerät = {this.props.endgerät}
                                status = {this.props.status}
                                layout = {this.props.layout}
                                addComment = {this.props.addComment}
                                addSiteComment = {this.props.addSiteComment}
                                permission = {this.props.permission}
                            />
                        }
                    </div>
                </div>
            </div>
        )
    }
}
class FolderWrapper extends React.Component{
    render(){
        return(
            <div id="folderWrapper">
                {this.props.folders.map((el,index) => {
                        return(
                            <ModalFolder 
                                index = {index}
                                headline = {el}
                                key = {index} 
                                activeFolder = {this.props.activeFolder}
                                folderClickHandler = {this.props.folderClickHandler}
                            />
                        )
                    })

                }
            </div>
        )
    }
}

export default DialogModal;