import React from 'react';
import {Link} from "react-router-dom";

import "./Headline.css"

// const infoTexts = {
//     Planung: "In diesem Bereich kannst du Layouts von Mandanten neu anlegen, bearbeiten oder z.B. eine Wunschvertaggung als Planung anlegen. Wähle dafür einfach den gewünschten Mandanten, das Endgerät, den Status und das Layout aus und klicke auf \"Layout bearbeiten\". "
// }

class Headline extends React.Component{
    render(){
        let breadcrumb = (this.props.breadcrumb.length > 0) ? <span className="breadcrumb">{this.props.breadcrumb[0].toUpperCase() + this.props.breadcrumb.substring(1)}</span> : "";
        let info = this.props.info || null;
        let other = (this.props.other) ? this.props.other.reduce((sum, curr) => sum += " > "+ curr, "") : undefined;
        return(
            <div id="headlineWrapper" style={{background: this.props.background || "white"}}>
                <div id="h1Wrapper">
                    <h1><Link style={{textDecoration: "none", cursor: "pointer"}} to={"/" + this.props.breadcrumb}>{breadcrumb}</Link> > {(other) ? this.props.headline + other : this.props.headline}</h1>
                </div>
                { info && 
                    <div id="spanWrapper">
                        <span title="Hier steht irgendwann mal ein Info Text" className="infoButton">info</span>
                    </div>
                }
                {/* <div id="infoModal">
                    <div id="infoContentWrapper">
                        <h2>Info {this.props.headline}</h2>
                        <p>{infoTexts[this.props.headline]}</p>
                    </div>
                </div> */}
            </div>
        )
    }
}

Headline.defaultProps = {
    breadcrumb: "",
}

export default Headline;