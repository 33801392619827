import React from "react";
import { Link } from "react-router-dom";

import "./AdminMandanten.css";
import Headline from "./Headline.js";
import NeutralInputDiv from "./NeutralInputDiv.js";
import helper from "./helper.js"
import Loader from "./Loader.js"

class AdminMandanten extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            titles: ["mandant", "endgerät", "status", "layout"],
            dataMode: true,
            newItemMode: false,
            newItemInput: "",
            newItemLoading: false,
            currentTable: 0,
            data: {
                mandant: [],
                endgerät: [],
                status: [],
                layout: [],
            },
            schema: {
                mandant: [],
                endgerät: [],
                status: [],
                layout: [],
            },
            selectedIndex: {
                mandant: null,
                endgerät: null, 
                status: null,
                layout: null,
            },
            schemaBluePrints: [],
            schemaBluePrintSelected: -1,
            fetchingDatafinished: false,
        }
        this.selectClickHandler = this.selectClickHandler.bind(this);
        this.deleteClickHandler = this.deleteClickHandler.bind(this);
        this.toggleDataMode = this.toggleDataMode.bind(this);
        this.clickForward = this.clickForward.bind(this);
        this.clickBackward = this.clickBackward.bind(this);
        this.newItemOnChangeHandler = this.newItemOnChangeHandler.bind(this);
        this.openNewItemMode = this.openNewItemMode.bind(this);
        this.closeNewItemMode = this.closeNewItemMode.bind(this);
        this.addNewItem = this.addNewItem.bind(this);
        this.schemaSelectOnChangeHandler = this.schemaSelectOnChangeHandler.bind(this);
    }
    componentDidMount(){
        this.fetchData();
    }
    toggleDataMode(){
        let state = {
            selectedIndex: {
                mandant: null,
                endgerät: null, 
                status: null,
                layout: null,
            },
            dataMode: !this.state.dataMode,
        }
        this.fetchData((-1*this.state.currentTable), state);
    }
    openNewItemMode(){
        this.setState({
            newItemLoading: true,
            newItemMode: true,
        });
        this.fetchSchema()
        .then((data) =>{
            this.setState({
                newItemLoading: false,
                schemaBluePrints: data,
            });
        }).catch((err) =>{
            console.error(err);
        })
    }
    closeNewItemMode(){
        this.setState({
            newItemMode: false,
        })
    }
    fetchData(offset=0, state={}){
        this.setState({
            fetchingDatafinished: false,
            newItemMode: false,
            newItemInput: "",
            newItemLoading: false,
            ...state
        });
        let dm = (state.dataMode === undefined) ? this.state.dataMode : state.dataMode;
        let cCurrentTable = this.state.currentTable + offset;
        let cMandant;
        let cEndgerät;
        let cStatus;
        let urls;
        if(dm){
            cMandant = this.state.data.mandant[this.state.selectedIndex.mandant] || undefined;
            cEndgerät = this.state.data.endgerät[this.state.selectedIndex.endgerät] || undefined;
            cStatus = this.state.data.status[this.state.selectedIndex.status] || undefined;
            urls = [
                window.api + "/planung?key=mandant",
                window.api + "/planung?key=endgerät&filter="+ cMandant, 
                window.api + "/planung?key=status&filter="+ cMandant + "," + cEndgerät, 
                window.api + "/planung?key=layout&filter="+ cMandant + "," + cEndgerät + "," + cStatus,
            ];
        }
        else{
            cMandant = this.state.schema.mandant[this.state.selectedIndex.mandant] || undefined;
            cEndgerät = this.state.schema.endgerät[this.state.selectedIndex.endgerät] || undefined;
            cStatus = this.state.schema.status[this.state.selectedIndex.status] || undefined;
            urls = [
                window.api + "/mandanten/schema?key=mandant",
                window.api + "/mandanten/schema?key=endgerät&filter="+ cMandant, 
                window.api + "/mandanten/schema?key=status&filter="+ cMandant + "," + cEndgerät, 
                window.api + "/mandanten/schema?key=layout&filter="+ cMandant + "," + cEndgerät + "," + cStatus,
            ];
        }
        console.log(this.state.titles[cCurrentTable])
        this.props._get(urls[cCurrentTable]) 
            .then((data) =>{
                let c = JSON.parse(data).map((el) => helper.capitalize(el));
                let cc = (dm) ? this.state.data : this.state.schema;
                cc[this.state.titles[cCurrentTable]] = c;
                this.setState({
                    fetchingDatafinished: true,
                    data: cc,
                    currentTable: cCurrentTable,
                });
            }).catch((err) =>{
                console.error(err);
                this.setState({
                    fetchingDatafinished: true,
                    currentTable: cCurrentTable,
                });
            });
    }
    selectClickHandler(index){
        let c = this.state.selectedIndex;
        c[this.state.titles[this.state.currentTable]] = index;
        this.setState({
            selectedIndex: c,
        })
    }
    deleteClickHandler(){
        let answers = ["den Mandanten", "das Endgerät", "den Status", "layout"]
        if(window.confirm("Möchten Sie " + answers[this.state.currentTable] + " wirklich löschen?")){
            this.setState({
                fetchingDatafinished: false,
            });
            // TODO: Das hier ist falsch und muss umgeschrieben WERDN!
            let c = {};
            if(this.state.selectedIndex.mandant !== null){
                c.mandant = this.state.data.mandant[this.state.selectedIndex.mandant];
            }
            if(this.state.selectedIndex.endgerät !== null){
                c.endgerät = this.state.data.endgerät[this.state.selectedIndex.endgerät];
            }
            if(this.state.selectedIndex.status !== null){
                c.status = this.state.data.status[this.state.selectedIndex.status];
            }
            if(this.state.selectedIndex.layout !== null){
                c.layout = this.state.data.layout[this.state.selectedIndex.layout];
            }
            if(!this.state.dataMode){
                c.srcSchema = true;
            }
            this.props._post(window.api + "/mandanten/delete", JSON.stringify(c),true)
            .then((data) =>{
                this.fetchData();
            }).catch((err) => {alert(err); this.setState({fetchingDatafinished: true})})
        }
    }
    clickForward(){
        if(this.state.selectedIndex[this.state.titles[this.state.currentTable]]!== null){
            this.setState({
                fetchingDatafinished: false,
                newItemMode: false,
            });
            this.fetchData(1);
        }
    }
    clickBackward(){
        let c = this.state.selectedIndex;
        c[this.state.titles[this.state.currentTable]] = null;
        this.setState({
            fetchingDatafinished: false,
            newItemMode: false,
            selectedIndex: c,
        });
        this.fetchData(-1);
    }
    newItemOnChangeHandler(evt){
        this.setState({
            newItemInput: evt.target.value,
        });
    }
    fetchSchema(){
        return new Promise((resolve,reject) =>{
            this.props._get(window.api + "/schema/select?key=" + this.state.titles[this.state.currentTable])
            .then((data) =>{
                resolve(JSON.parse(data));
            }).catch((err) =>{
                reject(err);
            })
        });
    }
    schemaSelectOnChangeHandler(evt){
        this.setState({
            schemaBluePrintSelected: evt.target.value,
        })
    }
    addNewItem(){
        if(this.state.newItemInput !== null && this.state.newItemInput !== ""){
            this.setState({
                newItemLoading: true,
            });
            let c = {};
            if(Number(this.state.schemaBluePrintSelected) > -1){
                c.schema = {};
                Object.keys(this.state.schemaBluePrints[this.state.schemaBluePrintSelected]).forEach((el) =>{
                    if(el !== "idString"){
                        c.schema[el] = this.state.schemaBluePrints[this.state.schemaBluePrintSelected][el];
                    }
                });
                c.key = this.state.titles[this.state.currentTable];
                
            }
            if(!this.state.dataMode){
                c.srcSchema = true;
            }
            let url =window.api + "/planung";
            if(this.state.dataMode){
                switch(this.state.currentTable){
                    case 0:
                        c.mandant = this.state.newItemInput;
                        break;
                    case 1:
                        c.mandant = this.state.data.mandant[this.state.selectedIndex.mandant];
                        c.endgerät = this.state.newItemInput;
                        break;
                    case 2:
                        c.mandant = this.state.data.mandant[this.state.selectedIndex.mandant];
                        c.endgerät = this.state.data.endgerät[this.state.selectedIndex.endgerät];
                        c.status = this.state.newItemInput;
                        break;
                    case 3:
                        c.mandant = this.state.data.mandant[this.state.selectedIndex.mandant];
                        c.endgerät = this.state.data.endgerät[this.state.selectedIndex.endgerät];
                        c.status = this.state.data.status[this.state.selectedIndex.status];
                        c.layout = this.state.newItemInput;
                        break;
                }
            }
            else{
                switch(this.state.currentTable){
                    case 0:
                        c.mandant = this.state.newItemInput;
                        break;
                    case 1:
                        c.mandant = this.state.schema.mandant[this.state.selectedIndex.mandant];
                        c.endgerät = this.state.newItemInput;
                        break;
                    case 2:
                        c.mandant = this.state.schema.mandant[this.state.selectedIndex.mandant];
                        c.endgerät = this.state.schema.endgerät[this.state.selectedIndex.endgerät];
                        c.status = this.state.newItemInput;
                        break;
                    case 3:
                        c.mandant = this.state.schema.mandant[this.state.selectedIndex.mandant];
                        c.endgerät = this.state.schema.endgerät[this.state.selectedIndex.endgerät];
                        c.status = this.state.schema.status[this.state.selectedIndex.status];
                        c.layout = this.state.newItemInput;
                        break;
                }
            }
            
            this.props._post(url, JSON.stringify(c), true)
            .then((data) => {
                this.fetchData();
            })
            .catch((err)=>{
                console.error(err);
                this.setState({
                    newItemLoading: false,
                })
            })
        }
    }
    render(){
        let modeCss = (this.state.dataMode) ? {marginRight: 38} : {marginLeft: 39};
        return(
            <div className="whiteWrapper" id="adminMandantenWrapper">
                <div style={{width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                    <Headline breadcrumb="data" headline="Mandantenverwaltung"/>
                    <div style={{display: "flex", justifyContent: "center", alignItems: "center", margin: 15}}>
                        <div className={(this.state.dataMode) ? "switchLabel selected" : "switchLabel"}><span>Daten</span></div>
                        <div onClick={this.toggleDataMode} id="radioSwitch">
                            <div style={modeCss}id="radioMarker"></div>
                        </div>
                        <div className={(!this.state.dataMode) ? "switchLabel selected" : "switchLabel"}><span>Schema</span></div>
                    </div>
                </div>
                <div style={{width: "100%", height:"90%", display: "flex", justifyContent: "center", alignItems: "center"}} >
                    <div className="positioner">
                        <div style={{gridColumn: "1/2", display: "flex", justifyContent: "center", alignItems: "center", flexWrap: "nowrap"}}>
                            <div> 
                                <h4 style={{margin: "20px 0", textAlign: "center"}}>{(!this.state.dataMode && this.state.currentTable === 0) ? "Schema" : helper.capitalize(this.state.titles[this.state.currentTable]) }</h4>
                                <NeutralInputDiv
                                    values = {(this.state.dataMode) ? this.state.data[this.state.titles[this.state.currentTable]] : this.state.schema[this.state.titles[this.state.currentTable]]}
                                    selectedIndex = {this.state.selectedIndex[this.state.titles[this.state.currentTable]]}
                                    clickHandler = {this.selectClickHandler}
                                    isLoading = {!this.state.fetchingDatafinished}
                                    listItemButtons = {[{iconName: "delete", clickHandler: this.deleteClickHandler, hoverColor: "red"}]}
                                    scaleHeight = {450}
                                />
                                <div style={{display: "flex", justifyContent: "space-around", alignItems:"center", flexWrap: "nowrap", height: 100}}>
                                    {this.state.currentTable > 0 &&
                                        <button onClick={this.clickBackward} style={{width: 100}} className="roundButton">
                                            <div><span className="material-icons">arrow_back</span></div><div>{"Zurück"}</div>
                                        </button>
                                    }
                                    {this.state.currentTable === 0 &&
                                        <button style={{width: 100}} className="roundButton deactivated">
                                            <div><span className="material-icons">arrow_back</span></div><div>{"Zurück"}</div>
                                        </button>
                                    }
                                    <button onClick={this.openNewItemMode} className="roundButton">
                                        <span className="material-icons">add</span>
                                    </button>
                                    {this.state.currentTable < 3 &&
                                        <button onClick={this.clickForward} style={{width: 100}} className="roundButton">
                                            <div>{"Weiter"}</div><div><span className="material-icons">arrow_forward</span></div>
                                        </button>
                                    }
                                    {this.state.currentTable >= 3 &&
                                        <button style={{width: 100}} className="roundButton deactivated">
                                            <div>{"Weiter"}</div><div><span className="material-icons">arrow_forward</span></div>
                                        </button>
                                    }
                                </div>
                            </div>
                        </div>
                        <div style={{gridColumn: "2/3", display: "grid", gridTemplateRows: "1fr 4fr", gridTemplateColumns: "1fr", flexWrap: "nowrap"}}>
                            <div id="mandantenBreadcrumbs">
                                { (this.state.selectedIndex.mandant !== null) && 
                                    <div className="breadcrumb first"><span>{this.state.data.mandant[this.state.selectedIndex.mandant]}</span></div>
                                }
                                { (this.state.selectedIndex.endgerät !== null) && 
                                    <div className="breadcrumb "><span>{this.state.data.endgerät[this.state.selectedIndex.endgerät]}</span></div>
                                }
                                { (this.state.selectedIndex.status !== null) && 
                                    <div className="breadcrumb last"><span>{this.state.data.status[this.state.selectedIndex.status]}</span></div>
                                }
                            </div>
                            <div id="newItemWrapper">
                                {this.state.newItemMode &&
                                    <div id="newItemCard" >
                                        <div style={{gridRow: "1/2", display: "flex", justifyContent: "flex-start", alignItems: "center", marginLeft: 20, fontSize: 19}}>
                                            <h4>{"Hinzufügen: " + ((!this.state.dataMode && this.state.currentTable === 0) ? "Schema" : helper.capitalize(this.state.titles[this.state.currentTable]))}</h4>
                                        </div>
                                        {!this.state.newItemLoading && 
                                            <div style={{gridRow: "2/3", display: "flex", justifyContent: "center", alignItems: "center", }}>
                                                <select id="newItemSchemaSelect" onChange={this.schemaSelectOnChangeHandler}>
                                                    <option value={-1}>Keine Vorlage verwenden</option>
                                                    {this.state.schemaBluePrints.length > 0 && this.state.schemaBluePrints.map((el,index) =>{
                                                        return(
                                                            <option value={index} key={index}>{el.idString}</option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                        }
                                        {!this.state.newItemLoading && 
                                            <div style={{gridRow: "3/4", display: "flex", justifyContent: "center", alignItems: "flex-start", }}>
                                                <input onChange={this.newItemOnChangeHandler} value={this.state.newItemInput} id="newItemInput"></input>
                                            </div>
                                        }
                                        {!this.state.newItemLoading && 
                                            <div style={{gridRow: "4/5", display: "flex", justifyContent: "center", alignItems: "center", }}>
                                                <button onClick={this.addNewItem} id="newItemButtonAccept">Hinzufügen</button>
                                                <button onClick={this.closeNewItemMode} id="newItemButtonDecline">Abbrechen</button>
                                            </div>
                                        }
                                        {this.state.newItemLoading &&
                                            <div style={{gridRow: "2/5"}}>
                                                <Loader
                                                scale={0.4}
                                                />
                                            </div>
                                        }
                                    </div>
                                }
                                {(!this.state.newItemMode && !this.state.dataMode && this.state.currentTable === 3 && this.state.selectedIndex.layout !== null) &&
                                    <div style={{width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
                                        <Link to={{
                                        pathname: "/planung/planner",
                                        state: {
                                            mandant: this.state.schema.mandant[this.state.selectedIndex.mandant],
                                            endgerät: this.state.schema.endgerät[this.state.selectedIndex.endgerät],
                                            status: this.state.schema.status[this.state.selectedIndex.status],
                                            layout: this.state.schema.layout[this.state.selectedIndex.layout],
                                            schema: true,
                                        }
                                        }} style={{textDecoration: "none", color: "inherit"}}>
                                            <button className="button" style={{width: 150, height: 60, fontSize: 20, }}>Layout bearbeiten</button>
                                        </Link>
                                    </div> 
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default AdminMandanten;