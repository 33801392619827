import React from "react";
import Start from "./Start.js";
import Loader from "./Loader.js";
import Login from "./Login.js";

class LoaderWrapper extends React.Component{
    constructor(props){
        super(props);
    }
    async shouldComponentUpdate(nextProps, nextState){
        if(nextProps.location.pathname !== this.props.location.pathname){
            this.props.setAppState({checkedAuth: false});
            this.props.checkAuthentication();
            return true;
        }
        else{
            return false;
        }
    }
    render(){
        let permissionCheck = (this.props.adminOnly) ? this.props.permission === 1 : this.props.permission < 3;
        if(!this.props.checkedAuth){
            return(
                <Loader marginTop="200px"/>
            )
        }
        else{
            if(this.props.isAuthed){
                if(this.props.needAdmin){
                    if(permissionCheck){
                        return(
                            <div>{this.props.children}</div>
                        )
                    }
                    else{
                        return(
                            <Start />
                        )
                    }
                }
                else{
                    return(
                        <div>{this.props.children}</div>
                    )
                }
            }
            else{
                return (
                    <Login 
                        toggleGui={this.props.toggleGui}
                        toggleLoadingState = {this.props.toggleLoadingState}
                        setAppState = {this.props.setAppState}
                        _post={this.props._post}
                        wrongCredentials={this.props.wrongCredentials}
                        checkAuthentication={this.props.checkAuthentication}
                        setAppState={this.props.setAppState}
                        isAuthed={this.props.checkedAuth}
                    /> 
                )
            }
        }
    }
}

export default LoaderWrapper;