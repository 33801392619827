import React from "react";
import Headline from "./Headline.js";
import "./css/iqdpw.css";
import "./style.css";
import iqdpw from "./js/iqdpw.js"; 
import IqdAdPositionComment from "./js/IqdAdPositionComment.js" ;
import Loader from "./Loader.js";
import DialogModal from "./DialogModal";
import CanvasMainMenuInfo from "./CanvasMainMenuInfo";
import helper from "./helper.js";

class PlanningWizzard extends React.Component{
    constructor(props){
        super(props);
        this.x = null;
        this.state = {
            mandant: this.props.location.state.mandant || null,
            endgerät: this.props.location.state.endgerät || null,
            status: this.props.location.state.status || null,
            layout: this.props.location.state.layout || null,
            dialogModalOpen: false,
            dialogType: undefined,
        };
        this.saveConfig = this.saveConfig.bind(this);
        this.throwConfig = this.throwConfig.bind(this);
        this.fetchData = this.fetchData.bind(this);
        this.addComment = this.addComment.bind(this);
        this.addSiteComment = this.addSiteComment.bind(this);
        this.toggleDialogModal = this.toggleDialogModal.bind(this);
        this.renderDataFromSource = this.renderDataFromSource.bind(this);
        this.addAdtile = this.addAdtile.bind(this);
        this.fetchSchema = this.fetchSchema.bind(this);
        this.disableClick = this.disableClick.bind(this);
        window.fetchDone = false;
        window.topLevelComments = [];
        window.lastFetchingState = undefined;
    }
    componentDidMount(){
        this.fetchData(false);
        this.fetchSchema();
        this.x = new iqdpw();
        window.addEventListener("scroll", this.fixedSite);
    }
    componentWillUnmount(){
        this.x.destroy();
        delete this.x
        window.removeEventListener("scroll", this.fixedSite);
    }
    fixedSite(){
        let sites = document.querySelectorAll(".fixedAnchor");
        sites.forEach( (el) => {
            el.style.position = (window.scrollY > 191) ? "fixed" : "relative";
        })
    }
    disableClick(evt){
        if(this.props.permission > 2){
            evt.preventDefault();
            evt.stopPropagation();
        }
    }
    saveConfig(){
        let itemToSave = new SaveItem(this.state.mandant, this.state.endgerät, this.state.status, this.state.layout, window.shapes, window.topLevelComments);
        document.querySelector("body").style.cursor = "wait";
        let url = (this.props.location.state.schema) ? window.api + "/schema/planner" : window.api + "/planung/planner"
        this.props._post(url, JSON.stringify(itemToSave))
            .then((response) =>{
                if(response.status === 200){
                    window.alert("Speichern erfolgreich");
                    window.lastFetchingState = undefined;
                }
                else{
                    window.alert("ERROR: Speichern war nicht erfolgreich.");
                }
            this.toggleDialogModal();
            document.querySelector("body").style.cursor = "auto";
            })
            .catch((err) => console.error(err));
    }
    throwConfig(){
        window.shapes = [];
        if(window.lastFetchingState){
            this.renderDataFromSource(window.lastFetchingState, true);
        }
        else{
            this.fetchData()
        }
        this.toggleDialogModal();
    }
    fetchData(fromCache){
        window.shapes = [];
        this.props.setAppState({
            isLoading: true,
        });
        document.querySelector("body").style.cursor = "wait";
        document.querySelector("body").style.overflow = "hidden";
        let queryString= [
            "mandant=" + this.state.mandant,
            "endgerät=" + this.state.endgerät,
            "status=" + this.state.status,
            "layout=" + this.state.layout,
        ];
        if(!fromCache){
            let url = (this.props.location.state.schema) ? window.api + "/schema/planner?" : window.api + "/planung/planner?"
            let request = this.props._get(url + queryString.join("&"));
            request.then( (data) => {
                if(!window.fetchDone){
                    window.fetchDone = true;
                    let parsedData = JSON.parse(data);
                    parsedData = (parsedData) ? parsedData : {shapes: []};
                    this.props.setAppState({
                        isLoading: false,
                    });
                    this.renderDataFromSource(parsedData, fromCache);
                }
            }).catch((err)=>{console.log(err)});
        }
    }
    fetchSchema(){
        this.props._get(window.api + "/schema/daten")
            .then((data) =>{
                let parsed = JSON.parse(data);
                let c = {schemaSizes: helper.sortSlotSizes([...parsed.sizes]),schemaAdtiles: helper.sortAdtiles([...parsed.adtiles])}
                this.setState(c);
            }).catch((err) =>{
                console.error(err);
            })
    }
    renderDataFromSource(parsedData, fromCache){
        for(let i = 0; i < parsedData.shapes.length; i++){
            window.createFullsize();
            for(let key in parsedData.shapes[i]){
                window.shapes[i][key] = parsedData.shapes[i][key]
            }
        }
        window.topLevelComments = (parsedData.topLevelComments) ? [].concat(parsedData.topLevelComments) : [];
        if(!fromCache){
            window.lastFetchingState = parsedData;
        }
        window.drawScreen();
        window.drawMiniMap();
        document.querySelector("body").style.cursor = "auto";
        document.querySelector("body").style.overflow = "auto";
    }
    addComment(input, iqadtile){
        let cComment = new IqdAdPositionComment(input, this.props.name);
        for(let i = 0; i < window.shapes.length; i++){
            if(window.shapes[i].iatId === iqadtile){
                window.shapes[i].comment.push(cComment);
                window.shapes[i].sortCommentByDate();
                break;
            }
        }
    }
    addSiteComment(input){
        let comment = new IqdAdPositionComment(input, this.props.name);
        window.topLevelComments.push(comment);
    }
    addAdtile(iqadtileId, slotsizes, slotWidth, slotHeight=250){
        window.makeSingleShape(slotWidth, slotHeight);
        let c = window.shapes[window.shapes.length-1];
        c.iatId = iqadtileId;
        // c.slotsize = slotsizes.toString();
        c.slotsize = slotsizes;
        window.positionSequencing();
        window.makeMiniMap();
    }
    toggleDialogModal(type){
        let cState = {
            dialogModalOpen: !this.state.dialogModalOpen,
        }
        cState.dialogType = (typeof type === "string") ? type : undefined;
        this.setState(
            cState
        )
    }

    render(){
        return(
            <div>
                <Headline
                    breadcrumb = {this.props.breadcrumb}
                    headline = {"Layout Planner"}
                />
                {this.props.isLoading &&
                    <div className="isLoadingModal" style={{display: "flex", alignItems: "flex-start", width: "100%", height: "100vh", position: "relative", zIndex: 501,}}>
                        <Loader />
                    </div> 
                }
                    <div id="mainApp">
                        <div id="canvasMapStack">
                                <div className="fixedAnchor">
                                    <canvas ref="canvasMiniMapActiveArea" id="canvasMiniMapActiveArea" width="100" height="1000">
                                        Your browser does not support HTML5 canvas.
                                    </canvas>
                                    <canvas ref="canvasMiniMap" id="canvasMiniMap" width="100" height="1000">
                                        Your browser does not support HTML5 canvas.
                                    </canvas>
                                </div>
                        </div>
                        <div id="canvasWrapper">
                            { this.state.dialogModalOpen &&
                                <DialogModal 
                                    input = {this.state.topLevelComments}
                                    name = {this.props.name}
                                    topLevelComments = {window.topLevelComments}
                                    addComment = {this.addComment}
                                    addSiteComment = {this.addSiteComment}
                                    toggleDialogModal = {this.toggleDialogModal}
                                    addAdtile = {this.addAdtile}
                                    mandant = {this.state.mandant}
                                    endgerät = {this.state.endgerät}
                                    status = {this.state.status}
                                    layout = {this.state.layout}
                                    dialogType={this.state.dialogType}
                                    saveConfig = {this.saveConfig}
                                    throwConfig = {this.throwConfig}
                                    _get = {this.props._get}
                                    schemaAdtiles = {this.state.schemaAdtiles}
                                    schemaSizes = {this.state.schemaSizes}
                                    permission = {this.props.permission}
                                />

                            }
                            <div id="canvasStack">
                                <canvas ref="canvas" id="canvasOne" width="1000" height="10000">
                                Your browser does not support HTML5 canvas.
                                </canvas>
                            </div>
                        </div>
                        <div id="canvasMainMenu">
                            <div className="fixedAnchor">
                                <CanvasMainMenuInfo
                                mandant = {this.state.mandant}
                                endgerät = {this.state.endgerät}
                                status = {this.state.status}
                                layout = {this.state.layout}
                                />
                                <div id="adtileInfo">
                                    <div id="showIqadtileInformation" className="hidden"></div>
                                </div>
                                <div id="plannerButtonWrapper">
                                { (this.props.permission < 3) &&
                                    <PlannerToolButton
                                        toggleDialogModal = {this.toggleDialogModal} 
                                        dialogType = "save"
                                        materialIcon = "save"
                                        class = "saveButton"
                                    />
                                }
                                { (this.props.permission < 3) &&
                                    <PlannerToolButton 
                                        toggleDialogModal = {this.toggleDialogModal} 
                                        dialogType = "throw"
                                        materialIcon = "delete_forever"
                                        class = "throwButton"
                                    />
                                }
                                { (this.props.permission < 3) &&
                                    <PlannerToolButton 
                                        toggleDialogModal = {this.toggleDialogModal} 
                                        dialogType = "add"
                                        materialIcon = "add_to_photos"
                                        class = "addSlotButton"
                                    />
                                }
                                { (this.props.permission < 3) &&
                                    <PlannerToolButton 
                                        toggleDialogModal = {this.toggleDialogModal} 
                                        dialogType = "edit"
                                        materialIcon = "settings_overscan"
                                        class = "editSlotButton"
                                    />
                                }
                                    <PlannerToolButton 
                                        toggleDialogModal = {this.toggleDialogModal} 
                                        dialogType = "comment" 
                                        materialIcon = "comment"
                                        class = "commentButton"
                                        permission = {this.props.permission}
                                    />
                                </div>
                                <div style={{display: "none"}}>
                                    <input type="checkbox" id="mt1"/>
                                    <div id="addPositionMenu">
                                        <label htmlFor="mt1" className="first-item">Adslots</label>
                                        <div style={{padding: 5}}>
                                            <h5>Adslots hinzufügen</h5>
                                            <ul>
                                                <li><button data-button-type="createFullsize" type="button" >1/1</button></li>
                                                <li><button data-button-type="createTwoThird" type="button" >2/3</button></li>
                                                <li><button data-button-type="createOneThird" type="button" >1/3</button></li>
                                            </ul>
                                            <div style={{width: "95%", display: "flex", flexWrap: "nowrap", justifyContent: "flex-end", alignItems: "center"}}>
                                                <input type="text" id="customPositionSize" name="customPositionSize" placeholder="BREITE x HÖHE" />
                                                <button id="createCustom" data-button-type="createCustom" type="button" >Custom</button>
                                            </div>
                                            <hr className="categoryBreak" />
                                            <h5>Adslots bearbeiten</h5>
                                            <div style={{width: "95%", display: "flex", flexWrap: "nowrap", justifyContent: "flex-end", alignItems: "center"}}>
                                                <input type="text" id="editCustomPositionSize" name="editCustomPositionSize" placeholder="BREITE x HÖHE" />
                                                <button id="resizeCurrent" data-button-type="resizeCurrentPosition" type="button" >Größe ändern</button>
                                            </div>
                                            <hr className="categoryBreak" />
                                            <h5>Adslots klonen</h5>
                                            <div style={{display: "flex", justifyContent: "center", flexWrap: "nowrap"}}>
                                                <button  data-button-type="cloneCurrentPosition" type="button" >Aktive Position klonen</button>
                                            </div>
                                        </div>
                                    </div>
                                    <input type="checkbox" id="mt2"/>
                                    <div id="addIqadtileMenu">
                                        <label htmlFor="mt2">IQAdtile Id</label>
                                        <h5>IQAdtile benennen</h5>
                                        <input id="addIqAdtile" list="all-theIqadtileId" placeholder="iqadtile" name="theIqadtileId" />
                                        <datalist id="all-theIqadtileId">
                                            <option value="test">test</option>
                                        </datalist>
                                        <div style={{display: "flex", justifyContent: "center"}}>
                                            <button  data-button-type="addIqadtileId" type="button" >Setzen</button>
                                        </div>
                                    </div>
                                    <input type="checkbox" id="mt3" />
                                    <div id="addSlotsizeMenu">
                                        <label htmlFor="mt3">Slotgrößen</label>
                                        <h5>Eine Slotgröße verändern</h5>
                                        <input id="addOneSlotSize" list="oneSlotsize" placeholder="99x1" name="oneSlotsize" />
                                        <datalist id="oneSlotsize">
                                            <option value="1000x1000">1000x1000</option>
                                        </datalist>
                                        <div>
                                            <button data-button-type="addOneSlotsize" type="button" >Hinzufügen</button>
                                            <button data-button-type="removeOneSlotsize" type="button" >Löschen</button>
                                        </div>
                                        <hr className="categoryBreak" />
                                        <h5>Multiple Slotgrößen verändern</h5>
                                        <input id="addMultipleSlotSize" list="slotsizes" name="slotsizes" placeholder="99x1,300x250,728x90" />
                                        <div>
                                            <button data-button-type="addMultipleSlotsize" type="button" >Hinzufügen</button>
                                            <button data-button-type="removeMultipleSlotsize" type="button" >Löschen</button>
                                        </div>
                                        <hr className="categoryBreak" />
                                        <h5>Slotgrößen löschen</h5>
                                        <input style={{display: "none"}} id="isSlotSizeThere" type="text" id="allSlotsizeList" name="allSlotsizeList" value="test" />
                                        <div>
                                            <button data-button-type="removeAllSlotsize" type="button" >Alle löschen</button>
                                        </div>
                                    </div>
                                    <input type="checkbox" id="mt4" />
                                    <div id="addCommentMenu">
                                        <label htmlFor="mt4">Kommentare</label>
                                        <h5>Erstellen</h5>
                                        <textarea id="addComment" type="textarea" name="comment" placeholder="Kommentar" />
                                        <div>
                                            <button type="button" data-button-type="addComment" onClick={this.addComment}>Zum Werbeslot hinzufügen</button>
                                            <button type="button" data-button-type="addSiteComment" onClick={this.addSiteComment}>Zur Seite hinzufügen</button>
                                        </div>
                                        <hr className="categoryBreak" />
                                        <h5>Kommentare einblenden</h5>
                                        <div>
                                        <button type="button" id="toggleComment" onClick={this.toggleDialogModal} >Anzeigen</button>
                                    </div>
                                </div>

                                </div>
                        </div>
                    </div>
                </div>
                <div id="showConfirmWrapper" className="hidden">
                    <div id="showConfirm" className="hidden"></div>
                </div>
                <div id="showErrorWrapper" className="hidden">
                    <div id="showError" className="hidden"></div>
                </div>
                <div id="showCommentWrapper" className="hidden">
                    <div id="showIqadtileComment" className="hidden"></div>
                </div>
                <div id="showSiteConfigWrapper" className="hidden">
                    <div id="showSiteConfig" className="hidden"></div>
                </div>
        </div>
        )
    }
}
class PlannerToolButton extends React.Component{
    constructor(props){
        super(props);
        this.toggleDialogWrapper = this.toggleDialogWrapper.bind(this);
    }
    toggleDialogWrapper(){
        if(this.props.dialogType === "edit" && (!window.shapes || window.shapes.length < 1)){
        }
        else{
            console.log(this.props.dialogType);
            this.props.toggleDialogModal(this.props.dialogType);
        }
    }
    render(){
        return(
            <button onClick={this.toggleDialogWrapper} className={"plannerButton "+this.props.class} >
                <i className="material-icons">{this.props.materialIcon}</i>
            </button>
        )
    }
}

class SaveItem{
    constructor(mandant, endgerät, status, layout, shapes, topLevelComments){
        this.mandant = mandant;
        this.endgerät = endgerät;
        this.status = status;
        this.layout = layout;
        this.topLevelComments = topLevelComments;
        this.shapes = shapes;
    }
}

export default PlanningWizzard;