import React from "react";
import Headline from "./Headline.js"
import Loader from"./Loader.js";
import helper from "./helper.js";
import "./style.css"
import "./AdminDaten.css"

class AdminDaten extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            selectedMetric: 0,
            slotSizeWidth: "",
            slotSizeHeight: "",
            slotSizeText: "",
            iqAdtileInput: "",
            errorMsg: "",
            sizes: [],
            adtiles: [],
            isFetching: false,
            sliderMode: true,
        }
        this.metricOnClickHandler = this.metricOnClickHandler.bind(this);
        this.slotSizeHeightOnChange = this.slotSizeHeightOnChange.bind(this);
        this.slotSizeWidthOnChange = this.slotSizeWidthOnChange.bind(this);
        this.slotSizeTextOnChange = this.slotSizeTextOnChange.bind(this);
        this.iqAdtileInputOnChange = this.iqAdtileInputOnChange.bind(this);
        this.deleteChip = this.deleteChip.bind(this);
        this.addChip = this.addChip.bind(this);
        this.fetchData = this.fetchData.bind(this);
        this.throwClickHandler = this.throwClickHandler.bind(this);
        this.saveClickHander = this.saveClickHander.bind(this);
        this.toggleSlider = this.toggleSlider.bind(this);
    }
    // TODO:
    // - Speichern route erstellen und eine methode zum abrufen schreiben
    // - Funktion zum richtigen sortieren analog zu der Sortierfunktion der Positionstabelle
    componentDidMount(){
        this.fetchData();
    }
    fetchData(){
        this.setState({
            isFetching: true,
        })
        this.props._get(window.api + "/schema/daten")
            .then((data) =>{
                let parsed = JSON.parse(data);
                this.setState({
                    sizes: helper.sortSlotSizes(parsed.sizes),
                    adtiles: helper.sortAdtiles(parsed.adtiles),
                    isFetching: false,
                })
            }).catch((err) =>{
                this.setState({
                    isFetching: false,
                    errorMsg: "Es ist ein Fehler aufgetreten",
                })
            })
    }
    metricOnClickHandler(evt){
        let el = (evt.target.tagName === "DIV") ? evt.target : evt.target.parentElement;
        let c = Number(el.dataset.index);
        this.setState({
            selectedMetric: c,
            slotSizeWidth: "",
            slotSizeHeight: "",
            iqAdtileInput: "",
            errorMsg: "",
        })
    }
    slotSizeWidthOnChange(evt){
        this.setState({
            slotSizeWidth: evt.target.value,
        })
    }
    slotSizeHeightOnChange(evt){
        this.setState({
            slotSizeHeight: evt.target.value,
        })
    }
    slotSizeTextOnChange(evt){
        this.setState({
            slotSizeText: evt.target.value,
        })
    }
    iqAdtileInputOnChange(evt){
        this.setState({
            iqAdtileInput: evt.target.value,
        })
    }
    deleteChip(evt){
        let c = {}
        let i = Number(evt.target.parentElement.dataset.index);
        if(this.state.selectedMetric === 0){
            c.sizes = [];
            this.state.sizes.forEach((el,index) =>{
                if(index !== i){
                    c.sizes.push(el);
                }
            })
        }
        else{
            c.adtiles = [];
            this.state.adtiles.forEach((el,index) =>{
                if(index !== i){
                    c.adtiles.push(el);
                }
            })
        }
        this.setState(c);
    }
    addChip(){
        let c = {};
        let s = "";
        if(this.state.selectedMetric === 0){
            if(this.state.sliderMode){
                s = this.state.slotSizeWidth + "x" + this.state.slotSizeHeight;
            }
            else{
                s = this.state.slotSizeText;
            }
            if(this.state.sizes.indexOf(s) < 0){
                c.sizes = helper.sortSlotSizes([...this.state.sizes, s]);
                c.slotSizeWidth = "";
                c.slotSizeHeight = "";
                c.slotSizeText = "";
                c.errorMsg = ""
            }
            else{
                c.errorMsg = "Slotgröße ist bereits vorhanden."
            }
        }
        else{
            s = "iqadtile" + this.state.iqAdtileInput;
            if(this.state.adtiles.indexOf(s) < 0){
                c.adtiles = helper.sortAdtiles([...this.state.adtiles, s]);
                c.iqAdtileInput = "";
                c.errorMsg = ""
            }
            else{
                c.errorMsg = "Iqadtile ist bereits vorhanden."
            }
        }
        this.setState(c);
    }
    throwClickHandler(){
        if(window.confirm("Möchtest du deine Änderungen wirklich verwerfen?")){
            this.fetchData();
        }
    }
    saveClickHander(){
        if(window.confirm("Möchtest du deine Änderungen wirklich speichern?")){
            this.props._post(window.api + "/schema/daten", JSON.stringify({sizes: this.state.sizes, adtiles: this.state.adtiles}), true)
                .then((data) => {
                    console.log(data);
                }).catch((err) =>{
                    console.error(err);
                })
        }
    }
    toggleSlider(){
        this.setState({
            sliderMode: !this.state.sliderMode, 
        });
    }
    render(){
        let modeCss = (this.state.sliderMode) ? {marginRight: 38} : {marginLeft: 38};
        return(
            <div className="whiteWrapper" id="adminDatenWrapper">
                <Headline breadcrumb="data" headline="Datenverwaltung"/>
                <div id="adminDatenComponent">
                    <div id="chooseDataList">
                        <div onClick={this.metricOnClickHandler} data-index="0" className={(this.state.selectedMetric === 0) ? "activated" : ""}><span>Slotgrößen</span></div>
                        <div onClick={this.metricOnClickHandler} data-index="1" className={(this.state.selectedMetric === 1) ? "activated" : ""}><span>Iqadtiles</span></div>
                    </div>
                    <div id="editMetricWrapper">
                        <div id="editMetricPositioner">
                            {(this.state.selectedMetric === 0) && 
                                <div style={{display: "flex", justifyContent: "center", alignItems: "center", margin: 15}}>
                                    <div className={(this.state.sliderMode) ? "switchLabel selected" : "switchLabel"}><span>Breite x Höhe</span></div>
                                    <div onClick={this.toggleSlider} id="radioSwitch">
                                        <div style={modeCss}id="radioMarker"></div>
                                    </div>
                                    <div className={(this.state.sliderMode) ? "switchLabel" : "switchLabel selected"}><span>Freitext</span></div>
                                </div>
                            }
                            {(this.state.selectedMetric === 0 && this.state.sliderMode) && 
                                <div id="editMetricInputWrapper" className="slotsize">
                                    <span>Breite:</span>
                                    <input onChange={this.slotSizeWidthOnChange} value={this.state.slotSizeWidth}></input>
                                    <span>Höhe:</span>
                                    <input onChange={this.slotSizeHeightOnChange} value={this.state.slotSizeHeight}></input>
                                    <button onClick={this.addChip}>Hinzufügen</button>
                                </div>
                            }
                            {(this.state.selectedMetric === 0 && !this.state.sliderMode) && 
                                <div id="editMetricInputWrapper" className="slotsize">
                                    <span>Slotgröße:</span>
                                    <input style={{width: "auto"}} onChange={this.slotSizeTextOnChange} value={this.state.slotSizeText}></input>
                                    <button onClick={this.addChip}>Hinzufügen</button>
                                </div>
                            }
                            {(this.state.selectedMetric === 1) && 
                                <div id="editMetricInputWrapper" className="iqadtiles">
                                    <div style={{display: "flex", justifyContent:"center", alignItems: "center", width: "70%"}}>
                                        <span>Iqadtile</span>
                                        <input onChange={this.iqAdtileInputOnChange} value={this.state.iqAdtileInput}></input>
                                    </div>
                                    <button onClick={this.addChip}>Hinzufügen</button>
                                </div>
                            }
                            <div className={(this.state.errorMsg !== "") ? "" : "hidden"} style={{width: "100%",height: 30, lineHeight: "30px", fontSize: 18,marginBottom: 10}}id="errorMsg">{this.state.errorMsg}</div> 
                            {!this.state.isFetching &&
                                <div id="editMetricContainer">
                                    {(this.state.selectedMetric === 0 && this.state.sizes.length > 0) && this.state.sizes.map((el,index) =>{
                                        return(
                                            <div data-index={index} key={index} className="sizeChip"><span>{el}</span><i onClick={this.deleteChip} className="material-icons">cancel</i></div>
                                        )
                                    })}
                                    {(this.state.selectedMetric === 1 && this.state.adtiles.length > 0) && this.state.adtiles.map((el,index) =>{
                                        return(
                                            <div data-index={index} key={index} className="sizeChip"><span>{el}</span><i onClick={this.deleteChip} className="material-icons">cancel</i></div>
                                        )
                                    })}
                                </div>
                            }
                            {this.state.isFetching &&
                                <div id="editMetricContainer">
                                    <Loader
                                        scale="0.5"
                                    />
                                </div>
                            }
                        </div>
                    </div>
                    <div id="saveMetricWrapper">
                        <div id="saveMetricPositioner">
                            <button onClick={this.throwClickHandler} className="button">Verwerfen</button>
                            <button onClick={this.saveClickHander} className="button">Speichern</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default AdminDaten;