import React from "react";

import NavSidebar from "./NavSidebar.js";
import Loader from "./Loader.js";
import UserCard from "./UserCard.js"
import Headline from "./Headline.js";
import RTable from "./RTable.js";
import "./AdminUser.css";

class AdminUser extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            activatedFolder:  0,
            selectedRow: null,
            folder: [{title:"Zugangsanfragen", icon: "person_search"}, {title: "Registrierte Nutzer", icon: "how_to_reg"}],
            loading: false,
            data: {
                requested: null,
                user: null,
            },
            selectedPermission: null,
            noPermissionError: false,
            clickedAccept: false,
        }
        this.fetchDataNew = this.fetchDataNew.bind(this);
        this.changeFolder = this.changeFolder.bind(this);
        this.toggleAccept = this.toggleAccept.bind(this);
        this.clickHandler = this.clickHandler.bind(this);
        this.selectPermission = this.selectPermission.bind(this);
        this.acceptUserClickHandler = this.acceptUserClickHandler.bind(this);
        this.declineUserClickHandler = this.declineUserClickHandler.bind(this);
        this.deleteUserClickHandler = this.deleteUserClickHandler.bind(this);
    }
    componentDidMount(){
        this.fetchDataNew();
    }
    fetchDataNew(){
        this.setState({
            loading: true,
        });
        this.props._get(window.api + "/user")
            .then((data) =>{
                let c = JSON.parse(data);
                console.log(c);
                this.setState({
                    loading: false,
                    data: {
                        requested: c.requests,
                        user: c.user,
                    },
                    clickedAccept: false,
                    selectPermission: null,
                    noPermissionError: false,
                    selectedRow: null,
                });
            }).catch((err) =>{
                window.alert("Error: Konnte Daten nicht neu laden!");
            })

    }
    clickHandler(index){
        let x = document.querySelector("#permissionSelect");
        if(x){
            x.selectedIndex = 0;
        }
        this.setState({
            selectedRow: index,
            selectedPermission: 0,
            clickedAccept: false,
        });
    }
    changeFolder(index){
        this.setState({
            activatedFolder: index,
            selectedRow: null,
            loading: false,
            selectedPermission: null,
            noPermissionError: false,
            clickedAccept: false,
        })
    }
    selectPermission(permission){
        this.setState({
            selectedPermission: permission,
            noPermissionError: false,
        })  
    }
    acceptUserClickHandler(){
        this.setState({
            loading: true,
        });
        if(Number(this.state.selectedPermission) === 1 || Number(this.state.selectedPermission) === 2 || Number(this.state.selectedPermission) === 3){
            let c = this.state.data.requested[this.state.selectedRow];
            let insert = {_id: c["_id"], permission: Number(this.state.selectedPermission)}
            this.props._post(window.api + "/user/accept", JSON.stringify(insert), true)
                .then((res) =>{
                    this.fetchDataNew();
                }).catch((err) =>{
                    alert("Hier ist leider etwas schief gelaufen!");
                    console.error(err);
                    this.setState({
                        loading: true,
                    });
                })
        }
        else{
            this.setState({
                loading: false,
                noPermissionError: true,
            });
        }

    }
    declineUserClickHandler(){
        if(window.confirm("Möchten Sie den Nutzer wirklich ablehnen?")){
            this.setState({
                loading: true,
            });
            let c = this.state.data.requested[this.state.selectedRow];
            let del = {_id: c["_id"]};
            this.props._post(window.api + "/user/decline", JSON.stringify(del), true)
                .then((res) =>{
                    this.fetchDataNew();
                }).catch((err) =>{
                    alert("Hier ist leider etwas schief gelaufen!");
                    console.error(err);
                    this.setState({
                        loading: true,
                    });
                })
        }
    }
    deleteUserClickHandler(){
        if(window.confirm("Möchten Sie den Nutzer wirklich löschen?")){
            this.setState({
                loading: true,
            });
            let c = this.state.data.user[this.state.selectedRow];
            let del = {_id: c["_id"]};
            this.props._delete(window.api + "/user/delete", JSON.stringify(del), true)
                .then((res) =>{
                    this.fetchDataNew();
                }).catch((err) =>{
                    alert("Hier ist leider etwas schief gelaufen!");
                    console.error(err);
                    this.setState({
                        loading: true,
                    });
                })
        }
    }
    toggleAccept(){ 
        this.setState({ 
            clickedAccept: !this.state.clickedAccept, 
        });
    }
    render(){
        return(
            <div className="whiteWrapper" id="adminUserWrapper">
                <Headline breadcrumb="data" headline="Userverwaltung"/>
                <div className="positioner" style={{width: "100%", display: "flex", justifyContent: "center", alignItems:"center"}}>
                    <div className="card">
                        <NavSidebar
                            menuItems={this.state.folder} 
                            activatedFolder={this.state.activatedFolder}
                            changeFolder={this.changeFolder}
                        />
                        {this.state.loading &&
                            <Loader
                                marginTop = {0}
                            /> 
                        }
                        {!this.state.loading &&
                            <div id="adminUserGrid">
                                <div style={{gridRow: "1/2", gridColumn: "1/3",display: "flex", justifyContent: "flex-start", alignItems: "center", marginLeft: 30}}>
                                    <h4 style={{fontSize: 25}}>{(this.state.activatedFolder === 0) ? "Zugangsanfragen" : "Registrierte Nutzer"}</h4>
                                </div>
                                <div style={{gridRow: "2/3", gridColumn: "1/2", overflowY: "auto"}}>
                                    {(this.state.activatedFolder === 0 && this.state.data.requested) &&
                                        <RTable
                                            activatedFolder = {this.state.activatedFolder}
                                            values = {this.state.data.requested}
                                            clickHandler = {this.clickHandler}
                                            selectedRow={this.state.selectedRow}
                                        />
                                    }
                                    {(this.state.activatedFolder === 1 && this.state.data.user) &&
                                        <RTable
                                            activatedFolder = {this.state.activatedFolder}
                                            values = {this.state.data.user}
                                            clickHandler = {this.clickHandler}
                                            selectedRow={this.state.selectedRow}
                                        />
                                    }
                                </div>
                                {(this.state.activatedFolder === 0 && this.state.selectedRow !== null) && 
                                    <UserCard
                                        activatedFolder={this.state.activatedFolder}
                                        selectedUser={this.state.data.requested[this.state.selectedRow]} 
                                        acceptUserClickHandler = {this.acceptUserClickHandler}
                                        declineUserClickHandler = {this.declineUserClickHandler}
                                        selectPermission = {this.selectPermission}
                                        selectedPermission = {this.selectedPermission}
                                        toggleAccept = {this.toggleAccept}
                                        clickedAccept = {this.state.clickedAccept}
                                        noPermissionError = {this.state.noPermissionError}
                                    />
                                }
                                {(this.state.activatedFolder === 1 && this.state.selectedRow !== null) && 
                                    <UserCard
                                        activatedFolder={this.state.activatedFolder}
                                        selectedUser={this.state.data.user[this.state.selectedRow]} 
                                        selectPermission = {this.selectPermission}
                                        selectedPermission = {this.selectedPermission}
                                        deleteUserClickHandler = {this.deleteUserClickHandler}
                                    />
                                }
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}


export default AdminUser;