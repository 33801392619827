import React from "react";
import "./UserCircle.css"
import helper from "./helper.js";

class UserCircle extends React.Component{
    render(){
        let color = helper.generateRgbFromName(this.props.name);
        let nameSplitted = this.props.name.split(" ");
        return(
            <div className="userCircleWrapper">
                <div className="userCircle" style={{background: color}}><span>{nameSplitted[0][0].toUpperCase()+nameSplitted[nameSplitted.length-1][0].toUpperCase()}</span></div>
            </div> 
        )
    }
}

export default UserCircle;